import React, { useEffect, useState } from "react";
import moment from "moment-timezone";
import "../../assets/css/Statistics.css";
import { getServerSettingsData } from "../../store/actions/serverSettingsAction";
import { useDispatch, useSelector } from "react-redux";
import { getStatsCount } from "../../store/actions/playerStatisticsAction";

const TotalStatsSnapshot = () => {
  const dispatch = useDispatch();
  const { serverSettingsData } = useSelector((state) => state.serverSettings);
  const [stats, setStats] = useState(null);
  const [timezone, setTimeZone] = useState();

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const yesterdayUnix = moment
          .tz(timezone)
          .subtract(1, "days")
          .startOf("day")
          .unix();

        const todayUnix = moment.tz(timezone).startOf("day").unix();

        const rows = await getStatsCount(todayUnix, yesterdayUnix);

        const aggregatedStats = {
          yesterday:
            rows.find((row) => row.currentDate === yesterdayUnix) || {},
          today: rows.find((row) => row.currentDate === todayUnix) || {},
        };

        setStats(aggregatedStats);
      } catch (error) {
        console.error("Error fetching statistics:", error);
      }
    };

    fetchStats();
  }, [timezone]);

  useEffect(() => {
    if (serverSettingsData && serverSettingsData.length > 0) {
      const data = serverSettingsData[0];
      setTimeZone(data?.timezone);
    }
  }, [serverSettingsData]);

  useEffect(() => {
    dispatch(getServerSettingsData());
  }, []);

  if (!stats) {
    return <div>Loading...</div>;
  }

  return (
    <div className="table-responsive">
      <h4 className="mb-3">Total Stats Snapshot</h4>
      <table className="stats-table">
        <thead>
          <tr>
            <th className="stats-heading">Players</th>
            <th>
              <div>
                <div>Day {stats?.yesterday?.serverDay ?? 0}</div>
                <div> {moment().subtract(1, "days").format("D MMMM YYYY")}</div>
              </div>
            </th>
            <th>
              <div>
                <div>Day {stats?.today?.serverDay ?? 0}</div>
                <div> {moment().format("D MMMM YYYY")}</div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="text-info fw-bold">Total Players</td>
            <td className="text-info fw-bold">
              {stats?.yesterday?.totalPlayers?.toLocaleString() ?? 0}
            </td>
            <td className="text-info fw-bold">
              {stats?.today?.totalPlayers?.toLocaleString() ?? 0}
            </td>
          </tr>
          <tr>
            <td className="text-success fw-bold">Active Players</td>
            <td className="text-success fw-bold">
              {stats?.yesterday?.activePlayers ?? 0}
            </td>
            <td className="text-success fw-bold">
              {stats?.today?.activePlayers ?? 0}
            </td>
          </tr>
          <tr>
            <td className="text-warning fw-bold">New Players</td>
            <td className="text-warning fw-bold">
              {stats?.yesterday?.newPlayers ?? 0}
            </td>
            <td className="text-warning fw-bold">
              {stats?.today?.newPlayers ?? 0}
            </td>
          </tr>
          <tr>
            <td className="text-danger fw-bold">Deleted Players</td>
            <td className="text-danger fw-bold">
              {stats?.yesterday?.deletedPlayers ?? 0}
            </td>
            <td className="text-danger fw-bold">
              {stats?.today?.deletedPlayers ?? 0}
            </td>
          </tr>
        </tbody>
        <thead>
          <tr>
            <th className="stats-heading">Villages</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="text-info fw-bold">Total Villages</td>
            <td className="text-info fw-bold">
              {stats?.yesterday?.totalVillages?.toLocaleString() ?? 0}
            </td>
            <td className="text-info fw-bold">
              {stats?.today?.totalVillages?.toLocaleString() ?? 0}
            </td>
          </tr>
          <tr>
            <td className="text-success fw-bold">Villages Settled</td>
            <td className="text-success fw-bold">
              {stats?.yesterday?.settledVillages?.toLocaleString() ?? 0}
            </td>
            <td className="text-success fw-bold">
              {stats?.today?.settledVillages?.toLocaleString() ?? 0}
            </td>
          </tr>
          <tr>
            <td className="text-danger fw-bold">Villages Destroyed</td>
            <td className="text-danger fw-bold">
              {stats?.yesterday?.destroyedVillages?.toLocaleString() ?? 0}
            </td>
            <td className="text-danger fw-bold">
              {stats?.today?.destroyedVillages?.toLocaleString() ?? 0}
            </td>
          </tr>
          <tr>
            <td className="text-warning fw-bold">Villages Conquered</td>
            <td className="text-warning fw-bold">
              {stats?.yesterday?.conqueredVillages?.toLocaleString() ?? 0}
            </td>
            <td className="text-warning fw-bold">
              {stats?.today?.conqueredVillages?.toLocaleString() ?? 0}
            </td>
          </tr>
        </tbody>
        <thead>
          <tr>
            <th className="stats-heading">Population</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="text-info fw-bold">Total Population</td>
            <td className="text-info fw-bold">
              {stats?.yesterday?.totalPopulation?.toLocaleString() ?? 0}
            </td>
            <td className="text-info fw-bold">
              {stats?.today?.totalPopulation?.toLocaleString() ?? 0}
            </td>
          </tr>
          <tr>
            <td className="text-success fw-bold">Population Change</td>
            <td
              className={`fw-bold ${
                stats?.yesterday?.populationChange > 0
                  ? "text-success"
                  : "text-danger"
              }`}
            >
              {stats?.yesterday?.populationChange > 0 ? "+" : ""}
              {stats?.yesterday?.populationChange?.toLocaleString() ?? 0}
            </td>
            <td
              className={`fw-bold ${
                stats?.today?.populationChange > 0
                  ? "text-success"
                  : "text-danger"
              }`}
            >
              {stats?.today?.populationChange > 0 ? "+" : ""}
              {stats?.today?.populationChange ?? 0}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default TotalStatsSnapshot;
