import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
} from "reactstrap";
import Select from "react-select";

const PushModal = ({
  isOpen,
  toggle,
  uniqueAlliances,
  selectedAlliance,
  handleAllianceChange,
  filteredPlayers,
  formData,
  handleChange,
  filteredVillages,
  handleSubmit,
  isSubmitDisabled,
  loading,
}) => {
  const allianceOptions = uniqueAlliances.map((alliance) => ({
    value: alliance,
    label: alliance,
  }));
  const playerOptions = filteredPlayers.map((player) => ({
    value: player,
    label: player,
  }));
  const villageOptions = filteredVillages.map((village) => ({
    value: village,
    label: village,
  }));

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered scrollable>
      <ModalHeader toggle={toggle}>Create Push</ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label for="allianceName">Alliance Name:</Label>
          <Select
            id="allianceName"
            value={
              selectedAlliance
                ? allianceOptions.find(
                    (option) => option.value === selectedAlliance
                  )
                : null
            }
            onChange={(selectedOption) =>
              handleAllianceChange({
                target: { value: selectedOption ? selectedOption.value : null },
              })
            }
            options={allianceOptions}
            isClearable
          />
        </FormGroup>
        <FormGroup>
          <Label for="playerName">Player Name:</Label>
          <Select
            id="playerName"
            value={
              formData.playerName
                ? playerOptions.find(
                    (option) => option.value === formData.playerName
                  )
                : null
            }
            onChange={(selectedOption) =>
              handleChange({
                target: {
                  id: "playerName",
                  value: selectedOption ? selectedOption.value : null,
                },
              })
            }
            options={playerOptions}
            isClearable
          />
        </FormGroup>
        <FormGroup>
          <Label for="villageName">Village Name:</Label>
          <Select
            id="villageName"
            value={
              formData.villageName
                ? villageOptions.find(
                    (option) => option.value === formData.villageName
                  )
                : null
            }
            onChange={(selectedOption) =>
              handleChange({
                target: {
                  id: "villageName",
                  value: selectedOption ? selectedOption.value : null,
                },
              })
            }
            options={villageOptions}
            isClearable
          />
        </FormGroup>
        <FormGroup>
          <Label for="resourcesNeeded">Resources Needed:</Label>
          <input
            type="number"
            id="resourcesNeeded"
            className="form-control"
            value={formData.resourcesNeeded}
            onChange={handleChange}
          />
        </FormGroup>
        <FormGroup>
          <Label for="comments">Comments:</Label>
          <input
            type="text"
            id="comments"
            className="form-control"
            value={formData.comments}
            onChange={handleChange}
          />
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          Close
        </Button>
        <Button
          color="primary"
          onClick={handleSubmit}
          disabled={isSubmitDisabled || loading}
        >
          {loading ? "Creating Push..." : "Create Push"}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default PushModal;
