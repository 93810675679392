import React, { useEffect, useState } from "react";
import TrackerTable from "../components/DefenderTracker/TrackerTable";
import TabSelection from "../components/TabSelection/TabSelection";
import TrackerMain from "../components/DefenderTracker/TrackerMain";
import { useSelector, useDispatch } from "react-redux";
import { getPermissions } from "../store/actions/userpermissionsAction";
import "../assets/css/defenderTracker.css"

const DefenderTracker = () => {
  //---------- useSelectors/useStates ---------------//
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { permission } = useSelector((state) => state.userPermissions);
  const defenderSections = ["Tracker Main", "Tracker Table"];
  const [defenderSection, setDefenderSection] = useState("Tracker Main");
  const [condition, setCondition] = useState("");

  //-------------Roles--------------//

  const permissionData = permission?.defenderTracker;
  const role = user?.role;
  const functionalRole = user?.functionalRole;
  const functionalPermission =
    permission && permissionData && permissionData[functionalRole];

  //--------------useEffect --------------//

  useEffect(() => {
    if (permission && permissionData) {
      const rolePermission = permissionData[role];
      const functionalPermission = permissionData[functionalRole];
      if (
        functionalPermission !== undefined &&
        functionalPermission.trim() !== ""
      ) {
        setCondition(functionalPermission);
      } else if (rolePermission !== undefined && rolePermission.trim() !== "") {
        setCondition(rolePermission);
      }
    }
  }, [permission, permissionData, role, functionalRole]);

  useEffect(() => {
    dispatch(getPermissions());
  }, []);

  return (
    <div className="px-5 container-fluid">
      {condition == "Locked" ? (
        <div
          className="text-center d-flex justify-content-center align-items-center "
          style={{ height: "70vh" }}
        >
          <h2>
            This page is not available for{" "}
            {functionalPermission !== undefined &&
            functionalPermission.trim() !== ""
              ? functionalRole
              : role}
          </h2>{" "}
        </div>
      ) : (
        <>
          <div className="d-flex justify-content-between align-items-center flex-wrap mt-3 mb-3">
            <h2 className="text-center">Defender Tracker</h2>
            <TabSelection
              options={defenderSections}
              onChange={(value) => setDefenderSection(value)}
            />
          </div>
          {defenderSection === "Tracker Main" ? (
            <TrackerMain />
          ) : (
            <TrackerTable />
          )}
        </>
      )}
    </div>
  );
};

export default DefenderTracker;
