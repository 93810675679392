import React, { useEffect, useState } from "react";
import "../assets/css/UserPermissions.css";
import "../assets/css/TroopGlossary.css";
import { useDispatch, useSelector } from "react-redux";
import {
  addPermissions,
  getPermissions,
} from "../store/actions/userpermissionsAction";

const roles = [
  "Administrator",
  "Senior Leader",
  "Leader",
  "Senior member",
  "Member",
  "Recruit",
];
const functionalRole = ["Scout Team", "Push Team"];

const pages = [
  "admin",
  "userAccounts",
  "Permissions",
  "serverSetting",
  "permanentRoster",
  "knowledgeBase",
  "troopGlossary",
  "trackers",
  "defenderTracker",
  "hammerTracker",
  "pushes",
  "allianceBonus",
  "resourcePush",
  "pushReport",
  "playerRoster",
  "playerTroop",
  "playerTools",
  "farmSearch",
  "playerStatistics",
  "interactiveMap",
];

function UserPermissions() {
  const dispatch = useDispatch();
  const { permission, loading } = useSelector((state) => state.userPermissions);
  const { user } = useSelector((state) => state.auth);

  //-------------- useStates -------------//

  const [activeTab, setActiveTab] = useState("table1");
  const [condition, setCondition] = useState("");
  const [permissions, setPermissions] = useState(
    pages.reduce((acc, page) => {
      acc[page] = roles.reduce((roleAcc, role) => {
        roleAcc[role] = permission[page] ? permission[page][role] : "";
        return roleAcc;
      }, {});
      return acc;
    }, {})
  );

  //--------------Roles/Permissions ----------------//

  const permissionData = permission?.Permissions;
  const role = user?.role;
  const functionalrole = user?.functionalRole;
  const functionalPermission =
    permission && permissionData && permissionData[functionalRole];

  //--------------- Functions ----------------//

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const handlePermissionChange = (page, role, value) => {
    setPermissions((prevPermissions) => ({
      ...prevPermissions,
      [page]: {
        ...prevPermissions[page],
        [role]: value,
      },
    }));
  };

  const handleSubmit = async () => {
    await dispatch(addPermissions(permissions));
    dispatch(getPermissions());
    setPermissions((prevPermissions) => ({
      ...prevPermissions,
      ...permission,
    }));
    setCondition(permissionData[role]);
  };

  //------------------ useEffects ----------------//

  useEffect(() => {
    setPermissions((prevPermissions) => ({
      ...prevPermissions,
      ...permission,
    }));
  }, [dispatch, permission]);

  useEffect(() => {
    dispatch(getPermissions());
  }, [dispatch]);

  useEffect(() => {
    if (permission && permissionData) {
      const rolePermission = permissionData[role];
      const functionalPermission = permissionData[functionalrole];
      if (
        functionalPermission !== undefined &&
        functionalPermission.trim() !== ""
      ) {
        setCondition(functionalPermission);
      } else if (rolePermission !== undefined && rolePermission.trim() !== "") {
        setCondition(rolePermission);
      }
    }
  }, [permission, permissionData, role, functionalRole]);

  return (
    <div className="container-fluid px-5 mb-4">
      <h2 className="text-center mb-3 mt-3">User Permissions</h2>
      <div className="d-flex justify-content-center align-items-center mb-3">
        <button
          className="btn btn-success mt-3"
          disabled={loading || condition === "Read Only"}
          onClick={handleSubmit}
        >
          {loading ? "Loading..." : "Update Permissions"}
        </button>
      </div>

      {condition === "Locked" ? (
        <div
          className="text-center d-flex justify-content-center align-items-center"
          style={{ height: "70vh" }}
        >
          <h2>
            This page is not available for{" "}
            {functionalPermission !== undefined &&
            functionalPermission.trim() !== ""
              ? functionalRole
              : role}
          </h2>
        </div>
      ) : loading ? (
        <div className="d-flex justify-content-center align-items-center">
          <div
            className="spinner-border"
            role="status"
            style={{ display: "inline-block", marginTop: "25px" }}
          ></div>
        </div>
      ) : (
        <>
          <ul className="nav nav-tabs">
            <li className="nav-item">
              <button
                className={`nav-link ${activeTab === "table1" ? "active" : ""}`}
                onClick={() => handleTabChange("table1")}
              >
                Member Role
              </button>
            </li>
            <li className="nav-item">
              <button
                className={`nav-link ${activeTab === "table2" ? "active" : ""}`}
                onClick={() => handleTabChange("table2")}
              >
                Functional Role
              </button>
            </li>
          </ul>
          <div className="table-container tab-content">
            {/* Roles Table  */}

            <div
              className={`tab-pane fade ${
                activeTab === "table1" ? "show active" : ""
              }`}
            >
              <table>
                <thead>
                  <tr>
                    <th className="bg-info text-black p-3 text-center">
                      Page Name
                    </th>
                    {roles.map((role, index) => (
                      <th
                        key={index}
                        className="bg-info text-black p-3 text-center"
                      >
                        {role}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {pages.map((page, pageIndex) => (
                    <tr key={pageIndex}>
                      <td className="text-capitalize text-center">{page}</td>
                      {roles.map((role, roleIndex) => (
                        <td key={roleIndex}>
                          <select
                            value={permissions[page][role]}
                            onChange={(e) =>
                              handlePermissionChange(page, role, e.target.value)
                            }
                            disabled={condition === "Read Only"}
                          >
                            <option value="">Select</option>
                            <option value="Full Access">Full Access</option>
                            {role !== "Administrator" && (
                              <option value="Full Edit">Full Edit</option>
                            )}
                            {role !== "Administrator" && (
                              <option value="Limited Edit">Limited Edit</option>
                            )}
                            {role !== "Administrator" && (
                              <option value="Read Only">Read Only</option>
                            )}
                            {role !== "Administrator" && (
                              <option value="Locked">Locked</option>
                            )}
                          </select>
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            {/* Functional Role Table  */}

            <div
              className={`tab-pane fade ${
                activeTab === "table2" ? "show active" : ""
              }`}
            >
              <table>
                <thead>
                  <tr>
                    <th className="bg-info text-black">Page Name</th>
                    {functionalRole.map((role, index) => (
                      <th key={index} className="bg-info text-black">
                        {role}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {pages.map((page, pageIndex) => (
                    <tr key={pageIndex}>
                      <td className="text-capitalize">{page}</td>
                      {functionalRole.map((role, roleIndex) => (
                        <td key={roleIndex}>
                          <select
                            value={permissions[page][role]}
                            onChange={(e) =>
                              handlePermissionChange(page, role, e.target.value)
                            }
                            disabled={condition === "Read Only"}
                          >
                            <option value="">Select</option>
                            <option value="Full Access">Full Access</option>
                            <option value="Full Edit">Full Edit</option>
                            <option value="Limited Edit">Limited Edit</option>
                            <option value="Read Only">Read Only</option>
                            <option value="Locked">Locked</option>
                          </select>
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default UserPermissions;
