import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFilteredData } from "../store/actions/playroasterAction";
import resource from "../assets/Images/troopGlossaryIcons/Total_Resources_Cost_Icon.png";
import { getGoalData } from "../store/actions/allianceBonusAction";
import {
  getReportPushData,
  getReportsData,
} from "../store/actions/resourcePushAction";
import { getPermissions } from "../store/actions/userpermissionsAction";
import { getUsers } from "../store/actions/dashboardAction";

const AlliancePushReports = () => {
  const dispatch = useDispatch();
  const { users } = useSelector((state) => state.dashboard);
  const { user } = useSelector((state) => state.auth);
  const { permission } = useSelector((state) => state.userPermissions);
  const { goalData, loading } = useSelector((state) => state.allianceBonus);
  const { resourcePush, reportsData } = useSelector(
    (state) => state.resourcePush
  );
  const [filterData, setFilterData] = useState([]);
  const [sortColumn, setSortColumn] = useState(null);
  const [selectedFilterValues, setSelectedFilterValues] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [condition, setCondition] = useState("");

  //---------------- Roles/Permissions --------------//

  const permissionData = permission?.pushReport;
  const role = user?.role;
  const functionalRole = user?.functionalRole;
  const functionalPermission =
    permission && permissionData && permissionData[functionalRole];

  //--------- Sorting-------- //

  const handleSort = (column, option) => {
    let sortedUsers = [...filterData];

    if (option === "asc") {
      sortedUsers.sort((a, b) => compareValues(a[column], b[column]));
    } else if (option === "desc") {
      sortedUsers.sort((a, b) => compareValues(b[column], a[column]));
    }

    setSortColumn(column);
    setFilterData(sortedUsers);
  };

  const compareValues = (valueA, valueB) => {
    if (valueA === valueB) {
      return 0;
    }
    return valueA < valueB ? -1 : 1;
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleApplyFilter = (columnName) => {
    const selectedValues = selectedFilterValues[columnName];

    if (!selectedValues || selectedValues.length === 0) {
      return;
    }

    const filteredRows = filterData.filter((player) => {
      return (
        player &&
        player[columnName] !== undefined &&
        selectedValues.includes(String(player[columnName]))
      );
    });

    setFilterData(filteredRows);
  };

  const uniqueValuesForColumn = (columnName) => {
    const uniqueValues = new Set();

    if (filterData && filterData.length > 0) {
      filterData.forEach((player) => {
        if (player && player[columnName] !== undefined) {
          const value = String(player[columnName]);
          uniqueValues.add(value);
        }
      });
    }

    return Array.from(uniqueValues);
  };

  const clearFilters = () => {
    setSortColumn(null);
    if (users && users.length > 0) {
      const data = users;
      const uniqueData = [];
      const seenNames = new Set();
      data?.forEach((item) => {
        if (!seenNames.has(item.gameAccountName)) {
          seenNames.add(item.gameAccountName);
          uniqueData.push(item);
        }
      });
      let pushReport = calculateReportData(uniqueData, goalData, reportsData);
      setFilterData(pushReport);
    }
    setSelectedFilterValues({});
    setSearchTerm("");
  };

  //------------ Field Names ---------------- //

  const fieldNames = [
    { key: "gameAccountName", label: "Player" },
    {
      key: "pushesParticipated",
      label: "Pushes Participated",
    },
    {
      key: "totalResourceSent",
      label: (
        <span className="bg-info">
          <span>Total</span>
          <img src={resource} width={25} className="mx-1" />
          <span>Sent</span>
        </span>
      ),
    },
    {
      key: "totalAllianceBonus",
      label: "Alliance Bonus",
    },
    { key: "ranking", label: "Ranking" },
  ];

  //----------- Calculations --------------//

  const calculateReportData = (data, goalData, reportsData) => {
    data.forEach((player) => {
      let pushesParticipated = reportsData.filter(
        (push) => push.fromPlayer === player.gameAccountName
      ).length;

      let totalResourceSent = reportsData.reduce((total, push) => {
        if (push.fromPlayer === player.gameAccountName && push.resources) {
          return total + push.resources;
        }
        return total;
      }, 0);

      let totalAllianceBonus = goalData.reduce((total, goal) => {
        return (
          total +
          goal.resources.reduce((resources, report) => {
            if (
              report.player === player.gameAccountName &&
              report.donatedResources
            ) {
              return resources + report.donatedResources;
            }
            return resources;
          }, 0)
        );
      }, 0);

      let totalCombined = totalResourceSent + totalAllianceBonus;

      player.pushesParticipated = pushesParticipated;
      player.totalResourceSent = totalResourceSent;
      player.totalAllianceBonus = totalAllianceBonus;
      player.totalCombined = totalCombined;
    });

    data.sort((a, b) => (b.totalCombined || 0) - (a.totalCombined || 0));

    data.forEach((player, index) => {
      player.ranking = index + 1;
    });

    return data;
  };

  useEffect(() => {
    if (users && users.length > 0) {
      const data = users;
      const uniqueData = [];
      const seenNames = new Set();
      data.forEach((item) => {
        if (!seenNames.has(item.gameAccountName)) {
          seenNames.add(item.gameAccountName);
          uniqueData.push(item);
        }
      });
      let pushReport = calculateReportData(uniqueData, goalData, reportsData);
      setFilterData(pushReport);
    }
  }, [users, goalData, reportsData]);

  useEffect(() => {
    if (permission && permissionData) {
      const rolePermission = permissionData[role];
      const functionalPermission = permissionData[functionalRole];
      if (
        functionalPermission !== undefined &&
        functionalPermission.trim() !== ""
      ) {
        setCondition(functionalPermission);
      } else if (rolePermission !== undefined && rolePermission.trim() !== "") {
        setCondition(rolePermission);
      }
    }
  }, [permission, permissionData, role, functionalRole]);

  useEffect(() => {
    dispatch(getFilteredData());
    dispatch(getGoalData());
    dispatch(getReportPushData());
    dispatch(getPermissions());
    dispatch(getReportsData());
    dispatch(getUsers());
  }, []);

  return (
    <>
      {condition == "Locked" ? (
        <div
          className="text-center d-flex justify-content-center align-items-center "
          style={{ height: "70vh" }}
        >
          <h2>
            This page is not available for{" "}
            {functionalPermission !== undefined &&
            functionalPermission.trim() !== ""
              ? functionalRole
              : role}
          </h2>
        </div>
      ) : (
        <div className="mt-3 table-responsive container-fluid px-4">
          <h2 className="text-center mb-4">Push Reports</h2>
          <table className="table">
            <thead>
              {fieldNames.map((fieldName) => {
                return (
                  <th key={fieldName.key} className="p-3 text-center bg-info">
                    {fieldName.label}
                    <i
                      style={{
                        cursor: "pointer",
                        color: sortColumn === fieldName.key ? "red" : "",
                        border:
                          sortColumn === fieldName.key
                            ? "2px solid red"
                            : "none",
                      }}
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      className={"bi bi-filter ms-1 bg-info"}
                      disabled={condition === "Read Only"}
                    ></i>
                    <ul style={{ cursor: "pointer" }} className="dropdown-menu">
                      <li
                        role="button"
                        className="dropdown-item cursor-pointer "
                        onClick={() => handleSort(fieldName.key, "desc")}
                      >
                        Sort By Highest Values
                      </li>
                      <li
                        role="button"
                        className="dropdown-item cursor-pointer "
                        onClick={() => handleSort(fieldName.key, "asc")}
                      >
                        Sort By Lowest Values
                      </li>
                      <li
                        role="button"
                        className="dropdown-item cursor-pointer "
                        onClick={() => clearFilters()}
                      >
                        Clear filter
                      </li>
                      <li
                        style={{ cursor: "pointer" }}
                        className="dropdown-item "
                      >
                        Search
                        <input
                          className="mx-2"
                          placeholder="Search"
                          style={{ width: "70%" }}
                          type="text"
                          value={searchTerm}
                          onChange={handleSearchChange}
                        />
                      </li>
                      <div>
                        <p>Selected Values:</p>
                        {selectedFilterValues[fieldName.key]?.map(
                          (value, i) => (
                            <span key={i}>{value},</span>
                          )
                        )}
                      </div>
                      <select
                        style={{ width: "100%" }}
                        onChange={(e) => {
                          const selectedValues = Array.from(
                            e.target.selectedOptions,
                            (option) => option.value
                          );
                          setSelectedFilterValues((prevValues) => {
                            const currentValues =
                              prevValues[fieldName.key] || [];
                            const newValues = currentValues.includes(
                              selectedValues[0]
                            )
                              ? currentValues.filter(
                                  (value) => value !== selectedValues[0]
                                )
                              : [...currentValues, ...selectedValues];
                            return {
                              ...prevValues,
                              [fieldName.key]: newValues,
                            };
                          });
                        }}
                        multiple
                      >
                        <option
                          value="selectAll"
                          onClick={() => {
                            const allValues = uniqueValuesForColumn(
                              fieldName.key
                            ).filter((value) =>
                              value
                                .toLowerCase()
                                .includes(searchTerm.toLowerCase())
                            );
                            setSelectedFilterValues((prevValues) => ({
                              ...prevValues,
                              [fieldName.key]: allValues,
                            }));
                          }}
                        >
                          Select All
                        </option>
                        value && typeof value === 'string' ? value.toLowerCase()
                        : value
                        {uniqueValuesForColumn(fieldName.key)
                          ?.filter((value) =>
                            value
                              .toLowerCase()
                              .includes(searchTerm?.toLowerCase())
                          )
                          .map((value, i) => (
                            <option key={i} value={value}>
                              {`${value} ${
                                selectedFilterValues[fieldName.key]?.includes(
                                  value
                                )
                                  ? "✓"
                                  : ""
                              }`}
                            </option>
                          ))}
                      </select>
                      <div className="d-flex justify-content-end gap-2 mt-2">
                        <button
                          className="btn btn-primary"
                          disabled={condition === "Read Only"}
                          onClick={() => handleApplyFilter(fieldName.key)}
                        >
                          Apply Filter
                        </button>
                        <button
                          className="btn btn-danger"
                          disabled={condition === "Read Only"}
                          onClick={() => setSelectedFilterValues("")}
                        >
                          Cancel
                        </button>
                      </div>
                    </ul>
                  </th>
                );
              })}
            </thead>
            {loading ? (
              <tbody>
                <tr>
                  <td className="text-center" colSpan="12">
                    <div className="d-flex justify-content-center align-items-center">
                      <div className="spinner-border" role="status"></div>
                      <span className="visually-hidden ms-2">Loading...</span>
                    </div>
                  </td>
                </tr>
              </tbody>
            ) : (
              <tbody>
                {filterData.map((data, index) => (
                  <tr key={index}>
                    <td className="text-center">{data?.gameAccountName}</td>
                    <td className="text-center">
                      {data?.pushesParticipated}/
                      {resourcePush && resourcePush?.length}
                    </td>
                    <td className="text-center">
                      {data?.totalResourceSent.toLocaleString()}
                    </td>
                    <td className="text-center">
                      {data?.totalAllianceBonus.toLocaleString()}
                    </td>
                    <td className="text-center">{data?.ranking}</td>
                  </tr>
                ))}
              </tbody>
            )}
          </table>
        </div>
      )}
    </>
  );
};

export default AlliancePushReports;
