const initialState = {
  column: [],
  filteredData: [],
};

export default function playroasterReducer(state = initialState, action) {
  switch (action.type) {
    case "GET_COLUMNS":
      return {
        ...state,
        column: action.payload,
      };
    case "GET_ROWS":
      return {
        ...state,
        rowData: action.payload,
      };
    case "SAVE_FILTERED_DATA":
      return {
        ...state,
        filteredData: action.payload,
      };
    case "DELETE_FILTERED_DATA":
      return {
        ...state,
        filteredData: state.filteredData.filter(
          (item) => item.id !== action.payload
        ),
      };
    case "LOADER":
      return {
        ...state,
        columnLoading: action.payload,
      };
    default:
      return state;
  }
}
