import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto";

const RPHGraph = ({ labels, data }) => {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);

  useEffect(() => {
    if (chartRef.current && labels.length > 0 && data.length > 0) {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }

      const ctx = chartRef.current.getContext("2d");
      chartInstance.current = new Chart(ctx, {
        type: "line",
        data: {
          labels: labels,
          datasets: [
            {
              label: "RPH",
              data: data,
              borderColor: "#198754",
              tension: 0.1,
            },
          ],
        },
        options: {
          aspectRatio: 0,
          animation: false,
          scales: {
            x: {
              grid: {
                display: false,
              },
              title: {
                display: true,
                text: "Server Day",
              },
            },
            y: {
              title: {
                display: true,
                text: "RPH",
              },
            },
          },
        },
      });
    }

    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, [labels, data]);

  return <canvas ref={chartRef} height={500} />;
};

export default RPHGraph;
