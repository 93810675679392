import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFilteredData } from "../store/actions/playroasterAction";
import "../assets/css/ResourcePush.css";
import ResourcePushReportParse from "../components/ResourcePush/ResourcePushReportParse";
import moment from "moment-timezone";
import {
  addWebhook,
  completePush,
  createNewPush,
  createNewRecord,
  deletePushRecord,
  getCurrentMapData,
  getReportPushData,
  getReportsData,
  getWebhooks,
  saveData,
  saveRecordData,
  saveSpeedData,
  swapRow,
} from "../store/actions/resourcePushAction";
import { getPermissions } from "../store/actions/userpermissionsAction";
import { getServerSettingsData } from "../store/actions/serverSettingsAction";
import PushModal from "../components/ResourcePush/CreatePushModal";
import DiscordWebhookModal from "../components/ResourcePush/DiscordWebhookModal";
import NewRecordModal from "../components/ResourcePush/NewRecordModal";
import { toast } from "react-toastify";

const ResourcePush = () => {
  const dispatch = useDispatch();
  const { resourcePush, reportsData, webHooks, latestMapInformation, loading } =
    useSelector((state) => state.resourcePush);
  const { permission } = useSelector((state) => state.userPermissions);
  const { serverSettingsData } = useSelector((state) => state.serverSettings);
  const { user } = useSelector((state) => state.auth);
  const { filteredData } = useSelector((state) => state.column);
  const [resourcePushData, setResourcePushData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [editedIndex, setEditedIndex] = useState(null);
  const [editedFieldName, setEditedFieldName] = useState(null);
  const [editedValue, setEditedValue] = useState(null);
  const [expandedRows, setExpandedRows] = useState([]);
  const [resourceId, setResourceId] = useState(null);
  const [condition, setCondition] = useState("");
  const [showCompleted, setShowCompleted] = useState(true);
  const [webhookURL, setWebhookURL] = useState("");
  const [isModified, setIsModified] = useState(false);
  const [webhookMessage, setWebhookMessage] = useState("");
  const [timezone, setTimeZone] = useState();
  const [uniqueAlliances, setUniqueAlliances] = useState([]);
  const [selectedAlliance, setSelectedAlliance] = useState("");
  const [filteredPlayers, setFilteredPlayers] = useState([]);
  const [filteredVillages, setFilteredVillages] = useState([]);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [serverUrl, setServerUrl] = useState();
  const [multiplier, setMultiplier] = useState("1x");
  const [modalReport, setModalReport] = useState(false);
  const [pushModalOpen, setPushModalOpen] = useState(false);
  const [discordModalOpen, setDiscordModalOpen] = useState(false);
  const [newRecordModalOpen, setNewRecordModalOpen] = useState(false);
  const [filteredAlliances, setFilteredAlliances] = useState(uniqueAlliances);
  const toggleModal = () => setModalReport(!modalReport);
  const PushToggleModal = () => setPushModalOpen(!pushModalOpen);
  const toggleDiscordModal = () => setDiscordModalOpen(!discordModalOpen);
  const toggleNewRecordModal = () => setNewRecordModalOpen(!newRecordModalOpen);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    playerName: "",
    villageName: "",
    resourcesNeeded: null,
    comments: "",
    priority: "",
    x: null,
    y: null,
    fieldId: null,
    isComplete: false,
  });
  const [newRecord, setNewRecord] = useState({
    fromPlayer: "",
    fromVillage: "",
    resources: null,
    arrivalTime: "",
  });

  //---------------- Roles/Permissions --------------//

  const permissionData = permission?.resourcePush;
  const role = user?.role;
  const functionalRole = user?.functionalRole;
  const functionalPermission =
    permission && permissionData && permissionData[functionalRole];

  //------------- Functions ---------------//

  const handleChange = (e) => {
    const { id, value } = e.target;
    const numericValue = /^\d+$/.test(value) ? parseInt(value) : value;

    setFormData({
      ...formData,
      [id]: numericValue,
    });
  };

  const handleRecordChange = (e) => {
    const { id, value } = e.target;

    setNewRecord({
      ...newRecord,
      [id]: id === "resources" ? Number(value) : value,
    });
  };

  const handleSelectChange = async (e, rowId, fieldName) => {
    const { value } = e.target;
    try {
      const updatedRow = resourcePushData.find((row) => row.id === rowId);
      const updatedData = { ...updatedRow, [fieldName]: value };
      await dispatch(saveData([updatedData]));
      setResourcePushData((prevData) =>
        prevData.map((item) =>
          item.id === rowId ? { ...item, [fieldName]: value } : item
        )
      );
      dispatch(getReportPushData());
      setEditedIndex(null);
      setEditedFieldName(null);
      setEditedValue("");
    } catch (error) {
      toast.error("Failed to update data");
    }
  };

  const handleMultiplierChange = async (e, rowId, fieldName) => {
    const { checked } = e.target;
    try {
      const updatedRow = resourcePushData.find((row) => row.id === rowId);

      let updatedData;
      if (updatedRow) {
        updatedData = { ...updatedRow, [fieldName]: checked };
      } else {
        updatedData = { id: rowId, [fieldName]: checked };
      }
      await dispatch(saveSpeedData([updatedData]));

      setResourcePushData((prevData) => {
        const rowExists = prevData.some((item) => item.id === rowId);

        if (rowExists) {
          return prevData.map((item) =>
            item.id === rowId ? { ...item, [fieldName]: checked } : item
          );
        } else {
          return [...prevData, updatedData];
        }
      });

      dispatch(getReportPushData());

      setEditedIndex(null);
      setEditedFieldName(null);
      setEditedValue("");
    } catch (error) {
      toast.error("Failed to update data");
    }
  };

  const handleSave = async (rowId) => {
    try {
      const updatedRow = resourcePushData.find((row) => row.id === rowId);
      let updatedValue = editedValue;
      if (editedFieldName === "resourcesNeeded" && editedValue !== null) {
        updatedValue = Number(editedValue);
      }
      const updatedData = { ...updatedRow, [editedFieldName]: updatedValue };
      await dispatch(saveData([updatedData]));
      setEditedValue(null);
      setEditedIndex(null);
      setEditedFieldName(null);
    } catch (error) {
      toast.error("Failed to save data");
    }
  };

  const handleRecordSave = async (rowId, resourceId) => {
    try {
      const findRow = resourcePushData.find((row) => row.id === resourceId);

      if (!findRow) {
        toast.error("Resource not found");
        return;
      }
      const updatedRow = findRow.reports.find((row) => row.id === rowId);
      if (!updatedRow) {
        toast.error("Row not found");
        return;
      }

      let updatedValue = editedValue;
      if (editedFieldName === "resources" && editedValue !== null) {
        updatedValue = Number(editedValue);
      }
      const updatedData = { ...updatedRow, [editedFieldName]: updatedValue };
      await dispatch(saveRecordData(updatedData, resourceId));
      setEditedValue(null);
      setEditedIndex(null);
      setEditedFieldName(null);
    } catch (error) {
      toast.error("Failed to save data");
    }
  };

  const handleKeyDown = (e, rowId) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSave(rowId);
      dispatch(getReportPushData());
    }
  };
  const handleKey = (e, rowId, resourceId) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleRecordSave(rowId, resourceId);
      dispatch(getReportPushData());
    }
  };

  const handleClickOutside = (e) => {
    if (!e.target.closest("input")) {
      setEditedIndex(null);
      setEditedValue("");
      setEditedFieldName(null);
    }
  };

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);

      await dispatch(createNewPush(formData));

      const messageContent = `${webhookMessage}\n\n**Village:** [${formData.x}/${formData.y} - ${formData.villageName}](${serverUrl}/karte.php?x=${formData.x}&y=${formData.y})\n**Amount:** ${formData.resourcesNeeded} [Send Resources](${serverUrl}/build.php?gid=17&z=${formData.fieldId}&t=5)\n\n[Resource Push Link](https://theramalliance.web.app/resourcePush)`;

      const payload = {
        content: messageContent,
      };

      await fetch(-webhookURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      setFormData({
        playerName: "",
        villageName: "",
        resourcesNeeded: null,
        comments: "",
        priority: "",
        isComplete: false,
        x: null,
        y: null,
        fieldId: null,
      });

      dispatch(getReportPushData());
      PushToggleModal();
      setIsSubmitting(false);
    } catch (error) {
      toast.error("Failed to create new push");
    }
  };

  const handleDiscordSave = () => {
    dispatch(addWebhook({ webhookURL, webhookMessage }));
    setIsModified(false);
    toggleDiscordModal();
  };

  const checkIfFormIsValid = () => {
    const { playerName, villageName, resourcesNeeded } = formData;
    if (selectedAlliance && playerName && villageName && resourcesNeeded) {
      setIsSubmitDisabled(false);
    } else {
      setIsSubmitDisabled(true);
    }
  };

  const isAllFieldsFilled = () => {
    return (
      newRecord.fromPlayer &&
      newRecord.fromVillage &&
      newRecord.resources &&
      newRecord.arrivalTime
    );
  };

  const handleRecordSubmit = async () => {
    try {
      await dispatch(createNewRecord(resourceId, newRecord));

      setNewRecord({
        fromPlayer: "",
        fromVillage: "",
        resources: null,
        arrivalTime: "",
      });

      dispatch(getReportPushData());
      toggleNewRecordModal();
    } catch (error) {
      toast.error("Failed to add new data");
    }
  };

  const findNearestPastArrival = (resource) => {
    const currentDateTime = moment().tz(timezone);

    const pastArrivalTimes =
      reportsData &&
      reportsData
        .filter((report) => report?.player === resource?.playerName)
        .map((report) =>
          moment(report?.arrivalTime, "HH:mm:ss, DD MMM YYYY").tz(
            timezone,
            true
          )
        )
        .filter((arrivalDateTime) => arrivalDateTime?.isBefore(currentDateTime))
        .sort((a, b) => b.diff(currentDateTime) - a.diff(currentDateTime));

    if (pastArrivalTimes.length === 0) {
      return null;
    }
    return pastArrivalTimes[0];
  };

  //------------Expand Table ------------ //

  const toggleRowExpansion = (id) => {
    const index = expandedRows.indexOf(id);
    if (index > -1) {
      setExpandedRows(expandedRows.filter((rowId) => rowId !== id));
    } else {
      setExpandedRows([...expandedRows, id]);
    }
  };

  const isRowExpanded = (id) => {
    return expandedRows.includes(id);
  };

  const expandCollapseIcon = (id) => {
    return isRowExpanded(id) ? (
      <i className="fas fa-chevron-up cursor-pointer" role="button"></i>
    ) : (
      <i className="fas fa-chevron-down cursor-pointer" role="button"></i>
    );
  };

  //--------------Format Value -------------//

  function formatNumber(value) {
    if (value === undefined) {
      return "Undefined";
    }

    if (value >= 1000000) {
      return (value / 1000000).toFixed(1) + "M";
    } else if (value >= 1000) {
      return (value / 1000).toFixed(1) + "K";
    } else {
      return value;
    }
  }

  //-------------Calculate total resources coming -----------//

  const calculateTotalResources = (resource) => {
    const totalResourcesSent =
      reportsData &&
      reportsData
        .filter((report) => report?.player === resource?.playerName)
        .reduce((acc, report) => {
          return acc + (report?.resources || 0);
        }, 0);

    if (
      totalResourcesSent >= resource?.resourcesNeeded &&
      !resource?.isComplete
    ) {
      dispatch(completePush(resource?.id));
    }

    return totalResourcesSent;
  };
  //--------Row Swap ----------//

  const swapRows = (index1, index2) => {
    dispatch(swapRow(index1, index2));
  };

  const handleMoveUp = (resourceIndex) => {
    const completedResources =
      resourcePushData &&
      resourcePushData
        .filter((resource) => !resource?.isComplete)
        .sort((a, b) => a.index - b.index);

    const currentIndex = completedResources.findIndex(
      (resource) => resource?.index === resourceIndex?.index
    );

    if (currentIndex > 0) {
      const prevIndex = currentIndex - 1;

      const downIndex = completedResources[prevIndex]?.index;
      swapRows(resourceIndex?.index, downIndex);
    }
  };

  const handleMoveDown = (resourceIndex) => {
    const completedResources =
      resourcePushData &&
      resourcePushData
        .filter((resource) => !resource?.isComplete)
        .sort((a, b) => a.index - b.index);

    const currentIndex = completedResources.findIndex(
      (resource) => resource?.index === resourceIndex?.index
    );

    const nextIndex = currentIndex + 1;

    const upIndex = completedResources[nextIndex]?.index;
    swapRows(resourceIndex?.index, upIndex);
  };

  const handleAllianceChange = (event) => {
    setSelectedAlliance(event.target.value);
  };

  //-----------------UseEffects -------------- //

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (editedIndex !== null && editedFieldName !== null) {
      const updatedRow = resourcePushData.find((row) => row.id === editedIndex);
      if (updatedRow) {
        const updatedData = { ...updatedRow, [editedFieldName]: editedValue };
        dispatch(saveData([updatedData]));
      }
    }
  }, [editedIndex, editedFieldName, editedValue, resourcePushData, dispatch]);

  useEffect(() => {
    if (resourcePush && resourcePush.length > 0) {
      setResourcePushData(resourcePush);

      const multiplierDoc = resourcePush.find(
        (doc) => doc?.id === "multiplier"
      );
      if (multiplierDoc) {
        setMultiplier(multiplierDoc.multiplier);
      }
    }
  }, [resourcePush]);

  useEffect(() => {
    if (filteredData && filteredData[0] && filteredData[0].data) {
      const data = filteredData[0].data;
      const uniqueData = [];
      const seenNames = new Set();
      data.forEach((item) => {
        if (!seenNames.has(item.GameAccountName)) {
          seenNames.add(item.GameAccountName);
          uniqueData.push(item);
        }
      });
      setFilterData(uniqueData);
    }
  }, [filteredData]);

  useEffect(() => {
    if (permission && permissionData) {
      const rolePermission = permissionData[role];
      const functionalPermission = permissionData[functionalRole];
      if (
        functionalPermission !== undefined &&
        functionalPermission.trim() !== ""
      ) {
        setCondition(functionalPermission);
      } else if (rolePermission !== undefined && rolePermission.trim() !== "") {
        setCondition(rolePermission);
      }
    }
  }, [permission, permissionData, role, functionalRole]);

  useEffect(() => {
    if (webHooks) {
      const resourcePushWebhook = webHooks.find(
        (hook) => hook.id === "resourcePush"
      );
      if (resourcePushWebhook) {
        setWebhookURL(resourcePushWebhook?.webhookURL);
        setWebhookMessage(resourcePushWebhook?.webhookMessage);
      }
    }
  }, [webHooks]);

  useEffect(() => {
    if (serverSettingsData && serverSettingsData.length > 0) {
      const data = serverSettingsData[0];
      setTimeZone(data?.timezone);
      setServerUrl(data?.serverUrl);
    }
  }, [serverSettingsData]);

  useEffect(() => {
    checkIfFormIsValid();
  }, [selectedAlliance, formData]);

  useEffect(() => {
    if (latestMapInformation.length > 0) {
      const alliances = latestMapInformation
        .map((data) => data.allianceTag)
        .filter((allianceTag) => allianceTag);
      const uniqueAlliances = [...new Set(alliances)];
      setUniqueAlliances(uniqueAlliances);
      setFilteredAlliances(uniqueAlliances);
    }
  }, [latestMapInformation]);

  useEffect(() => {
    if (latestMapInformation && formData.playerName && formData.villageName) {
      const matchingInfo = latestMapInformation.filter(
        (info) =>
          info.playerName === formData.playerName &&
          info.villageName === formData.villageName
      );

      if (matchingInfo.length > 0) {
        setFormData((prevData) => ({
          ...prevData,
          x: matchingInfo[0]?.x,
          y: matchingInfo[0]?.y,
          fieldId: matchingInfo[0]?.fieldID,
        }));
      }
    }
  }, [latestMapInformation, formData.playerName, formData.villageName]);

  useEffect(() => {
    if (selectedAlliance) {
      const players = latestMapInformation
        .filter((data) => data.allianceTag === selectedAlliance)
        .map((data) => data.playerName);
      setFilteredPlayers([...new Set(players)]);

      const villages = latestMapInformation
        .filter((data) => data.allianceTag === selectedAlliance)
        .map((data) => data.villageName);
      setFilteredVillages([...new Set(villages)]);
    } else if (formData.playerName) {
      const playerData = latestMapInformation.filter(
        (data) => data.playerName === formData.playerName
      );

      const alliances = playerData.map((data) => data.allianceTag);
      setFilteredAlliances([...new Set(alliances)]);

      const villages = playerData.map((data) => data.villageName);
      setFilteredVillages([...new Set(villages)]);
    } else if (formData.villageName) {
      const villageData = latestMapInformation.filter(
        (data) => data.villageName === formData.villageName
      );

      const alliances = villageData.map((data) => data.allianceTag);
      setFilteredAlliances([...new Set(alliances)]);

      const players = villageData.map((data) => data.playerName);
      setFilteredPlayers([...new Set(players)]);
    } else {
      const allPlayers = latestMapInformation.map((data) => data.playerName);
      const allVillages = latestMapInformation.map((data) => data.villageName);
      setFilteredPlayers([...new Set(allPlayers)]);
      setFilteredVillages([...new Set(allVillages)]);
    }
  }, [
    selectedAlliance,
    latestMapInformation,
    formData.playerName,
    formData.villageName,
  ]);

  useEffect(() => {
    dispatch(getReportPushData());
    dispatch(getFilteredData());
    dispatch(getPermissions());
    dispatch(getWebhooks());
    dispatch(getServerSettingsData());
    dispatch(getReportsData());
    dispatch(getCurrentMapData());
  }, []);

  return (
    <>
      {condition == "Locked" ? (
        <div
          className="text-center d-flex justify-content-center align-items-center "
          style={{ height: "70vh" }}
        >
          <h2>
            This page is not available for{" "}
            {functionalPermission !== undefined &&
            functionalPermission.trim() !== ""
              ? functionalRole
              : role}
          </h2>
        </div>
      ) : (
        <div className="mt-3 container-fluid px-4">
          <h2 className="text-center mb-4">Resource Push</h2>
          <div className="d-flex justify-content-between align-items-center flex-wrap">
            <div className="d-flex gap-2 flex-wrap">
              {condition === "Full Access" && (
                <button
                  className="btn btn-info py-2 text-white"
                  onClick={PushToggleModal}
                >
                  Create Push
                </button>
              )}
              <button
                className="btn btn-info py-2 text-white"
                onClick={toggleModal}
              >
                Send Resources
              </button>
              {condition === "Full Access" && (
                <button
                  className="btn btn-info py-2 text-white"
                  onClick={toggleDiscordModal}
                  disabled={!condition === "Full Access"}
                >
                  Webhook
                </button>
              )}
            </div>
            <div className="d-flex align-items-center gap-3">
              {condition === "Full Access" && (
                <>
                  <div className="form-group mt-2 justify-content-center custom-checkbox-div">
                    <div className="mb-1 activee">
                      Finished/<span className="fw-bold">Active</span>
                    </div>
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input custom-checkbox mb-3"
                        type="checkbox"
                        checked={showCompleted}
                        onChange={(e) => setShowCompleted(e.target.checked)}
                        disabled={!condition === "Full Access" || loading}
                      />
                    </div>
                  </div>
                  <div className="form-group mt-2 justify-content-center custom-checkbox-div">
                    <div className="mb-1 activee text-center fw-bold">
                      Multiplier
                    </div>
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input custom-checkbox mb-3"
                        type="checkbox"
                        checked={multiplier}
                        onChange={(e) =>
                          handleMultiplierChange(e, "multiplier", "multiplier")
                        }
                        disabled={!condition === "Full Access" || loading}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>

          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col" className="text-center bg-info p-3">
                    Order
                  </th>
                  <th scope="col" className="text-center bg-info p-3">
                    Player Name
                  </th>
                  <th scope="col" className="text-center bg-info p-3">
                    Village Name
                  </th>
                  <th scope="col" className="text-center bg-info p-3">
                    X|Y
                  </th>
                  <th scope="col" className="text-center bg-info p-3">
                    Village Map Link
                  </th>
                  <th scope="col" className="text-center bg-info p-3">
                    Progress
                  </th>
                  <th scope="col" className="text-center bg-info p-3">
                    Resources Needed
                  </th>
                  <th scope="col" className="text-center bg-info p-3">
                    Priority
                  </th>
                  <th scope="col" className="text-center bg-info p-3">
                    Players Sent
                  </th>
                  <th scope="col" className="text-center bg-info p-3">
                    Last Update
                  </th>
                  <th scope="col" className="text-center bg-info p-3">
                    Comment
                  </th>
                </tr>
              </thead>
              {showCompleted ? (
                loading ? (
                  <tbody>
                    <tr>
                      <td className="text-center" colSpan="12">
                        <div className="d-flex justify-content-center align-items-center">
                          <div className="spinner-border" role="status"></div>
                          <span className="visually-hidden ms-2">
                            Loading...
                          </span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody>
                    {resourcePushData &&
                      resourcePushData
                        .filter(
                          (resource) =>
                            !resource?.isComplete &&
                            resource?.id !== "multiplier"
                        )
                        .slice()
                        .sort((a, b) => a.index - b.index)
                        .map((resource, index) => (
                          <>
                            <tr key={index}>
                              <td className="text-center">
                                {index > 0 && (
                                  <i
                                    role="button"
                                    className="fa fa-arrow-up p-2 cursor-pointer"
                                    onClick={() => handleMoveUp(resource)}
                                  />
                                )}
                                {!resource?.isComplete &&
                                  index <
                                    resourcePush.filter(
                                      (res) => !res?.isComplete
                                    ).length -
                                      2 && (
                                    <i
                                      role="button"
                                      className="fa fa-arrow-down p-2 cursor-pointer"
                                      onClick={() => handleMoveDown(resource)}
                                    />
                                  )}
                              </td>
                              <td className="text-center">
                                {resource?.playerName}
                              </td>
                              <td className="text-center">
                                {resource?.villageName}
                              </td>
                              <td className="text-center">
                                {resource ? `${resource.x},${resource.y}` : ""}
                              </td>
                              <td className="text-center">
                                <div className="d-flex justify-content-between align-items-center">
                                  {editedIndex === resource?.id &&
                                  editedFieldName === "villageMapLink" ? (
                                    <input
                                      type="text"
                                      value={editedValue}
                                      onChange={(e) =>
                                        setEditedValue(e.target.value)
                                      }
                                      onBlur={() => handleSave(resource?.id)}
                                      onKeyDown={(e) =>
                                        handleKeyDown(e, resource?.id)
                                      }
                                    />
                                  ) : (
                                    <>
                                      <a
                                        className="text-decoration-none"
                                        href={`${serverUrl}/karte.php?x=${resource?.x}&y=${resource?.y}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        {serverUrl}/karte.php?x=${resource?.x}
                                        &y=${resource?.y}
                                      </a>
                                    </>
                                  )}
                                </div>
                              </td>
                              <td className="text-center">
                                <span>
                                  {formatNumber(
                                    calculateTotalResources(resource)
                                  )}
                                  /
                                </span>
                                {formatNumber(resource?.resourcesNeeded)}
                                <div
                                  className="bar-graph"
                                  style={{
                                    position: "relative",
                                    width: `${Math.min(
                                      (calculateTotalResources(resource) /
                                        resource?.resourcesNeeded) *
                                        100,
                                      100
                                    )}%`,
                                    height: "15px",
                                    backgroundColor: (() => {
                                      const resources =
                                        (calculateTotalResources(resource) /
                                          resource?.resourcesNeeded) *
                                        100;
                                      if (resources < 0) return "transparent";
                                      if (resources < 30) return "green";
                                      else if (resources < 60) return "yellow";
                                      else return "red";
                                    })(),
                                  }}
                                ></div>
                              </td>
                              <td className="text-center">
                                {editedIndex === resource?.id &&
                                editedFieldName === "resourcesNeeded" ? (
                                  <input
                                    type="number"
                                    value={editedValue}
                                    onChange={(e) =>
                                      setEditedValue(e.target.value)
                                    }
                                    onBlur={() => handleSave(resource?.id)}
                                    onKeyDown={(e) =>
                                      handleKeyDown(e, resource?.id)
                                    }
                                  />
                                ) : (
                                  <span
                                    className="text-decoration-none"
                                    disabled={condition === "Read Only"}
                                    onClick={() => {
                                      if (condition !== "Read Only") {
                                        setEditedIndex(resource?.id);
                                        setEditedFieldName("resourcesNeeded");
                                        setEditedValue(
                                          resource?.resourcesNeeded || null
                                        );
                                      }
                                    }}
                                  >
                                    {resource?.resourcesNeeded?.toLocaleString() ||
                                      "\u00A0"}
                                  </span>
                                )}
                              </td>
                              <td className="table-cell text-center">
                                <select
                                  className="form-select"
                                  value={resource?.priority}
                                  disabled={condition === "Read Only"}
                                  onChange={(e) =>
                                    handleSelectChange(
                                      e,
                                      resource.id,
                                      "priority"
                                    )
                                  }
                                >
                                  <option value="">Select</option>
                                  <option value="low">Low</option>
                                  <option value="medium">Medium</option>
                                  <option value="high">High</option>
                                </select>
                              </td>
                              <td
                                className="text-center"
                                onClick={() => toggleRowExpansion(resource?.id)}
                              >
                                {reportsData &&
                                  reportsData.filter(
                                    (report) =>
                                      report.player === resource?.playerName
                                  ).length}
                                /{filterData?.length}{" "}
                                {expandCollapseIcon(resource?.id)}
                              </td>
                              <td className="text-center">
                                {findNearestPastArrival(resource)
                                  ? findNearestPastArrival(resource).format(
                                      "HH:mm:ss, DD MMM YYYY"
                                    )
                                  : ""}
                              </td>
                              <td className="text-center">
                                {editedIndex === resource?.id &&
                                editedFieldName === "comments" ? (
                                  <input
                                    type="text"
                                    value={editedValue}
                                    onChange={(e) =>
                                      setEditedValue(e.target.value)
                                    }
                                    onBlur={() => handleSave(resource?.id)}
                                    onKeyDown={(e) =>
                                      handleKeyDown(e, resource?.id)
                                    }
                                  />
                                ) : (
                                  <span
                                    className="text-decoration-none"
                                    disabled={condition === "Read Only"}
                                    onClick={() => {
                                      if (condition !== "Read Only") {
                                        setEditedIndex(resource?.id);
                                        setEditedFieldName("comments");
                                        setEditedValue(
                                          resource?.comments || null
                                        );
                                      }
                                    }}
                                  >
                                    {resource?.comments || "\u00A0"}
                                  </span>
                                )}
                              </td>
                            </tr>
                            {isRowExpanded(resource?.id) && (
                              <tr>
                                <td colSpan="10">
                                  <div className="mx-auto w-50 d-flex justify-content-between">
                                    <div></div>
                                    <button
                                      className="btn mb-2 btn-primary"
                                      onClick={() => {
                                        setResourceId(resource);
                                        toggleNewRecordModal();
                                      }}
                                    >
                                      Add
                                    </button>
                                  </div>
                                  <table className="mx-auto w-50">
                                    <thead>
                                      <tr>
                                        <th className="text-center text-white bg-secondary">
                                          Player
                                        </th>
                                        <th className="text-center text-white bg-secondary">
                                          Village Name
                                        </th>
                                        <th className="text-center text-white bg-secondary">
                                          Resources
                                        </th>
                                        <th className="text-center text-white bg-secondary">
                                          Status/Arriving Time
                                        </th>
                                        <th className="text-center text-white bg-secondary">
                                          Action
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {reportsData &&
                                        reportsData
                                          .filter(
                                            (report) =>
                                              report?.player ===
                                              resource?.playerName
                                          )
                                          .map((report, index) => (
                                            <tr key={index}>
                                              <td className="text-center">
                                                {editedIndex === report?.id &&
                                                editedFieldName ===
                                                  "fromPlayer" ? (
                                                  <input
                                                    type="text"
                                                    value={editedValue}
                                                    onChange={(e) =>
                                                      setEditedValue(
                                                        e.target.value
                                                      )
                                                    }
                                                    onBlur={() =>
                                                      handleRecordSave(
                                                        report?.id
                                                      )
                                                    }
                                                    onKeyDown={(e) =>
                                                      handleKey(
                                                        e,
                                                        report?.id,
                                                        resource?.id
                                                      )
                                                    }
                                                  />
                                                ) : (
                                                  <span
                                                    className="text-decoration-none"
                                                    onClick={() => {
                                                      setEditedIndex(
                                                        report?.id
                                                      );
                                                      setEditedFieldName(
                                                        "fromPlayer"
                                                      );
                                                      setEditedValue(
                                                        report?.fromPlayer ||
                                                          null
                                                      );
                                                    }}
                                                  >
                                                    {report?.fromPlayer ||
                                                      "\u00A0"}
                                                  </span>
                                                )}
                                              </td>
                                              <td className="text-center">
                                                {editedIndex === report?.id &&
                                                editedFieldName ===
                                                  "fromVillage" ? (
                                                  <input
                                                    type="text"
                                                    value={editedValue}
                                                    onChange={(e) =>
                                                      setEditedValue(
                                                        e.target.value
                                                      )
                                                    }
                                                    onBlur={() =>
                                                      handleRecordSave(
                                                        report?.id
                                                      )
                                                    }
                                                    onKeyDown={(e) =>
                                                      handleKey(
                                                        e,
                                                        report?.id,
                                                        resource?.id
                                                      )
                                                    }
                                                  />
                                                ) : (
                                                  <span
                                                    className="text-decoration-none"
                                                    onClick={() => {
                                                      setEditedIndex(
                                                        report?.id
                                                      );
                                                      setEditedFieldName(
                                                        "fromVillage"
                                                      );
                                                      setEditedValue(
                                                        report?.fromVillage ||
                                                          null
                                                      );
                                                    }}
                                                  >
                                                    {report?.fromVillage ||
                                                      "\u00A0"}
                                                  </span>
                                                )}
                                              </td>
                                              <td className="text-center">
                                                {editedIndex === report?.id &&
                                                editedFieldName ===
                                                  "resources" ? (
                                                  <input
                                                    type="text"
                                                    value={editedValue}
                                                    onChange={(e) =>
                                                      setEditedValue(
                                                        e.target.value
                                                      )
                                                    }
                                                    onBlur={() =>
                                                      handleRecordSave(
                                                        report?.id
                                                      )
                                                    }
                                                    onKeyDown={(e) =>
                                                      handleKey(
                                                        e,
                                                        report?.id,
                                                        resource?.id
                                                      )
                                                    }
                                                  />
                                                ) : (
                                                  <span
                                                    className="text-decoration-none"
                                                    onClick={() => {
                                                      setEditedIndex(
                                                        report?.id
                                                      );
                                                      setEditedFieldName(
                                                        "resources"
                                                      );
                                                      setEditedValue(
                                                        report?.resources ||
                                                          null
                                                      );
                                                    }}
                                                  >
                                                    {report?.resources.toLocaleString() ||
                                                      "\u00A0"}
                                                  </span>
                                                )}
                                              </td>
                                              <td className="text-center">
                                                {editedIndex === report?.id &&
                                                editedFieldName ===
                                                  "arrivalTime" ? (
                                                  <input
                                                    type="text"
                                                    value={editedValue}
                                                    onChange={(e) =>
                                                      setEditedValue(
                                                        e.target.value
                                                      )
                                                    }
                                                    onBlur={() =>
                                                      handleRecordSave(
                                                        report?.id
                                                      )
                                                    }
                                                    onKeyDown={(e) =>
                                                      handleKey(
                                                        e,
                                                        report?.id,
                                                        resource?.id
                                                      )
                                                    }
                                                  />
                                                ) : (
                                                  <span
                                                    className="text-decoration-none"
                                                    onClick={() => {
                                                      setEditedIndex(
                                                        report?.id
                                                      );
                                                      setEditedFieldName(
                                                        "arrivalTime"
                                                      );
                                                      setEditedValue(
                                                        report?.arrivalTime ||
                                                          null
                                                      );
                                                    }}
                                                  >
                                                    {(() => {
                                                      const currentTime =
                                                        moment()
                                                          .tz(timezone)
                                                          .format(
                                                            "HH:mm:ss, DD MMM YYYY"
                                                          );
                                                      const currentDateTime =
                                                        moment(
                                                          currentTime,
                                                          "HH:mm:ss, DD MMM YYYY"
                                                        );
                                                      const arrivalTime =
                                                        report?.arrivalTime;
                                                      const arrivalDateTime =
                                                        moment(
                                                          arrivalTime,
                                                          "HH:mm:ss, DD MMM YYYY"
                                                        );
                                                      return arrivalDateTime?.isBefore(
                                                        currentDateTime
                                                      ) ? (
                                                        <span className="text-success fw-bold">
                                                          Received
                                                        </span>
                                                      ) : (
                                                        report?.arrivalTime ||
                                                          "\u00A0"
                                                      );
                                                    })()}
                                                  </span>
                                                )}
                                              </td>
                                              <td className="text-center">
                                                <button
                                                  className="btn btn-danger"
                                                  onClick={() =>
                                                    dispatch(
                                                      deletePushRecord(
                                                        resource?.id,
                                                        report?.id
                                                      )
                                                    )
                                                  }
                                                >
                                                  -
                                                </button>
                                              </td>
                                            </tr>
                                          ))}
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            )}
                          </>
                        ))}
                  </tbody>
                )
              ) : (
                <tbody>
                  {resourcePushData
                    .filter(
                      (resource) =>
                        resource?.isComplete && resource?.id !== "multiplier"
                    )
                    .slice()
                    .sort((a, b) => a.index - b.index)
                    .map((resource, index) => (
                      <>
                        <tr key={index}>
                          <td className="text-center">
                            {index > 0 && (
                              <i
                                role="button"
                                className="fa fa-arrow-up p-2 cursor-pointer"
                              />
                            )}
                            {resource?.isComplete &&
                              index <
                                resourcePush.filter((res) => res?.isComplete)
                                  .length -
                                  1 && (
                                <i
                                  role="button"
                                  className="fa fa-arrow-down p-2 cursor-pointer"
                                />
                              )}
                          </td>
                          <td className="text-center">
                            {resource?.playerName}
                          </td>
                          <td className="text-center">
                            {resource?.villageName}
                          </td>
                          <td className="text-center">
                            {resource ? `${resource.x},${resource.y}` : ""}
                          </td>
                          <td className="text-center">
                            <a
                              className="text-decoration-none"
                              href={`${serverUrl}/karte.php?x=${resource?.x}&y=${resource?.y}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {serverUrl}/karte.php?x=${resource?.x}
                              &y=${resource?.y}
                            </a>
                          </td>
                          <td className="text-center">
                            <span>
                              {formatNumber(calculateTotalResources(resource))}/
                            </span>
                            {formatNumber(resource?.resourcesNeeded)}
                            <div
                              className="bar-graph"
                              style={{
                                position: "relative",
                                width: `${Math.min(
                                  (calculateTotalResources(resource) /
                                    resource?.resourcesNeeded) *
                                    100,
                                  100
                                )}%`,
                                height: "15px",
                                backgroundColor: (() => {
                                  const resources =
                                    (calculateTotalResources(resource) /
                                      resource?.resourcesNeeded) *
                                    100;
                                  if (resources < 0) return "transparent";
                                  if (resources < 30) return "green";
                                  else if (resources < 60) return "yellow";
                                  else return "red";
                                })(),
                              }}
                            ></div>
                          </td>
                          <td className="text-center">
                            {resource?.resourcesNeeded?.toLocaleString()}
                          </td>
                          <td className="table-cell text-center">
                            <select
                              className="form-select"
                              value={resource?.priority}
                              disabled
                            >
                              <option value="">Select</option>
                              <option value="low">Low</option>
                              <option value="medium">Medium</option>
                              <option value="high">High</option>
                            </select>
                          </td>
                          <td
                            className="text-center"
                            onClick={() => toggleRowExpansion(resource?.id)}
                          >
                            {reportsData &&
                              reportsData.filter(
                                (report) =>
                                  report.player === resource?.playerName
                              ).length}
                            /{filterData?.length}
                            {expandCollapseIcon(resource?.id)}
                          </td>
                          <td className="text-center">
                            {findNearestPastArrival(resource)
                              ? findNearestPastArrival(resource).format(
                                  "HH:mm:ss, DD MMM YYYY"
                                )
                              : ""}
                          </td>
                          <td className="text-center">{resource?.comments}</td>
                        </tr>
                        {isRowExpanded(resource?.id) && (
                          <tr>
                            <td colSpan="10">
                              <table className="mx-auto w-50">
                                <thead>
                                  <tr>
                                    <th className="text-center text-white bg-secondary">
                                      Player
                                    </th>
                                    <th className="text-center text-white bg-secondary">
                                      Village Name
                                    </th>
                                    <th className="text-center text-white bg-secondary">
                                      Resources
                                    </th>
                                    <th className="text-center text-white bg-secondary">
                                      Status/Arriving Time
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {reportsData &&
                                    reportsData
                                      .filter(
                                        (report) =>
                                          report?.player ===
                                          resource?.playerName
                                      )
                                      .map((report, index) => (
                                        <tr key={index}>
                                          <td className="text-center">
                                            {report?.fromPlayer}
                                          </td>
                                          <td className="text-center">
                                            {report?.fromVillage}
                                          </td>
                                          <td className="text-center">
                                            {report?.resources.toLocaleString()}
                                          </td>
                                          <td className="text-center">
                                            {(() => {
                                              const currentTime = moment()
                                                .tz(timezone)
                                                .format(
                                                  "HH:mm:ss, DD MMM YYYY"
                                                );
                                              const currentDateTime = moment(
                                                currentTime,
                                                "HH:mm:ss, DD MMM YYYY"
                                              );
                                              const arrivalTime =
                                                report?.arrivalTime;
                                              const arrivalDateTime = moment(
                                                arrivalTime,
                                                "HH:mm:ss, DD MMM YYYY"
                                              );
                                              return arrivalDateTime?.isBefore(
                                                currentDateTime
                                              ) ? (
                                                <span className="text-success fw-bold">
                                                  Received
                                                </span>
                                              ) : (
                                                report?.arrivalTime || "\u00A0"
                                              );
                                            })()}
                                          </td>
                                        </tr>
                                      ))}
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        )}
                      </>
                    ))}
                </tbody>
              )}
            </table>
          </div>

          <ResourcePushReportParse toggle={toggleModal} modal={modalReport} />

          <PushModal
            isOpen={pushModalOpen}
            toggle={PushToggleModal}
            uniqueAlliances={filteredAlliances}
            selectedAlliance={selectedAlliance}
            handleAllianceChange={handleAllianceChange}
            filteredPlayers={filteredPlayers}
            formData={formData}
            handleChange={handleChange}
            filteredVillages={filteredVillages}
            handleSubmit={handleSubmit}
            isSubmitDisabled={isSubmitDisabled}
            loading={isSubmitting}
          />

          <NewRecordModal
            isOpen={newRecordModalOpen}
            toggle={toggleNewRecordModal}
            newRecord={newRecord}
            handleRecordChange={handleRecordChange}
            handleRecordSubmit={handleRecordSubmit}
            isSubmitDisabled={!isAllFieldsFilled()}
          />

          <DiscordWebhookModal
            isOpen={discordModalOpen}
            toggle={toggleDiscordModal}
            webhookURL={webhookURL}
            setWebhookURL={setWebhookURL}
            webhookMessage={webhookMessage}
            setWebhookMessage={setWebhookMessage}
            handleDiscordSave={handleDiscordSave}
            isModified={isModified}
            setIsModified={setIsModified}
          />
        </div>
      )}
    </>
  );
};

export default ResourcePush;
