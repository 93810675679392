const initialState = {
  hammerData: [],
  Teuton: [],
  Gual: [],
  Roman: [],
  columnData: [],
  loading: false,
};

const HammerTrackerReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_HAMMER_ROWS":
      return {
        ...state,
        hammerData: action.payload,
      };
    case "GET_TEUTON_ROWS":
      return {
        ...state,
        Teuton: action.payload,
      };
    case "GET_GUAL_ROWS":
      return {
        ...state,
        Gual: action.payload,
      };
    case "GET_ROMAN_ROWS":
      return {
        ...state,
        Roman: action.payload,
      };
    case "GET_MODIFIERS_DATA":
      return {
        ...state,
        Modifiers: action.payload,
      };
    case "GET_ALL_COLUMN_DATA_SUCCESS":
      return {
        ...state,
        columnData: action.payload,
      };
    case "HAMMER_TRACKER_LOADING":
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};

export default HammerTrackerReducer;
