import { toast } from "react-toastify";
import firebase from "../../config/firebase";

export const updateFarmlist =
  (payload, onSuccess = () => {}) =>
  async (dispatch) => {
    try {
      if (!payload.player) {
        console.error("Invalid payload: player is missing");
        return;
      }

      const firestore = firebase.firestore();
      const collectionRef = firestore.collection("myFarmlist");

      for (const village of payload.villages) {
        const querySnapshot = await collectionRef
          .where("player", "==", payload.player)
          .where("village", "==", payload.village)
          .where("x", "==", village.x)
          .where("y", "==", village.y)
          .get();

        if (querySnapshot.empty) {
          const docData = {
            id: collectionRef.doc().id,
            player: payload.player,
            village: payload.village,
            x: village.x,
            y: village.y,
            serverDay: payload.serverDay,
          };

          if (village.raidedVillage) {
            docData.raidedVillage = village.raidedVillage;
          }
          if (village.raidedPlayer) {
            docData.raidedPlayer = village.raidedPlayer;
          }
          if (village.raidedAlliance) {
            docData.raidedAlliance = village.raidedAlliance;
          }

          await collectionRef.doc(docData.id).set(docData);
        }
      }

      toast.success("Farmlist updated successfully.");
      onSuccess();
      dispatch(getFarmlistData());
    } catch (error) {
      console.error("Error processing the request:", error);
      toast.error("Error updating farmlist.");
    }
  };

export const addNewVillage =
  (player, village, targetedVillage, onSuccess = () => {}) =>
  async (dispatch) => {
    try {
      if (!player) {
        console.error("Invalid payload");
        return;
      }

      const querySnapshot = await firebase
        .firestore()
        .collection("myFarmlist")
        .where("player", "==", player)
        .where("raiderVillage", "==", village)
        .where("raidedVillage.x", "==", parseInt(targetedVillage.x))
        .where("raidedVillage.y", "==", parseInt(targetedVillage.y))
        .get();

      if (!querySnapshot.empty) {
        toast.info("The targeted village is already in the farmlist.");
      } else {
        try {
          const newDocRef = await firebase
            .firestore()
            .collection("myFarmlist")
            .doc();
          await newDocRef.set({
            id: newDocRef.id,
            player: player,
            village: village,
            x: parseInt(targetedVillage.x),
            y: parseInt(targetedVillage.y),
            raidedPlayer: targetedVillage.playerName,
            raidedVillage: targetedVillage.villageName,
            raidedAlliance: targetedVillage.allianceTag,
          });
          toast.success(`Farmlist added successfully.`);
        } catch (addError) {
          console.error("Error adding document:", addError);
          toast.error("Error adding farmlist.");
        }
      }

      onSuccess();
      dispatch(getFarmlistData());
    } catch (error) {
      console.error("Error fetching documents:", error);
      toast.error("Error processing the request.");
    }
  };

export const getFarmlistData = () => async (dispatch) => {
  try {
    dispatch(loader(true));
    const snapshot = await firebase.firestore().collection("myFarmlist").get();
    const rows = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    dispatch({ type: "GET_FARMLIST_DATA", payload: rows });
    dispatch(loader(false));
  } catch (error) {
    console.error("Error fetching rows:", error);
    dispatch(loader(false));
  }
};

export const deleteFarmlistRow = (id) => async (dispatch) => {
  try {
    const docRef = firebase.firestore().collection("myFarmlist").doc(id);
    await docRef.delete();

    dispatch({ type: "DELETE_FARMLIST_ROW" });
    dispatch(getFarmlistData());
    toast.success("Farmlist row deleted successfully.");
  } catch (error) {
    console.error("Error deleting farmlist row:", error);
    dispatch({ type: "DELETE_FARMLIST_ROW_ERROR", payload: error });
    toast.error("Error deleting farmlist row.");
  }
};

export const resetFarmlist = (player) => async (dispatch) => {
  try {
    const querySnapshot = await firebase
      .firestore()
      .collection("myFarmlist")
      .where("player", "==", player)
      .get();

    const batch = firebase.firestore().batch();
    querySnapshot.forEach((doc) => {
      batch.delete(doc.ref);
    });

    await batch.commit();

    dispatch({ type: "RESET_FARMLIST_SUCCESS" });
    dispatch(getFarmlistData());
    toast.success("Farmlist reset successfully.");
  } catch (error) {
    console.error("Error resetting farmlist:", error);
    dispatch({ type: "RESET_FARMLIST_ERROR", payload: error });
    toast.error("Error resetting farmlist.");
  }
};

export const loader = (val) => async (dispatch) => {
  dispatch({
    type: "FARM_SEARCH_LOADING",
    payload: val,
  });
};

const processDocument = async (doc) => {
  const data = doc.data();
  const currentPlayer = data.playerName;
  const currentPlayerID = data.playerID;
  const allianceID = data?.allianceID;
  const currentDate = 1716246000;
  const previousDate = 1716159600;
  let oldDoc = false;

  let newPlayerData = await firebase
    .firestore()
    .collection("playersDataHistory")
    .where("playerID", "==", currentPlayerID)
    .where("currentDate", "==", currentDate)
    .get();

  if (newPlayerData.empty) {
    newPlayerData = {
      currentDate: data.currentDate,
      allianceTag: data?.allianceTag,
      allianceID: data?.allianceID,
      playerID: data.playerID,
      playerName: data.playerName,
      serverDay: data.serverDay,
      totalPopulation: 0,
      tribe: data.tribe,
      conqueredVillagesCount: 0,
      settledVillagesCount: 0,
      lostVillagesCount: 0,
      villagesCount: 0,
      villages: [],
      previousAllianceId: 0,
    };
  } else {
    newPlayerData = newPlayerData.docs[0].data();
    oldDoc = true;
  }

  const villageExistsInLastDay = await firebase
    .firestore()
    .collection("timeseriesMapInformation")
    .where("villageID", "==", data.villageID)
    .where("currentDate", "==", previousDate)
    .get();

  let isJustSettled = villageExistsInLastDay.docs.some((doc) => {
    return (
      (doc.data().x != data.x || doc.data().y != data.y) &&
      doc.data().playerID == data.playerID
    );
  });

  let isJustConquered = villageExistsInLastDay.docs.some((doc) => {
    return (
      doc.data().x == data.x &&
      doc.data().y == data.y &&
      doc.data().playerID != data.playerID
    );
  });

  const playerLastDayData = await firebase
    .firestore()
    .collection("playersDataHistory")
    .where("playerID", "==", data.playerID)
    .where("currentDate", "==", previousDate)
    .get();

  if (playerLastDayData.empty) {
    newPlayerData.isNew = true;
    newPlayerData.previousAllianceId = 0;
    newPlayerData.lastPopulationChange = data?.population;
    newPlayerData.lastPopulationChangeDay = data?.serverDay;
  } else {
    const lastDayPopulation = playerLastDayData.docs[0].data().totalPopulation;
    if (playerLastDayData.docs[0].data().allianceID !== data?.allianceID) {
      newPlayerData.previousAllianceId =
        playerLastDayData.docs[0].data().allianceID;
    }

    // if (data.totalPopulation > lastDayPopulation) {
    //   newPlayerData.isActive = true;
    // }

    if (data.totalPopulation !== lastDayPopulation) {
      newPlayerData.lastPopulationChange =
        data.totalPopulation - lastDayPopulation;
      newPlayerData.lastPopulationChangeDay = data?.serverDay;
    } else {
      newPlayerData.lastPopulationChange =
        playerLastDayData.docs[0].data().lastPopulationChange ?? 0;
      newPlayerData.lastPopulationChangeDay =
        playerLastDayData.docs[0].data().lastPopulationChangeDay ?? 0;
    }

    if (data?.serverDay - newPlayerData.lastPopulationChangeDay > 7) {
      newPlayerData.isActive = false;
    } else {
      newPlayerData.isActive = true;
    }
  }

  const villagePreviousData = playerLastDayData.docs.find((doc) =>
    doc.data().villages.find((village) => village.villageID == data.villageID)
  );

  let gameAcquiredDay = villagePreviousData?.gameAcquiredDay || 0;

  if (gameAcquiredDay === 0 || isJustSettled || isJustConquered) {
    if (!isJustConquered) {
      isJustSettled = true;
    }
    gameAcquiredDay = data.serverDay;
  }

  const village = {
    villageName: data.villageName,
    villageID: data?.villageID,
    x: data.x,
    y: data.y,
    fieldID: data.fieldID,
    gameAcquiredDay: gameAcquiredDay,
    population: data.population,
    isSettled: isJustSettled || villagePreviousData?.isSettled || false,
    isConquered: isJustConquered || villagePreviousData?.isConquered || false,
  };
  if (isJustConquered) {
    let previousPlayer = villageExistsInLastDay.playerID;
    newPlayerData.conqueredVillagesCount += 1;

    const playerDataSnapshot = await firebase
      .firestore()
      .collection("playersDataHistory")
      .where("playerID", "==", previousPlayer)
      .where("currentDate", "==", currentDate)
      .get();

    if (!playerDataSnapshot.empty) {
      let playerDoc = playerDataSnapshot.docs[0];
      let lostVillagesCount = playerDoc.data().lostVillagesCount + 1;
      await firebase
        .firestore()
        .collection("playersDataHistory")
        .doc(`${playerDoc.serverDay}-${playerDoc.playerID}`)
        .update({ lostVillagesCount });
    } else {
      const previousPlayerLastDataSnapshot = await firebase
        .firestore()
        .collection("playersDataHistory")
        .where("playerID", "==", previousPlayer)
        .where("currentDate", "==", previousDate)
        .get();

      if (!previousPlayerLastDataSnapshot.empty) {
        let previousPlayerNewDoc =
          previousPlayerLastDataSnapshot.docs[0].data();
        previousPlayerNewDoc.currentDate = currentDate;
        previousPlayerNewDoc.lostVillagesCount =
          (previousPlayerNewDoc.lostVillagesCount || 0) + 1;
        previousPlayerNewDoc.villagesCount = 0;
        previousPlayerNewDoc.villages = [];
        previousPlayerNewDoc.totalPopulation = 0;

        await firebase
          .firestore()
          .collection("playersDataHistory")
          .doc(
            `${previousPlayerNewDoc.serverDay}-${previousPlayerNewDoc.playerID}`
          )
          .set(previousPlayerNewDoc);
      }
    }
  }

  newPlayerData.villages.push(village);
  newPlayerData.villagesCount += 1;
  newPlayerData.totalPopulation += data.population;

  if (isJustSettled) {
    newPlayerData.settledVillagesCount += 1;
  }

  await firebase
    .firestore()
    .collection("playersDataHistory")
    .doc(`${newPlayerData.serverDay}-${newPlayerData.playerID}`)
    .set(newPlayerData);

  let allianceTagExists = await firebase
    .firestore()
    .collection("allianceDataHistory")
    .where("allianceTag", "==", data.allianceTag)
    .get();

  let newAllianceData;

  if (allianceTagExists.empty) {
    newAllianceData = {
      currentDate: data.currentDate,
      allianceTag: data?.allianceTag,
      allianceID: data?.allianceID,
      serverDay: data.serverDay,
      totalPopulation: 0,
      conqueredVillagesCount: 0,
      settledVillagesCount: 0,
      lostVillagesCount: 0,
      villagesCount: 0,
      foundingServerDay: data?.serverDay,
      members: [],
      foundingMembers: [],
      newMembers: [],
      leavingMembers: [],
      deletingMembers: [],
      isFirst: true,
    };
    newAllianceData.foundingMembers.push(currentPlayer);
    newAllianceData.members.push(currentPlayer);
    newAllianceData.newMembers.push(currentPlayer);
    newAllianceData.foundingServerDay = data?.serverDay;
  } else {
    let allianceDataDoc = await firebase
      .firestore()
      .collection("allianceDataHistory")
      .where("allianceID", "==", allianceID)
      .where("currentDate", "==", currentDate)
      .get();

    if (allianceDataDoc.empty) {
      newAllianceData = {
        currentDate: data.currentDate,
        allianceTag: data?.allianceTag,
        allianceID: data?.allianceID,
        serverDay: data.serverDay,
        totalPopulation: 0,
        conqueredVillagesCount: 0,
        settledVillagesCount: 0,
        lostVillagesCount: 0,
        villagesCount: 0,
        members: [],
        newMembers: [],
        leavingMembers: [],
        deletingMembers: [],
      };
    } else {
      newAllianceData = allianceDataDoc.docs[0].data();
      oldDoc = true;
    }
  }

  if (newAllianceData.isFirst) {
    if (!newAllianceData.foundingMembers.includes(currentPlayer)) {
      newAllianceData.foundingMembers.push(currentPlayer);
      newAllianceData.members.push(currentPlayer);
      newAllianceData.newMembers.push(currentPlayer);
    }
  }

  let isJustSettledNow = villageExistsInLastDay.docs.some((doc) => {
    return (
      (doc.data().x != data.x || doc.data().y != data.y) &&
      doc.data().allianceID == data.allianceID
    );
  });

  let isJustConqueredNow = villageExistsInLastDay.docs.some((doc) => {
    return (
      doc.data().x == data.x &&
      doc.data().y == data.y &&
      doc.data().allianceID != data.allianceID
    );
  });

  const allianceLastDayData = await firebase
    .firestore()
    .collection("allianceDataHistory")
    .where("allianceID", "==", data.allianceID)
    .where("currentDate", "==", previousDate)
    .get();

  if (!allianceLastDayData.empty) {
    let leavingMembers = [];
    newAllianceData.foundingMembers =
      allianceLastDayData.docs[0].data().foundingMembers;

    for (const doc of allianceLastDayData.docs) {
      const members = doc.data().members;

      if (!newAllianceData.members.includes(data.playerName)) {
        newAllianceData.members.push(data.playerName);
      }

      if (!members.includes(data.playerName)) {
        newAllianceData.newMembers.push(data.playerName);
      }

      leavingMembers = members.filter(
        (element) => !newAllianceData.members.includes(element)
      );

      console.log("leavingMembers", leavingMembers);

      if (leavingMembers.length > 0) {
        const leavingMembersDocs = await firebase
          .firestore()
          .collection("playerDataHis")
          .where("allianceTag", "!=", "")
          .where("playerName", "in", leavingMembers)
          .where("currentDate", "==", currentDate)
          .get();


        let deletingMembers = leavingMembersDocs.docs.filter(
          (doc) => !leavingMembers.includes(doc.data().playerName)
        );


        if (leavingMembersDocs.docs.length === 0) {
          deletingMembers = leavingMembers;
        }

        console.log(deletingMembers, "deleting Members");

        let leavingMember = leavingMembersDocs.docs.filter((doc) =>
          leavingMembers.includes(doc.data().playerName)
        );


        if (leavingMember.length > 0) {
          newAllianceData.leavingMembers = leavingMember;
        }

        if (deletingMembers.length > 0) {
          newAllianceData.deletingMembers = deletingMembers;
        }

        let conqueredVillagesCount = 0;
        let lostVillagesCount = 0;
        let settledVillagesCount = 0;

        for (const member of newAllianceData.members) {
          const membersDocs = await firebase
            .firestore()
            .collection("playerDataHis")
            .where("allianceTag", "!=", "")
            .where("playerName", "==", member)
            .where("currentDate", "==", previousDate)
            .get();

          membersDocs.forEach((doc) => {
            const data = doc.data();
            conqueredVillagesCount += data.conqueredVillagesCount || 0;
            lostVillagesCount += data.lostVillagesCount || 0;
            settledVillagesCount += data.settledVillagesCount || 0;
          });
        }

        newAllianceData.conqueredVillagesCount = conqueredVillagesCount;
        newAllianceData.lostVillagesCount = lostVillagesCount;
        newAllianceData.settledVillagesCount = settledVillagesCount;
      }
    }
  }

  if (isJustConqueredNow) {
    let previousAlliance = villageExistsInLastDay.allianceID;
    newAllianceData.conqueredVillagesCount += 1;

    const allianceDataSnapshot = await firebase
      .firestore()
      .collection("allianceDataHistory")
      .where("allianceID", "==", previousAlliance)
      .where("currentDate", "==", currentDate)
      .get();

    if (!allianceDataSnapshot.empty) {
      let allianceDoc = allianceDataSnapshot.docs[0];
      let lostVillagesCount = allianceDoc.data().lostVillagesCount + 1;
      await firebase
        .firestore()
        .collection("allianceDataHistory")
        .doc(`${allianceDoc.serverDay}-${allianceDoc.playerID}`)
        .update({ lostVillagesCount });
    } else {
      const previousAllianceLastDataSnapshot = await firebase
        .firestore()
        .collection("allianceDataHistory")
        .where("allianceID", "==", previousAlliance)
        .where("currentDate", "==", previousDate)
        .get();

      if (!previousAllianceLastDataSnapshot.empty) {
        let previousAllianceNewDoc =
          previousAllianceLastDataSnapshot.docs[0].data();
        previousAllianceNewDoc.currentDate = currentDate;
        previousAllianceNewDoc.serverDay =
          (previousAllianceNewDoc.serverDay || 0) + 1;
        previousAllianceNewDoc.lostVillagesCount =
          (previousAllianceNewDoc.lostVillagesCount || 0) + 1;
        previousAllianceNewDoc.villagesCount = 0;
        previousAllianceNewDoc.totalPopulation = 0;

        await firebase
          .firestore()
          .collection("allianceDataHistory")
          .doc(
            `${previousAllianceNewDoc.serverDay}-${previousAllianceNewDoc.allianceID}`
          )
          .set(previousAllianceNewDoc, { merge: true });
      }
    }
  }
  if (!isJustConqueredNow) {
    isJustSettledNow = true;
  }

  newAllianceData.villagesCount += 1;
  newAllianceData.totalPopulation += data.population;

  if (isJustSettledNow) {
    newAllianceData.settledVillagesCount += 1;
  }


  await firebase
    .firestore()
    .collection("allianceDataHistory")
    .doc(`${newAllianceData.serverDay}-${newAllianceData.allianceID}`)
    .set({ ...newAllianceData }, { merge: true });
};

export const updateHistory = () => async (dispatch) => {
  try {
    let currentDate = 1716246000;
    const collectionRef = firebase
      .firestore()
      .collection("timeseriesMapInformation");
    const batchSize = 100;
    let lastVisible = null;

    const processBatch = async () => {
      let query = collectionRef
        .where("currentDate", "==", currentDate)
        .limit(batchSize);

      if (lastVisible) {
        query = query.startAfter(lastVisible);
      }

      const querySnapshot = await query.get();

      if (querySnapshot.empty) {
        return false;
      }

      lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];

      console.log("test", querySnapshot.docs);

      for (const doc of querySnapshot.docs) {
        await processDocument(doc);
      }

      return true;
    };

    let moreDocuments = true;
    while (moreDocuments) {
      moreDocuments = await processBatch();
    }

    dispatch({ type: "UPDATE_PLAYER_HISTORY" });
    toast.success("Player Data Updated successfully.");
  } catch (error) {
    dispatch({ type: "UPDATE_PLAYER_HISTORY_ERROR", payload: error });
    console.log(error);
    toast.error("Error updating player data.");
  }
};
