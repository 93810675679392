import React, { useEffect, useState, useCallback } from "react";
import { Card, CardBody, CardTitle, CardText, Button, Label } from "reactstrap";
import axios from "axios";
import "../../assets/css/Statistics.css";
import Select from "react-select";
import TotalStatsSnapshot from "./TotalStatsSnapshot";
import PopulationGraph from "./PopulationGraph";
import {
  getPlayerHistoryData,
  getAllianceHistoryData,
  getPlayerRank,
  getAllianceRank,
} from "../../store/actions/playerStatisticsAction";
import { useDispatch, useSelector } from "react-redux";
import typesenseServer from "../../config/typesenseConfig";
import moment from "moment-timezone";
import { getServerSettingsData } from "../../store/actions/serverSettingsAction";
import StatisticsTable from "./StatisticsTable";
import VillageHistoryModal from "./VillageHistoryModal";
import AllianceHistoryModal from "./AllianceHistoryModal";
import AllianceHistory from "./AllianceHistory";
import debounce from "lodash.debounce";
import { toast } from "react-toastify";

const Statistics = ({ condition }) => {
  const dispatch = useDispatch();
  const { serverSettingsData } = useSelector((state) => state.serverSettings);
  const [isOpen, setIsOpen] = useState(false);
  const [showSummary, setShowSummary] = useState(false);
  const [modal, setModal] = useState(false);
  const [allianceModal, setAllianceModal] = useState(false);
  const [allianceOptions, setAllianceOptions] = useState([]);
  const [playerOptions, setPlayerOptions] = useState([]);
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const [selectedAlliance, setSelectedAlliance] = useState(null);
  const [timezone, setTimeZone] = useState();
  const [serverUrl, setServerUrl] = useState();
  const [playerHistory, setPlayerHistory] = useState([]);
  const [allianceHistory, setAllianceHistory] = useState([]);
  const [todayRank, setTodayRank] = useState(null);
  const [sevenDaysAgoRank, setSevenDaysAgoRank] = useState(null);

  const toggleVillageHistoryModal = () => setModal(!modal);
  const toggleAllianceHistoryModal = () => setAllianceModal(!allianceModal);
  const toggle = () => setIsOpen(!isOpen);

  const showFullHistory = () => {
    setShowSummary(false);
    setIsOpen(true);
  };

  const showDeletionSummary = () => {
    setShowSummary(true);
    setIsOpen(true);
  };

  const getPlaytime = (history) => {
    if (!history || history.length === 0) return null;

    const serverDays = history.map((item) => item.serverDay);
    const maxServerDay = Math.max(...serverDays);
    const minServerDay = Math.min(...serverDays);

    return maxServerDay - minServerDay + 1;
  };

  const playtime = getPlaytime(playerHistory);

  const handleAllianceSearch = useCallback(
    debounce(async (inputValue) => {
      if (inputValue) {
        try {
          const response = await axios.get(
            `https://${typesenseServer.nodes[0].host}:${typesenseServer.nodes[0].port}/collections/timeseriesMapInformation/documents/search`,
            {
              params: {
                q: inputValue.toString(),
                query_by: "allianceTag",
                per_page: 10,
              },
              headers: {
                "X-TYPESENSE-API-KEY": typesenseServer.apiKey,
                "Content-Type": "application/json",
              },
            }
          );

          const uniqueAllianceTags = Array.from(
            new Set(response.data.hits.map((hit) => hit.document.allianceTag))
          );

          const allianceOptions = uniqueAllianceTags.map((allianceTag) => ({
            label: allianceTag,
            value: allianceTag,
          }));
          setAllianceOptions(allianceOptions);
        } catch (error) {
          toast.error("Typesense search error:", error);
        }
      } else {
        setAllianceOptions([]);
      }
    }, 300),
    []
  );

  const handlePlayerSearch = useCallback(
    debounce(async (inputValue) => {
      if (inputValue) {
        try {
          const response = await axios.get(
            `https://${typesenseServer.nodes[0].host}:${typesenseServer.nodes[0].port}/collections/timeseriesMapInformation/documents/search`,
            {
              params: {
                q: inputValue.toString(),
                query_by: "playerName",
                per_page: 10,
              },
              headers: {
                "X-TYPESENSE-API-KEY": typesenseServer.apiKey,
                "Content-Type": "application/json",
              },
            }
          );

          const uniquePlayerNames = Array.from(
            new Set(response.data.hits.map((hit) => hit.document.playerName))
          );

          const playerOptions = uniquePlayerNames.map((playerName) => ({
            label: playerName,
            value: playerName,
          }));
          setPlayerOptions(playerOptions);
        } catch (error) {
          toast.error("Typesense search error:", error);
        }
      } else {
        setPlayerOptions([]);
      }
    }, 300),
    []
  );

  const handlePlayerSelect = (selectedOption) => {
    setSelectedPlayer(selectedOption);
    setSelectedAlliance(null);
  };

  const handleAllianceSelect = (selectedOption) => {
    setSelectedAlliance(selectedOption);
    setSelectedPlayer(null);
  };

  const handlePlayerSearchButtonClick = async () => {
    if (selectedPlayer) {
      const historyData = await getPlayerHistoryData(selectedPlayer.value);
      setPlayerHistory(historyData);
      setAllianceHistory([]);
    } else if (playerOptions.length > 0) {
      const historyData = await getPlayerHistoryData(playerOptions[0]?.value);
      setPlayerHistory(historyData);
      setAllianceHistory([]);
    }
  };

  const handleAllianceSearchButtonClick = async () => {
    if (selectedAlliance) {
      const historyData = await getAllianceHistoryData(selectedAlliance.value);
      setAllianceHistory(historyData);
      setPlayerHistory([]);
    } else if (allianceOptions.length > 0) {
      const historyData = await getAllianceHistoryData(
        allianceOptions[0]?.value
      );
      setAllianceHistory(historyData);
      setPlayerHistory([]);
    }
  };

  const todayUnix = moment.tz(timezone).startOf("day").unix();

  const sevenDaysAgoUnix = moment
    .tz(timezone)
    .subtract(7, "days")
    .startOf("day")
    .unix();

  const filterDataByDate = (data) => {
    const todayData = data.filter((doc) => doc.currentDate === todayUnix);
    const sevenDaysAgoData = data.filter(
      (doc) => doc.currentDate === sevenDaysAgoUnix
    );
    return { todayData, sevenDaysAgoData };
  };

  const dataSource =
    allianceHistory.length > 0 ? allianceHistory : playerHistory;
  const { todayData, sevenDaysAgoData } = filterDataByDate(dataSource);

  const debouncedPlayerSearch = debounce((inputValue, handler) => {
    handler(inputValue);
  }, 300);
  const debouncedAllianceSearch = debounce((inputValue, handler) => {
    handler(inputValue);
  }, 300);

  const getTribeName = (tribeId) => {
    switch (tribeId) {
      case 1:
        return "Teutons";
      case 2:
        return "Romans";
      case 3:
        return "Gauls";
      case 5:
        return "Natars";
      default:
        return "Unknown";
    }
  };

  //---------- UseEffect ------------//

  useEffect(() => {
    if (serverSettingsData && serverSettingsData.length > 0) {
      const data = serverSettingsData[0];
      setTimeZone(data?.timezone);
      setServerUrl(data?.serverUrl);
    }
  }, [serverSettingsData]);

  useEffect(() => {
    const todayUnix = moment.tz(timezone).startOf("day").unix();
    const sevenDaysAgoUnix = moment
      .tz(timezone)
      .subtract(7, "days")
      .startOf("day")
      .unix();

    const getPlayerRanks = async (playerName) => {
      const todayRank = await getPlayerRank(playerName, todayUnix);
      const sevenDaysAgoRank = await getPlayerRank(
        playerName,
        sevenDaysAgoUnix
      );
      setTodayRank(todayRank);
      setSevenDaysAgoRank(sevenDaysAgoRank);
    };

    getPlayerRanks(playerHistory[0]?.playerName);
  }, [playerHistory]);

  useEffect(() => {
    const todayUnix = moment.tz(timezone).startOf("day").unix();
    const sevenDaysAgoUnix = moment
      .tz(timezone)
      .subtract(7, "days")
      .startOf("day")
      .unix();

    const getAllianceRanks = async (allianceTag) => {
      const todayRank = await getAllianceRank(allianceTag, todayUnix);
      const sevenDaysAgoRank = await getPlayerRank(
        allianceTag,
        sevenDaysAgoUnix
      );
      setTodayRank(todayRank);
      setSevenDaysAgoRank(sevenDaysAgoRank);
    };

    getAllianceRanks(allianceHistory[0]?.allianceTag);
  }, [allianceHistory]);

  useEffect(() => {
    dispatch(getServerSettingsData());
  }, []);

  return (
    <>
      <div className="statistics-wrapper row">
        <div className="snapshot-container col-lg-6">
          <TotalStatsSnapshot />
        </div>
        <div className="statistics-container col-lg-6">
          <Label for="playerSearch" className="label">
            Search for Player
          </Label>
          <div className="search-section">
            <Select
              id="playerSearch"
              isClearable
              className="select"
              onInputChange={(inputValue) =>
                debouncedPlayerSearch(inputValue, handlePlayerSearch)
              }
              onChange={handlePlayerSelect}
              options={playerOptions}
            />
            <Button
              color="info"
              className="search-button text-white"
              disabled={condition === "Read Only"}
              onClick={handlePlayerSearchButtonClick}
            >
              Search
            </Button>
          </div>
          <Label for="allianceSearch" className="label">
            Search for Alliance
          </Label>
          <div className="search-section">
            <Select
              id="allianceSearch"
              isClearable
              className="select"
              onInputChange={(inputValue) =>
                debouncedAllianceSearch(inputValue, handleAllianceSearch)
              }
              onChange={handleAllianceSelect}
              options={allianceOptions}
            />
            <Button
              color="info"
              className="search-button text-white"
              disabled={condition === "Read Only"}
              onClick={handleAllianceSearchButtonClick}
            >
              Search
            </Button>
          </div>
        </div>
      </div>
      <div className="border-line"></div>
      {(allianceHistory && allianceHistory.length > 0) ||
      (playerHistory && playerHistory.length > 0) ? (
        <div className="mt-5 mb-5">
          <div className="row">
            <div className="col-md-6">
              <Card className="shadow-sm mb-4">
                <CardBody>
                  <CardTitle tag="h4" className="mb-4">
                    Values (Last 7 Days Change)
                  </CardTitle>
                  <CardText className="mb-3">
                    <strong>Name:</strong>{" "}
                    {playerHistory && playerHistory.length > 0
                      ? playerHistory[0]?.playerName
                      : (allianceHistory &&
                          allianceHistory.length > 0 &&
                          allianceHistory[0]?.allianceTag) ||
                        "N/A"}
                  </CardText>
                  {playerHistory && playerHistory.length > 0 && (
                    <CardText className="mb-3">
                      <strong>Tribe:</strong>{" "}
                      {getTribeName(playerHistory[0]?.tribe)}
                    </CardText>
                  )}
                  {playerHistory && playerHistory.length > 0 && (
                    <CardText className="mb-3">
                      <strong>Rank:</strong>{" "}
                      {todayRank !== null ? `${todayRank}` : "Loading..."} (
                      {todayRank - sevenDaysAgoRank > 0 ? "+" : ""}
                      {sevenDaysAgoRank !== null
                        ? todayRank - sevenDaysAgoRank
                        : 0}
                      )
                    </CardText>
                  )}
                  {allianceHistory && allianceHistory.length > 0 && (
                    <CardText className="mb-3">
                      <strong>Rank:</strong>{" "}
                      {todayRank !== null ? `${todayRank}` : "Loading..."} (
                      {todayRank - sevenDaysAgoRank > 0 ? "+" : ""}
                      {sevenDaysAgoRank !== null
                        ? todayRank - sevenDaysAgoRank
                        : 0}
                      )
                    </CardText>
                  )}
                  {playerHistory && playerHistory.length > 0 && (
                    <CardText className="mb-3">
                      <strong className="me-2">Alliance Profile:</strong>
                      <a
                        href={`${serverUrl}/alliance/${todayData[0]?.allianceID}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-decoration-none"
                      >
                        {todayData[0]?.allianceTag}
                      </a>
                    </CardText>
                  )}
                  <CardText className="mb-3">
                    <strong>Villages:</strong>{" "}
                    {todayData[0]?.villagesCount ?? 0} (
                    {(todayData[0]?.villagesCount ?? 0) -
                      (sevenDaysAgoData[0]?.villagesCount ?? 0) >
                    0
                      ? "+"
                      : ""}
                    {(todayData[0]?.villagesCount ?? 0) -
                      (sevenDaysAgoData[0]?.villagesCount ?? 0)}
                    )
                  </CardText>
                  <CardText className="mb-3">
                    <strong>Settled Villages:</strong>{" "}
                    {todayData[0]?.settledVillagesCount ?? 0} (
                    {(todayData[0]?.settledVillagesCount ?? 0) -
                      (sevenDaysAgoData[0]?.settledVillagesCount ?? 0) >
                    0
                      ? "+"
                      : ""}
                    {(todayData[0]?.settledVillagesCount ?? 0) -
                      (sevenDaysAgoData[0]?.settledVillagesCount ?? 0)}
                    )
                  </CardText>
                  <CardText className="mb-3">
                    <strong>Conquered Villages:</strong>{" "}
                    {todayData[0]?.conqueredVillagesCount ?? 0} (
                    {(todayData[0]?.conqueredVillagesCount ?? 0) -
                      (sevenDaysAgoData[0]?.conqueredVillagesCount ?? 0) >
                    0
                      ? "+"
                      : ""}
                    {(todayData[0]?.conqueredVillagesCount ?? 0) -
                      (sevenDaysAgoData[0]?.conqueredVillagesCount ?? 0)}
                    )
                  </CardText>

                  {allianceHistory && allianceHistory.length > 0 && (
                    <CardText className="mb-3">
                      <strong>Lost Villages:</strong>{" "}
                      {todayData[0]?.lostVillagesCount ?? 0} (
                      {(todayData[0]?.lostVillagesCount ?? 0) -
                        (sevenDaysAgoData[0]?.lostVillagesCount ?? 0) >
                      0
                        ? "+"
                        : ""}
                      {(todayData[0]?.lostVillagesCount ?? 0) -
                        (sevenDaysAgoData[0]?.lostVillagesCount ?? 0)}
                      )
                    </CardText>
                  )}
                  <CardText className="mb-3">
                    <strong>Population:</strong>{" "}
                    {(todayData[0]?.totalPopulation ?? 0).toLocaleString()} (
                    {(todayData[0]?.totalPopulation ?? 0) -
                      (sevenDaysAgoData[0]?.totalPopulation ?? 0) >
                    0
                      ? "+"
                      : ""}
                    {(
                      (todayData[0]?.totalPopulation ?? 0) -
                      (sevenDaysAgoData[0]?.totalPopulation ?? 0)
                    ).toLocaleString()}
                    )
                  </CardText>

                  <CardText className="mb-3">
                    <strong>Game Day Registered:</strong>{" "}
                    {playerHistory && playerHistory.length > 0
                      ? playerHistory[0]?.serverDay
                      : allianceHistory[0]?.serverDay}
                  </CardText>
                  {playerHistory && playerHistory.length > 0 && (
                    <CardText className="mb-3">
                      <strong>Playtime:</strong>{" "}
                      {playtime !== null ? playtime : "N/A"} days
                    </CardText>
                  )}
                  <CardText className="mb-3">
                    {playerHistory && playerHistory.length > 0 && (
                      <>
                        <strong className="me-2">Player Profile Link:</strong>
                        <a
                          href={`${serverUrl}/profile/${todayData[0]?.playerID}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-decoration-none"
                        >
                          Profile Link
                        </a>
                      </>
                    )}
                  </CardText>
                  <CardText className="mb-3">
                    {allianceHistory && allianceHistory.length > 0 && (
                      <>
                        <strong className="me-2">Alliance Profile:</strong>
                        <a
                          href={`${serverUrl}/profile/${todayData[0]?.allianceID}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-decoration-none"
                        >
                          Profile Link
                        </a>
                      </>
                    )}
                  </CardText>
                  {playerHistory && playerHistory.length > 0 && (
                    <div className="d-flex">
                      <Button
                        color="info"
                        className="me-2 text-white"
                        onClick={toggleVillageHistoryModal}
                      >
                        Village History
                      </Button>
                      <Button
                        color="info"
                        className="text-white"
                        onClick={toggleAllianceHistoryModal}
                      >
                        Alliance History
                      </Button>
                    </div>
                  )}
                  {allianceHistory && allianceHistory.length > 0 && (
                    <div className="d-flex">
                      <Button
                        color="info"
                        className="me-2 text-white"
                        onClick={showFullHistory}
                      >
                        Alliance History
                      </Button>
                      <Button
                        color="info"
                        className="text-white"
                        onClick={showDeletionSummary}
                      >
                        Alliance History Summary
                      </Button>
                    </div>
                  )}
                </CardBody>
              </Card>
            </div>

            {playerHistory && playerHistory.length > 0 ? (
              <div className="col-md-6">
                <PopulationGraph data={playerHistory} />
              </div>
            ) : (
              <div className="col-md-6">
                <PopulationGraph data={allianceHistory} />
              </div>
            )}
          </div>
        </div>
      ) : null}

      {(allianceHistory && allianceHistory.length > 0) ||
      (playerHistory && playerHistory.length > 0) ? (
        <StatisticsTable
          playerHistory={playerHistory}
          allianceHistory={allianceHistory}
          todayData={todayData}
          sevenDaysAgoData={sevenDaysAgoData}
        />
      ) : null}

      <VillageHistoryModal
        playerHistory={playerHistory}
        toggle={toggleVillageHistoryModal}
        isOpen={modal}
      />
      <AllianceHistoryModal
        playerHistory={playerHistory}
        toggle={toggleAllianceHistoryModal}
        isOpen={allianceModal}
      />
      <AllianceHistory
        allianceHistory={allianceHistory}
        toggle={toggle}
        isOpen={isOpen}
        showSummary={showSummary}
      />
    </>
  );
};

export default Statistics;
