import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import troop from "./../assets/Images/buttonIcons/troop.svg";
import back from "./../assets/Images/buttonIcons/back.svg";
import { useDispatch, useSelector } from "react-redux";
import { getPermissions } from "../store/actions/userpermissionsAction";

const KnowledgeBase = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { permission } = useSelector((state) => state.userPermissions);
  const [condition, setCondition] = useState();

  //-------------Roles/Permissions--------------//

  const permissionData = permission?.knowledgeBase;
  const role = user?.role;
  const functionalRole = user?.functionalRole;
  const functionalPermission =
    permission && permissionData && permissionData[functionalRole];

  useEffect(() => {
    if (permission && permissionData) {
      const rolePermission = permissionData[role];
      const functionalPermission = permissionData[functionalRole];
      if (
        functionalPermission !== undefined &&
        functionalPermission.trim() !== ""
      ) {
        setCondition(functionalPermission);
      } else if (rolePermission !== undefined && rolePermission.trim() !== "") {
        setCondition(rolePermission);
      }
    }
  }, [permission, permissionData, role, functionalRole]);

  useEffect(() => {
    dispatch(getPermissions());
  }, []);

  return (
    <div className="container-fluid">
      {condition == "Locked" ? (
        <div
          className="text-center d-flex justify-content-center align-items-center "
          style={{ height: "70vh" }}
        >
          <h2>
            This page is not available for{" "}
            {functionalPermission !== undefined &&
            functionalPermission.trim() !== ""
              ? functionalRole
              : role}
          </h2>
        </div>
      ) : (
        <div>
          <Link className="btn mt-2 ms-3 button-link move-back-button" to="/">
            <img src={back} alt="Move Back" width={30} />
          </Link>
          <div className="px-5 button-card">
            <Link
              className="btn btn-primary mt-2 ms-3 button-link"
              to="/troop-glossary"
            >
              <div className="button-icon">
                <img src={troop} className="img-fluid" alt={"Troop Glossary"} />
              </div>
              <p>Troop Glossary</p>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default KnowledgeBase;
