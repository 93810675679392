import React from "react";

const TroopInfo = () => {
  return (
    <div className="d-flex flex-column justify-content-center align-items-center">
      <h3 className="text-secondary">Village Overview</h3>
    </div>
  );
};

export default TroopInfo;
