import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const NewRecordModal = ({
  isOpen,
  toggle,
  newRecord,
  handleRecordChange,
  handleRecordSubmit,
  isSubmitDisabled,
}) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} centered scrollable>
      <ModalHeader toggle={toggle}>Add New Record</ModalHeader>
      <ModalBody>
        <div className="form-group">
          <label htmlFor="fromPlayer">Player:</label>
          <input
            type="text"
            className="form-control"
            id="fromPlayer"
            value={newRecord.fromPlayer}
            onChange={handleRecordChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="fromVillage">Village:</label>
          <input
            type="text"
            className="form-control"
            id="fromVillage"
            value={newRecord.fromVillage}
            onChange={handleRecordChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="resources">Resources:</label>
          <input
            type="number"
            className="form-control"
            id="resources"
            value={newRecord.resources}
            onChange={handleRecordChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="arrivalTime">Arrival Time:</label>
          <input
            type="text"
            className="form-control"
            id="arrivalTime"
            value={newRecord.arrivalTime}
            onChange={handleRecordChange}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          Close
        </Button>
        <Button
          color="primary"
          onClick={handleRecordSubmit}
          disabled={isSubmitDisabled}
        >
          Add New Record
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default NewRecordModal;
