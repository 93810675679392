import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const VillageHistoryModal = ({ playerHistory, toggle, isOpen }) => {
  if (!playerHistory) {
    return null;
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle} scrollable>
      <ModalHeader toggle={toggle}>Village History</ModalHeader>
      <ModalBody>
        {playerHistory && playerHistory.length > 0 ? (
          <ul className="list-group">
            {playerHistory.map((entry, index) => (
              <li
                key={index}
                className={`list-group-item d-flex justify-content-between align-items-center list-group-item-success`}
              >
                <div>
                  {entry.villages.map((village, villageIndex) => (
                    <>
                      <span className="fw-bold">
                        Day {village.gameAcquiredDay}:
                      </span>
                      <span key={villageIndex} className="ms-2">
                        Village ({village.x} | {village.y}){" "}
                        {!village.isSettled ? "settled " : "conquered"}
                      </span>
                      <br/>
                    </>
                  ))}
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <p>No village history found.</p>
        )}
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default VillageHistoryModal;
