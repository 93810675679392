import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { addNewTrackerTableRow } from "../../store/actions/defenderTrackerAction";
import { toast } from "react-toastify";

const AddEntryModal = ({ isOpen, toggle }) => {
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    allianceName: "",
    playerName: "",
    villageName: "",
    villageCoords: { x: 0, y: 0 },
    villageMapLink: "",
    timeDefended: "",
  });

  const handleChange = (e) => {
    const { id, value } = e.target;
    const numericValue = /^\d+$/.test(value) ? parseInt(value) : value;
    if (id === "xCoord") {
      setFormData({
        ...formData,
        villageCoords: {
          ...formData.villageCoords,
          x: numericValue,
        },
      });
    } else if (id === "yCoord") {
      setFormData({
        ...formData,
        villageCoords: {
          ...formData.villageCoords,
          y: numericValue,
        },
      });
    } else {
      setFormData({
        ...formData,
        [id]: numericValue,
      });
    }
  };

  const handleSubmit = async () => {
    try {
      await dispatch(addNewTrackerTableRow(formData));
      setFormData({
        allianceName: "",
        playerName: "",
        villageName: "",
        villageCoords: { x: 0, y: 0 },
        villageMapLink: "",
        timeDefended: "",
      });
      toggle();
    } catch (error) {
      toast.error("Error adding new row")
    }
  };
  return (
    <Modal isOpen={isOpen} toggle={toggle} centered scrollable>
      <ModalHeader toggle={toggle}>Add New Entry</ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label for="allianceName">Alliance Name:</Label>
          <Input
            type="text"
            id="allianceName"
            value={formData.allianceName}
            onChange={handleChange}
          />
        </FormGroup>
        <FormGroup>
          <Label for="playerName">Player Name:</Label>
          <Input
            type="text"
            id="playerName"
            value={formData.playerName}
            onChange={handleChange}
          />
        </FormGroup>
        <FormGroup>
          <Label for="villageName">Village Name:</Label>
          <Input
            type="text"
            id="villageName"
            value={formData.villageName}
            onChange={handleChange}
          />
        </FormGroup>
        <FormGroup>
          <Label for="villageCoords">Village Coords:</Label>
          <div className="d-flex">
            <Input
              type="number"
              id="xCoord"
              placeholder="X"
              value={formData.villageCoords.x}
              onChange={handleChange}
              className="me-2"
            />
            <Input
              type="number"
              id="yCoord"
              placeholder="Y"
              value={formData.villageCoords.y}
              onChange={handleChange}
            />
          </div>
        </FormGroup>
        <FormGroup>
          <Label for="villageMapLink">Village Map Link:</Label>
          <Input
            type="url"
            id="villageMapLink"
            value={formData.villageMapLink}
            onChange={handleChange}
          />
        </FormGroup>
        <FormGroup>
          <Label for="timeDefended">Number of Times Defended:</Label>
          <Input
            type="number"
            id="timeDefended"
            value={formData.timeDefended}
            onChange={handleChange}
          />
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          Close
        </Button>
        <Button color="primary" onClick={handleSubmit}>
          Add Row
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AddEntryModal;
