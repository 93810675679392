import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from "reactstrap";
import UpdateFarmlistParser from "./UpdateFarmlistParser";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteFarmlistRow,
  getFarmlistData,
  resetFarmlist,
} from "../../store/actions/farmSearchAction";
import AddVillageModal from "./AddVillageModal";

const MyFarmList = ({condition}) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { myFarmlist, loading } = useSelector((state) => state.farmSearch);
  const [player, setPlayer] = useState();
  const [modalReport, setModalReport] = useState(false);
  const [modalAddVillage, setModalAddVillage] = useState(false);
  const [modalReset, setModalReset] = useState(false);
  const [filteredFarmlist, setFilteredFarmlist] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [sortColumn, setSortColumn] = useState(null);
  const [selectedFilterValues, setSelectedFilterValues] = useState({});
  const [searchTerm, setSearchTerm] = useState("");

  const toggleModal = () => setModalReport(!modalReport);
  const toggleAddModal = () => setModalAddVillage(!modalAddVillage);
  const toggleResetModal = () => setModalReset(!modalReset);

  const handleResetFarmlist = () => {
    dispatch(resetFarmlist(player));
    toggleResetModal();
  };

  //Field Names
  const fieldNames = [
    { key: "village", label: "Raider Village" },
    { key: "raidedPlayer", label: "Player" },
    { key: "raidedAlliance", label: "Alliance" },
    { key: "raidedVillage", label: "Village" },
    { key: "x", label: "X|Y" },
  ];

  const handleSort = (column, option) => {
    let sortedData = [...filteredFarmlist];

    if (option === "asc") {
      sortedData.sort((a, b) => compareValues(a[column], b[column]));
    } else if (option === "desc") {
      sortedData.sort((a, b) => compareValues(b[column], a[column]));
    }

    setSortColumn(column);
    setFilterData([...sortedData]);
  };

  const compareValues = (valueA, valueB) => {
    if (valueA === valueB) {
      return 0;
    }
    return valueA < valueB ? -1 : 1;
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleApplyFilter = (columnName) => {
    const selectedValues = selectedFilterValues[columnName];

    if (!selectedValues || selectedValues.length === 0) {
      return;
    }
    const filteredRows = filterData.filter((player) => {
      return (
        player &&
        player[columnName] !== undefined &&
        selectedValues.includes(String(player[columnName]))
      );
    });

    setFilterData(filteredRows);
  };

  const uniqueValuesForColumn = (columnName) => {
    const uniqueValues = new Set();

    if (filterData && filterData.length > 0) {
      filterData.forEach((entry) => {
        if (entry[columnName] !== undefined) {
          uniqueValues.add(String(entry[columnName]));
        }
      });
    }

    return Array.from(uniqueValues);
  };

  const clearFilters = () => {
    setSortColumn(null);
    const filteredData =
      myFarmlist && myFarmlist.filter((entry) => entry.player === player);

    setFilteredFarmlist(filteredData);
    setFilterData(filteredData);
    setSelectedFilterValues({});
    setSearchTerm("");
  };

  useEffect(() => {
    if (user) {
      setPlayer(user?.gameAccountName);

      const filteredData =
        myFarmlist && myFarmlist.filter((entry) => entry.player === player);

      setFilteredFarmlist(filteredData);
      setFilterData(filteredData);
    }
  }, [user, myFarmlist]);

  useEffect(() => {
    dispatch(getFarmlistData());
  }, [dispatch]);

  return (
    <div className="mt-5">
      <div className="d-flex flex-wrap gap-2 mb-4">
        <Button color="info" className="text-white py-2" onClick={toggleModal}>
          Update Farmlist
        </Button>
        <Button color="info" className="text-white" onClick={toggleAddModal}>
          Add New Village
        </Button>
        <Button color="info" className="text-white" onClick={toggleResetModal}>
          Reset Farmlist
        </Button>
      </div>
      <div className="table-responsive">
        <Table bordered>
          <thead>
            <tr>
              <th className="text-center bg-info p-3">Action</th>
              {fieldNames.map((fieldName) => (
                <th key={fieldName.key} className="p-3 text-center bg-info">
                  {fieldName.label}
                  <i
                    style={{
                      cursor: "pointer",
                      color: sortColumn === fieldName.key ? "red" : "",
                      border:
                        sortColumn === fieldName.key ? "2px solid red" : "none",
                    }}
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    className={"bi bi-filter ms-1 bg-info"}
                  ></i>
                  <ul style={{ cursor: "pointer" }} className="dropdown-menu">
                    <li
                      role="button"
                      className="dropdown-item cursor-pointer"
                      onClick={() => handleSort(fieldName.key, "desc")}
                    >
                      Sort By Highest Values
                    </li>
                    <li
                      role="button"
                      className="dropdown-item cursor-pointer"
                      onClick={() => handleSort(fieldName.key, "asc")}
                    >
                      Sort By Lowest Values
                    </li>
                    <li
                      role="button"
                      className="dropdown-item cursor-pointer"
                      onClick={() => clearFilters()}
                    >
                      Clear filter
                    </li>
                    <li className="dropdown-item">
                      Search
                      <input
                        className="mx-2"
                        placeholder="Search"
                        style={{ width: "70%" }}
                        type="text"
                        value={searchTerm}
                        onChange={handleSearchChange}
                      />
                    </li>
                    <div>
                      <p>Selected Values:</p>
                      {selectedFilterValues[fieldName.key]?.map((value, i) => (
                        <span key={i}>{value},</span>
                      ))}
                    </div>
                    <select
                      style={{ width: "100%" }}
                      onChange={(e) => {
                        const selectedValues = Array.from(
                          e.target.selectedOptions,
                          (option) => option.value
                        );
                        setSelectedFilterValues((prevValues) => {
                          const currentValues = prevValues[fieldName.key] || [];
                          const newValues = currentValues.includes(
                            selectedValues[0]
                          )
                            ? currentValues.filter(
                                (value) => value !== selectedValues[0]
                              )
                            : [...currentValues, ...selectedValues];
                          return {
                            ...prevValues,
                            [fieldName.key]: newValues,
                          };
                        });
                      }}
                      multiple
                    >
                      <option
                        value="selectAll"
                        onClick={() => {
                          const allValues = uniqueValuesForColumn(
                            fieldName.key
                          ).filter((value) =>
                            value
                              .toLowerCase()
                              .includes(searchTerm.toLowerCase())
                          );
                          setSelectedFilterValues((prevValues) => ({
                            ...prevValues,
                            [fieldName.key]: allValues,
                          }));
                        }}
                      >
                        Select All
                      </option>
                      {uniqueValuesForColumn(fieldName.key)
                        ?.filter((value) =>
                          value
                            .toLowerCase()
                            .includes(searchTerm?.toLowerCase())
                        )
                        .map((value, i) => (
                          <option key={i} value={value}>
                            {`${value} ${
                              selectedFilterValues[fieldName.key]?.includes(
                                value
                              )
                                ? "✓"
                                : ""
                            }`}
                          </option>
                        ))}
                    </select>
                    <div className="d-flex justify-content-end gap-2 mt-2">
                      <button
                        className="btn btn-primary"
                        onClick={() => handleApplyFilter(fieldName.key)}
                      >
                        Apply Filter
                      </button>
                      <button
                        className="btn btn-danger"
                        onClick={() => setSelectedFilterValues("")}
                      >
                        Cancel
                      </button>
                    </div>
                  </ul>
                </th>
              ))}
            </tr>
          </thead>
          {loading ? (
            <tbody>
              <tr>
                <td className="text-center" colSpan="12">
                  <div className="d-flex justify-content-center align-items-center">
                    <div className="spinner-border" role="status"></div>
                    <span className="visually-hidden ms-2">Loading...</span>
                  </div>
                </td>
              </tr>
            </tbody>
          ) : (
            <tbody>
              {filterData.length > 0 ? (
                filterData.map((entry, index) => (
                  <tr key={`${entry.id}-${index}`}>
                    <td className="text-center">
                      <Button
                        color="danger"
                        className="px-3"
                        onClick={() => dispatch(deleteFarmlistRow(entry?.id))}
                      >
                        -
                      </Button>
                    </td>
                    <td className="text-center">{entry?.village}</td>
                    <td className="text-center">
                      {entry?.raidedPlayer || "N/A"}
                    </td>
                    <td className="text-center">
                      {entry?.raidedAlliance || "N/A"}
                    </td>
                    <td className="text-center">
                      {entry?.raidedVillage || "N/A"}
                    </td>
                    <td className="text-center">
                      {entry?.x} | {entry?.y}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="5" className="text-center">
                    No data available
                  </td>
                </tr>
              )}
            </tbody>
          )}
        </Table>
      </div>
      <UpdateFarmlistParser toggle={toggleModal} modal={modalReport} />
      <AddVillageModal
        isOpen={modalAddVillage}
        toggle={toggleAddModal}
        player={player}
      />
      <Modal isOpen={modalReset} toggle={toggleResetModal}>
        <ModalHeader toggle={toggleResetModal}>Confirm Reset</ModalHeader>
        <ModalBody>Are you sure you want to reset the farmlist?</ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={handleResetFarmlist}>
            Yes, Reset
          </Button>
          <Button color="secondary" onClick={toggleResetModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default MyFarmList;
