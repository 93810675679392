import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
} from "reactstrap";

const DiscordWebhookModal = ({
  isOpen,
  toggle,
  webhookURL,
  setWebhookURL,
  webhookMessage,
  setWebhookMessage,
  handleDiscordSave,
  isModified,
  setIsModified,
}) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} centered scrollable>
      <ModalHeader toggle={toggle}>Set up Discord Webhook</ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label for="webhookURL">Webhook URL:</Label>
          <Input
            type="text"
            id="webhookURL"
            className="form-control"
            name="webhookURL"
            value={webhookURL}
            onChange={(e) => {
              setWebhookURL(e.target.value);
              setIsModified(true);
            }}
            required
          />
        </FormGroup>
        <FormGroup className="mt-3">
          <Label for="webhookMessage">Message:</Label>
          <Input
            type="textarea"
            id="webhookMessage"
            className="form-control"
            name="webhookMessage"
            rows="4"
            value={webhookMessage}
            onChange={(e) => {
              setWebhookMessage(e.target.value);
              setIsModified(true);
            }}
            required
          />
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          Close
        </Button>
        <Button
          color="primary"
          onClick={handleDiscordSave}
          disabled={!isModified}
        >
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default DiscordWebhookModal;
