import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import {
  getServerSettingsData,
  resetAllGameData,
  saveServerSettings,
} from "../store/actions/serverSettingsAction";
import { getPermissions } from "../store/actions/userpermissionsAction";
import moment from "moment-timezone";

const ServerSettings = () => {
  //-------------- useSelectors --------------//
  const dispatch = useDispatch();
  const { serverSettingsData, loading } = useSelector(
    (state) => state.serverSettings
  );

  const { user } = useSelector((state) => state.auth);
  const { permission } = useSelector((state) => state.userPermissions);

  //------------ useStates---------------//
  const [showFirstModal, setShowFirstModal] = useState(false);
  const [showSecondModal, setShowSecondModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [formChanged, setFormChanged] = useState(false);
  const [condition, setCondition] = useState("");
  const [formData, setFormData] = useState({
    startDate: "",
    serverUrl: "",
    gameSpeed: "",
    scraperUsername: "",
    scraperPassword: "",
    scrapingInterval: "",
    scrapingRandomInterval: "",
    timezone: "",
    downloadTime: "",
    largestX: null,
    largestY: null,
  });

  //---------------- Roles/Permissions --------------//

  const permissionData = permission?.serverSetting;
  const role = user?.role;
  const functionalRole = user?.functionalRole;
  const functionalPermission =
    permission && permissionData && permissionData[functionalRole];

  //----------------- Functions ----------------//

  const handleSubmit = (event) => {
    event.preventDefault();
    if (serverSettingsData && serverSettingsData.length > 0) {
      const documentId = serverSettingsData[0].id;
      dispatch(saveServerSettings(formData, documentId));
      setFormChanged(false);
    } else {
      dispatch(saveServerSettings(formData));
      setFormChanged(false);
    }
  };

  const handleChange = (event) => {
    const { name, value, type } = event.target;
    const parsedValue = type === "number" ? Number(value) : value;
    setFormData({ ...formData, [name]: parsedValue });
    setFormChanged(true);
  };

  const handleTimezoneChange = (event) => {
    const selectedTimezone = event.target.value;
    setFormData({
      ...formData,
      timezone: selectedTimezone,
    });
    setFormChanged(true);
  };

  const handleResetData = () => {
    setShowFirstModal(true);
  };

  const confirmResetFirst = () => {
    setShowFirstModal(false);
    setShowSecondModal(true);
  };

  const confirmResetSecond = () => {
    dispatch(resetAllGameData());
    setShowSecondModal(false);
  };

  const firebaseTimestampToDate = (timestamp) => {
    const date = new Date(
      timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000
    );
    return date.toISOString().split("T")[0];
  };

  // -------------------useEffects -------------- //

  useEffect(() => {
    if (serverSettingsData && serverSettingsData.length > 0) {
      const data = serverSettingsData[0];
      setFormData({
        ...data,
        startDate: data.startDate
          ? firebaseTimestampToDate(data.startDate)
          : "",
      });
    }
  }, [serverSettingsData]);

  useEffect(() => {
    if (permission && permissionData) {
      const rolePermission = permissionData[role];
      const functionalPermission = permissionData[functionalRole];
      if (
        functionalPermission !== undefined &&
        functionalPermission.trim() !== ""
      ) {
        setCondition(functionalPermission);
      } else if (rolePermission !== undefined && rolePermission.trim() !== "") {
        setCondition(rolePermission);
      }
    }
  }, [permission, permissionData, role, functionalRole]);

  useEffect(() => {
    const fetchTimezones = async () => {
      const timezones = moment.tz.names().map((zone) => ({
        name: zone,
        offset: moment.tz(zone).format("Z"),
      }));
      setFormData({
        ...formData,
        timezone: timezones[0].name,
      });
    };
    fetchTimezones();
  }, []);

  useEffect(() => {
    dispatch(getServerSettingsData());
    dispatch(getPermissions());
  }, []);

  return (
    <div className="container-fluid px-5">
      <h2 className="mb-4 mt-4 text-center">Server Settings</h2>
      {loading ? (
        <div className="d-flex justify-content-center align-items-center mt-5">
          <div className="text-center mt-5">
            <div className="spinner-border d-inline-block" role="status"></div>
          </div>
        </div>
      ) : (
        <>
          {condition == "Locked" ? (
            <div
              className="text-center d-flex justify-content-center align-items-center "
              style={{ height: "70vh" }}
            >
              <h2>
                This page is not available for{" "}
                {functionalPermission !== undefined &&
                functionalPermission.trim() !== ""
                  ? functionalRole
                  : role}
              </h2>
            </div>
          ) : (
            <>
              <div className="d-flex gap-2 mb-2 justify-content-end">
                <Button color="info" className="text-white">
                  Scan Map
                </Button>
                <Button
                  color="danger"
                  onClick={() => setShowConfirmationModal(true)}
                >
                  Delete Map
                </Button>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="row mb-3">
                  <div className="col-lg-6">
                    <label className="form-label">
                      Game Server Start Date:
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      name="startDate"
                      value={formData.startDate}
                      onChange={handleChange}
                      disabled={condition === "Read Only"}
                    />
                  </div>
                  <div className="col-lg-6">
                    <label className="form-label">Server URL:</label>
                    <input
                      type="text"
                      className="form-control"
                      name="serverUrl"
                      value={formData.serverUrl}
                      onChange={handleChange}
                      disabled={condition === "Read Only"}
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-lg-6">
                    <label className="form-label">Game Speed:</label>
                    <select
                      className="form-select"
                      name="gameSpeed"
                      value={formData.gameSpeed}
                      disabled={condition === "Read Only"}
                      onChange={handleChange}
                    >
                      <option value="1x">1x</option>
                      <option value="2x">2x</option>
                      <option value="3x">3x</option>
                      <option value="5x">5x</option>
                    </select>
                  </div>
                  <div className="col-lg-6">
                    <label className="form-label">Timezone:</label>
                    <select
                      className="form-select"
                      name="timezone"
                      value={formData.timezone}
                      onChange={handleTimezoneChange}
                      disabled={condition === "Read Only"}
                    >
                      <option value="">Select Timezone</option>
                      {moment.tz.names().map((zone) => (
                        <option key={zone} value={`${zone}`}>
                          {zone} ({moment.tz(zone).format("Z")})
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-lg-6">
                    <label className="form-label">
                      Scraper Account Username:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="scraperUsername"
                      value={formData.scraperUsername}
                      onChange={handleChange}
                      disabled={condition === "Read Only"}
                    />
                  </div>
                  <div className="col-lg-6">
                    <label className="form-label">
                      Scraper Account Password:
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      name="scraperPassword"
                      value={formData.scraperPassword}
                      onChange={handleChange}
                      disabled={condition === "Read Only"}
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-lg-6">
                    <label className="form-label">
                      Scraping Interval (in minutes):
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      name="scrapingInterval"
                      value={formData.scrapingInterval}
                      onChange={handleChange}
                      disabled={condition === "Read Only"}
                    />
                  </div>
                  <div className="col-lg-6">
                    <label className="form-label">
                      Scraping Random Pattern Interval (in minutes):
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      name="scrapingRandomInterval"
                      value={formData.scrapingRandomInterval}
                      onChange={handleChange}
                      disabled={condition === "Read Only"}
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-lg-6">
                    <label className="form-label">
                      Game Information Update Time:
                    </label>
                    <input
                      type="time"
                      className="form-control"
                      name="downloadTime"
                      value={formData.downloadTime}
                      onChange={handleChange}
                      disabled={condition === "Read Only"}
                    />
                  </div>
                  <div className="col-lg-6">
                    <label className="form-label">Largest X coordinate</label>
                    <input
                      type="number"
                      className="form-control"
                      name="largestX"
                      value={formData.largestX}
                      onChange={handleChange}
                      disabled={condition === "Read Only"}
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-lg-6">
                    <label className="form-label">Largest Y coordinate</label>
                    <input
                      type="number"
                      className="form-control"
                      name="largestY"
                      value={formData.largestY}
                      onChange={handleChange}
                      disabled={condition === "Read Only"}
                    />
                  </div>
                </div>
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={
                    loading || !formChanged || condition === "Read Only"
                  }
                >
                  Save Settings
                </button>
              </form>
              <div className="row justify-content-center mt-3 mb-3">
                <div className="col-md-6">
                  <button
                    type="button"
                    className="btn btn-danger w-100 py-3"
                    onClick={handleResetData}
                    disabled={condition !== "Full Access"}
                  >
                    Reset Game Data
                  </button>
                </div>
              </div>
            </>
          )}
        </>
      )}

      {/* First Confirmation Modal */}
      <Modal
        isOpen={showFirstModal}
        toggle={() => setShowFirstModal(false)}
        centered
      >
        <ModalHeader toggle={() => setShowFirstModal(false)}>
          First Confirmation
        </ModalHeader>
        <ModalBody>
          Are you sure you want to reset game data? This action cannot be
          undone.
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setShowFirstModal(false)}>
            Cancel
          </Button>
          <Button color="warning" onClick={confirmResetFirst}>
            Yes, I'm sure
          </Button>
        </ModalFooter>
      </Modal>

      {/* Second Confirmation Modal */}
      <Modal
        isOpen={showSecondModal}
        toggle={() => setShowSecondModal(false)}
        centered
      >
        <ModalHeader toggle={() => setShowSecondModal(false)}>
          Second Confirmation
        </ModalHeader>
        <ModalBody>
          This will erase all the data of Server Settings, Hammer Tracker,
          Defender Tracker, Player Roster, Resource Push, Alliance Bonus, Push
          Reports, Player Troop, Player Statistics, Farm Search and
          WebHooks. Are you sure you want to continue?
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setShowSecondModal(false)}>
            Cancel
          </Button>
          <Button color="danger" onClick={confirmResetSecond}>
            Erase
          </Button>
        </ModalFooter>
      </Modal>

      {/* Map data Delete Confirmation Modal  */}
      <Modal
        isOpen={showConfirmationModal}
        toggle={() => setShowConfirmationModal(false)}
        centered
      >
        <ModalHeader toggle={() => setShowConfirmationModal(false)}>
          Confirmation
        </ModalHeader>
        <ModalBody>Are you sure you want to delete the Map data.</ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            onClick={() => setShowConfirmationModal(false)}
          >
            Cancel
          </Button>
          <Button color="danger">Delete</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ServerSettings;
