import { toast } from "react-toastify";
import firebase from "../../config/firebase";

export const addNewRow =
  (payload, onSuccess = () => {}) =>
  async (dispatch) => {
    try {
      const createdAt = firebase.firestore.Timestamp.now();
      firebase
        .firestore()
        .collection("defenderTrackerRows")
        .add({ ...payload, createdAt })
        .then(onSuccess());
    } catch (error) {
      console.error(error);
    }
  };

export const addNewTrackerTableRow =
  (payload, onSuccess = () => {}) =>
  async (dispatch) => {
    try {
      const createdAt = firebase.firestore.Timestamp.now();
      firebase
        .firestore()
        .collection("defenderTrackerTable")
        .add({ ...payload, createdAt })
        .then(() => {
          onSuccess();
          toast.success("New Tracker Table Row Added");
          dispatch(getTrackerTableData());
        });
    } catch (error) {
      toast.error("Failed to add new tracker table row");
    }
  };

export const getTrackerData = () => async (dispatch) => {
  try {
    dispatch(loader(true));
    const snapshot = await firebase
      .firestore()
      .collection("defenderTrackerRows")
      .get();
    const rows = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    rows.sort((a, b) => {
      return a.createdAt - b.createdAt;
    });
    dispatch({ type: "GET_TRACKER_DATA", payload: rows });
    dispatch(loader(false));
  } catch (error) {
    console.error("Error fetching rows:", error);
    dispatch(loader(false));
  }
};

export const getTrackerTableData = () => async (dispatch) => {
  try {
    dispatch(loader(true));
    const snapshot = await firebase
      .firestore()
      .collection("defenderTrackerTable")
      .get();
    const rows = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    rows.sort((a, b) => {
      return a.createdAt - b.createdAt;
    });
    dispatch({ type: "GET_TRACKER_TABLE_DATA", payload: rows });
    dispatch(loader(false));
  } catch (error) {
    console.error("Error fetching rows:", error);
    dispatch(loader(false));
  }
};

export const deleteTrackerRow =
  (id, onSuccess = () => {}) =>
  async (dispatch) => {
    try {
      await firebase
        .firestore()
        .collection("defenderTrackerRows")
        .doc(id)
        .delete()
        .then(() => {
          onSuccess();
          toast.success("Tracker row deleted successfully");
        });
    } catch (error) {
      toast.error("Failed to delete tracker row");
    }
  };

export const deleteTrackerTableRow =
  (id, onSuccess = () => {}) =>
  async (dispatch) => {
    try {
      await firebase
        .firestore()
        .collection("defenderTrackerTable")
        .doc(id)
        .delete()
        .then(() => {
          onSuccess();
          toast.success("Tracker Table row deleted successfully");
        });
    } catch (error) {
      toast.error("Failed to delete tracker table row");
    }
  };

export const saveTrackerData = (units) => {
  return async (dispatch) => {
    try {
      const firestore = firebase.firestore();
      const batch = firestore.batch();

      units.forEach((unit) => {
        const docRef = firestore.collection("defenderTrackerRows").doc(unit.id);
        const updatedUnit = { ...unit };
        batch.update(docRef, updatedUnit);
      });

      await batch.commit();
    } catch (error) {
      console.error("Error updating unit data: ", error);
      throw error;
    }
  };
};

export const saveTrackerTableData = (units) => {
  return async (dispatch) => {
    try {
      const firestore = firebase.firestore();
      const batch = firestore.batch();

      units.forEach((unit) => {
        const docRef = firestore
          .collection("defenderTrackerTable")
          .doc(unit.id);
        const updatedUnit = { ...unit };
        batch.update(docRef, updatedUnit);
      });

      await batch.commit();
    } catch (error) {
      console.error("Error updating unit data: ", error);
      throw error;
    }
  };
};

export const addNewDateColumn =
  (payload, onSuccess = () => {}) =>
  async (dispatch) => {
    try {
      firebase
        .firestore()
        .collection("defenderTrackerColumns")
        .add({ ...payload })
        .then(onSuccess());
    } catch (error) {
      console.error(error);
    }
  };

export const getDateColumns = () => async (dispatch) => {
  try {
    const snapshot = await firebase
      .firestore()
      .collection("defenderTrackerColumns")
      .get();
    const columns = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    dispatch({ type: "GET_TRACKER_COLUMNS", payload: columns });
  } catch (error) {
    console.error(error);
  }
};

export const deleteDateColumn = (id) => async (dispatch) => {
  try {
    await firebase
      .firestore()
      .collection("defenderTrackerColumns")
      .doc(id)
      .delete();
    dispatch(getDateColumns());
  } catch (error) {
    console.error(error);
  }
};

export const loader = (val) => async (dispatch) => {
  dispatch({
    type: "TRACKER_DATA_LOADING",
    payload: val,
  });
};
