import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto";

const LineChart = ({ labels, datasets, predictionStartIndex }) => {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);

  useEffect(() => {
    if (chartRef.current && labels.length > 0 && datasets.length > 0) {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }

      const ctx = chartRef.current.getContext("2d");
      chartInstance.current = new Chart(ctx, {
        type: "line",
        data: {
          labels: labels,
          datasets: datasets.map((entry) => ({
            ...entry,
          })),
        },
        options: {
          aspectRatio: 0,
          animation: false,
          scales: {
            x: {
              grid: {
                display: false,
              },
            },
          },
        },
      });

      const drawDottedLine = (startX, startY, endY) => {
        const dashLength = 5;
        const gapLength = 5;
        let currentY = startY;
        ctx.strokeStyle = "black";
        ctx.lineWidth = 0.3;
        ctx.beginPath();
        while (currentY <= endY) {
          ctx.moveTo(startX, currentY);
          currentY += dashLength;
          ctx.lineTo(startX, Math.min(currentY, endY));
          currentY += gapLength;
        }
        ctx.stroke();
      };

      const startX =
        chartInstance.current.scales.x.getPixelForValue(predictionStartIndex);

      const chartArea = chartInstance.current.chartArea;
      const startY = chartArea.top;
      const endY = chartArea.bottom;

      drawDottedLine(startX, startY, endY);
    }

    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, [labels, datasets, predictionStartIndex]);

  return <canvas ref={chartRef} height={500} />;
};

export default LineChart;
