const initialState = {
  resourcePush: [],
  reportsData: [],
  webHooks: [],
  latestMapInformation: [],
  loading: false,
};

const ResourcePushReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_RESOURCE_PUSH_DATA":
      return {
        ...state,
        resourcePush: action.payload,
      };
    case "GET_REPORTS_DATA":
      return {
        ...state,
        reportsData: action.payload,
      };
    case "GET_CURRENT_MAP_INFORMATION":
      return {
        ...state,
        latestMapInformation: action.payload,
      };
    case "SWAP_ROWS":
      return {
        ...state,
        resourcePush: action.payload,
      };
    case "WEBHOOKS_FETCH_SUCCESS":
      return {
        ...state,
        webHooks: action.payload,
      };
    case "IS_RESOURCE_LOADING":
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};

export default ResourcePushReducer;
