import { toast } from "react-toastify";
import firebase from "../../config/firebase";
import moment from "moment-timezone";

export const createNewPush =
  (payload, onSuccess = () => {}) =>
  async (dispatch, getState) => {
    try {
      const state = getState();
      const resourcePushList = state.resourcePush.resourcePush;

      let highestIndex = -1;

      if (resourcePushList.length > 0) {
        highestIndex = resourcePushList.reduce((max, item) => {
          return item.index > max ? item.index : max;
        }, -1);
      }

      const newIndex = highestIndex + 1;

      const docRef = await firebase
        .firestore()
        .collection("resourcePush")
        .add({
          ...payload,
          index: newIndex,
        });

      await docRef.update({ id: docRef.id });

      onSuccess(docRef.id);
      toast.success("New Push has been created");
      dispatch(getReportPushData());
    } catch (error) {
      console.error(error);
      toast.error("Error creating new Push");
    }
  };

export const getReportPushData = () => async (dispatch) => {
  try {
    dispatch(loader(true));

    const allResourcePushData = [];

    const resourcePushSnapshot = await firebase
      .firestore()
      .collection("resourcePush")
      .get();

    resourcePushSnapshot.forEach((doc) => {
      allResourcePushData.push({
        id: doc.id,
        ...doc.data(),
      });
    });

    dispatch({ type: "GET_RESOURCE_PUSH_DATA", payload: allResourcePushData });
    dispatch(loader(false));
  } catch (error) {
    console.error("Error fetching ResourcePush data:", error);
    dispatch(loader(false));
  }
};

export const getReportsData = () => async (dispatch) => {
  try {
    dispatch(loader(true));

    const allReports = [];

    const resourcePushSnapshot = await firebase
      .firestore()
      .collection("resourcePush")
      .get();

    for (const doc of resourcePushSnapshot.docs) {
      const docRef = doc.ref;

      const reportsSnapshot = await docRef.collection("Reports").get();

      const reportsData = reportsSnapshot.docs.map((reportDoc) => ({
        id: reportDoc.id,
        ...reportDoc.data(),
      }));

      allReports.push(...reportsData);
    }

    dispatch({ type: "GET_REPORTS_DATA", payload: allReports });
    dispatch(loader(false));
  } catch (error) {
    console.error("Error fetching Reports data:", error);
    dispatch(loader(false));
  }
};

export const saveData = (units) => {
  return async (dispatch) => {
    try {
      const firestore = firebase.firestore();
      const batch = firestore.batch();

      units.forEach((unit) => {
        const docRef = firestore.collection("resourcePush").doc(unit.id);
        const timestamp = new Date();
        const updatedUnit = { ...unit, timestamp };
        batch.update(docRef, updatedUnit);
      });

      await batch.commit();
    } catch (error) {
      toast.error("Error updating unit data");
    }
  };
};

export const saveSpeedData = (units) => {
  return async () => {
    try {
      const firestore = firebase.firestore();
      const batch = firestore.batch();

      for (const unit of units) {
        const docRef = firestore.collection("resourcePush").doc(unit.id);
        const docSnapshot = await docRef.get();

        const updatedUnit = { ...unit };

        if (docSnapshot.exists) {
          batch.update(docRef, updatedUnit);
        } else {
          batch.set(docRef, updatedUnit);
        }
      }

      await batch.commit();
    } catch (error) {
      console.error("Error updating unit data: ", error);
      throw error;
    }
  };
};

export const saveRecordData = (unit, id) => {
  console.log(unit);
  return async (dispatch) => {
    try {
      const firestore = firebase.firestore();
      const batch = firestore.batch();

      const reportsCollectionRef = firestore
        .collection("resourcePush")
        .doc(id)
        .collection("Reports");

      const reportDocRef = reportsCollectionRef.doc(unit.id);
      batch.set(reportDocRef, unit, { merge: true });

      await batch.commit();
    } catch (error) {
      console.error("Error updating Reports sub-collection data: ", error);
      throw error;
    }
  };
};

export const createPushReportData = (payload) => async (dispatch) => {
  try {
    const { playerName, villageName } = payload;

    const snapshot = await firebase
      .firestore()
      .collection("resourcePush")
      .where("playerName", "==", playerName)
      .where("villageName", "==", villageName)
      .where("isComplete", "==", false)
      .get();

    if (!snapshot.empty) {
      const docRef = snapshot.docs[0].ref;

      const subcollectionSnapshot = await docRef
        .collection("Reports")
        .where("fromPlayer", "==", payload.fromPlayer)
        .where("fromVillage", "==", payload.fromVillage)
        .get();

      if (!subcollectionSnapshot.empty) {
        const subcollectionDocRef = subcollectionSnapshot.docs[0].ref;

        const existingResources =
          (await subcollectionDocRef.get()).data().resources || {};

        const updatedResources = existingResources + payload.resources;

        await subcollectionDocRef.update({
          resources: updatedResources,
          arrivalTime: payload.arrivalTime,
        });
      } else {
        const subcollectionRef = docRef.collection("Reports").doc();
        await subcollectionRef.set({
          id: subcollectionRef.id,
          player: playerName,
          village: villageName,
          resources: payload.resources,
          arrivalTime: payload.arrivalTime,
          fromPlayer: payload.fromPlayer,
          fromVillage: payload.fromVillage,
        });
      }

      toast.success("Data added successfully");
    } else {
      toast.error(`Player ${playerName} not found.`);
    }

    const updatedSnapshot = await firebase
      .firestore()
      .collection("resourcePush")
      .get();
    const rows = updatedSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    dispatch({ type: "CREATE_PUSH_REPORT_DATA", payload: rows });
    dispatch(getReportPushData());
  } catch (error) {
    console.error("Error updating document:", error);
    toast.error("Error adding data");
  }
};

export const swapRow = (oldIndex, newIndex) => async (dispatch, getState) => {
  try {
    const firestore = firebase.firestore();
    const batch = firestore.batch();

    const { resourcePush } = getState().resourcePush;

    const oldRow =
      resourcePush && resourcePush?.find((row) => row.index === oldIndex);
    const newRow =
      resourcePush && resourcePush?.find((row) => row.index === newIndex);

    if (oldRow && newRow) {
      batch.update(firestore.collection("resourcePush").doc(oldRow?.id), {
        index: newIndex,
      });

      batch.update(firestore.collection("resourcePush").doc(newRow?.id), {
        index: oldIndex,
      });

      await batch.commit();

      const newResourcePush = [...resourcePush];
      [newResourcePush[oldIndex], newResourcePush[newIndex]] = [
        newResourcePush[newIndex],
        newResourcePush[oldIndex],
      ];

      dispatch({ type: "SWAP_ROWS", payload: newResourcePush });
      dispatch(getReportPushData());
      dispatch(getReportsData());
    } else {
      console.error("One or both rows not found.");
    }
  } catch (error) {
    console.error("Error swapping rows:", error);
    toast.error("Error swapping rows");
    throw error;
  }
};

export const completePush =
  (id, onSuccess = () => {}) =>
  async (dispatch) => {
    try {
      const docRef = firebase.firestore().collection("resourcePush").doc(id);

      const doc = await docRef.get();

      if (doc.exists) {
        await docRef.update({ isComplete: true });

        dispatch({
          type: "UPDATE_PUSH_DATA",
          payload: { id: doc.id, isComplete: true },
        });

        dispatch(getReportPushData());

        onSuccess();
      } else {
        console.log("Document does not exist");
      }
    } catch (error) {
      console.error("Error updating document:", error);
    }
  };

export const createNewRecord =
  (resource, payload, onSuccess = () => {}) =>
  async (dispatch) => {
    try {
      const docRef = firebase
        .firestore()
        .collection("resourcePush")
        .doc(resource?.id);
      const reportsCollectionRef = docRef.collection("Reports");

      const matchingReportQuery = await reportsCollectionRef
        .where("fromPlayer", "==", payload.fromPlayer)
        .where("fromVillage", "==", payload.fromVillage)
        .get();

      if (!matchingReportQuery.empty) {
        const matchingReportDoc = matchingReportQuery.docs[0];
        const matchingReportData = matchingReportDoc.data();
        const updatedResources =
          matchingReportData.resources + payload.resources;

        await matchingReportDoc.ref.update({
          resources: updatedResources,
          arrivalTime: payload.arrivalTime,
        });

        onSuccess(matchingReportDoc.id);
      } else {
        const reportDocRef = await reportsCollectionRef.add({
          ...payload,
          player: resource?.playerName,
          village: resource?.villageName,
        });
        const reportId = reportDocRef.id;
        await reportDocRef.update({ id: reportId });

        onSuccess(reportId);
      }
      toast.success("New record added successfully");
      dispatch(getReportPushData());
      dispatch(getReportsData());
    } catch (error) {
      console.error(error);
      toast.error("Error adding new record");
    }
  };

export const addWebhook = (payload) => async (dispatch) => {
  try {
    const docRef = firebase
      .firestore()
      .collection("webHooks")
      .doc("resourcePush");
    await docRef.set(payload, { merge: true });
    dispatch({ type: "WEBHOOK_ADDED_SUCCESSFULLY" });
    toast.success("Webhook updated Successfully");
  } catch (error) {
    console.error(error);
    dispatch({ type: "WEBHOOK_ADD_ERROR", error });
    toast.error("Failed updating Webhook");
  }
};

export const getWebhooks = () => async (dispatch) => {
  try {
    const snapshot = await firebase.firestore().collection("webHooks").get();
    const webhooks = snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    dispatch({ type: "WEBHOOKS_FETCH_SUCCESS", payload: webhooks });
  } catch (error) {
    console.error(error);
    dispatch({ type: "WEBHOOKS_FETCH_ERROR", error });
  }
};

export const deletePushRecord =
  (resourcePushId, reportId) => async (dispatch) => {
    try {
      await firebase
        .firestore()
        .collection("resourcePush")
        .doc(resourcePushId)
        .collection("Reports")
        .doc(reportId)
        .delete();

      toast.success("Record deleted successfully");

      dispatch(getReportPushData());
    } catch (error) {
      console.error("Error deleting report document:", error);
      toast.error("Error deleting report document");
    }
  };

export const getCurrentMapData = () => async (dispatch) => {
  try {
    dispatch(loader(true));
    const serverSettingsSnapshot = await firebase
      .firestore()
      .collection("serverSettings")
      .get();

    if (serverSettingsSnapshot.empty) {
      throw new Error("No server settings found");
    }

    const serverSettings = serverSettingsSnapshot?.docs[0]?.data();

    const timezone = serverSettings.timezone;
    const serverMoment = moment.tz(timezone);
    const currentDate = serverMoment.format("YYYY-MM-DD");

    const latestMapInformation = [];

    const resourcePushSnapshot = await firebase
      .firestore()
      .collection("latestMapInformation")
      .get();

    resourcePushSnapshot.forEach((doc) => {
      const data = doc.data();
      const unixTimestamp = data.currentDate;
      const formattedDate = moment.unix(unixTimestamp).format("YYYY-MM-DD");

      if (formattedDate === currentDate) {
        latestMapInformation.push({
          ...data,
        });
      }
    });

    dispatch({
      type: "GET_CURRENT_MAP_INFORMATION",
      payload: latestMapInformation,
    });
    dispatch(loader(false));
  } catch (error) {
    console.error("Error fetching latest Map Information:", error);
    dispatch(loader(false));
  }
};

export const loader = (val) => async (dispatch) => {
  dispatch({
    type: "IS_RESOURCE_LOADING",
    payload: val,
  });
};
