import React from 'react'

export const Error = () => {
  return (
    <div>Error 404</div>
  )
}


export default Error;

