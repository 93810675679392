import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteFilteredData,
  getColumns,
  getFilteredData,
  saveFilteredData,
} from "../store/actions/playroasterAction";
import { getPermissions } from "../store/actions/userpermissionsAction";

function PlayerRoster() {
  //----------------- useSelectors----------------//
  const dispatch = useDispatch();
  const { column, rowData, columnLoading, filteredData } = useSelector(
    (state) => state.column
  );
  const { user } = useSelector((state) => state.auth);
  const { permission } = useSelector((state) => state.userPermissions);

  //------------ useStates -------------//

  const [tableRows, setTableRows] = useState([]);
  const [activeCell, setActiveCell] = useState(null);
  const [filters, setFilters] = useState([
    { field: "", operator: "", value: "" },
  ]);
  const [selectedFields, setSelectedFields] = useState([]);
  const [isSorting, setIsSorting] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [condition, setCondition] = useState("");
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [isAddFilterDisabled, setIsAddFilterDisabled] = useState(true);
  const [isApplyFilterDisabled, setIsApplyFilterDisabled] = useState(true);
  const [filterData, setFilterData] = useState([]);

  //--------------Roles/Permissions ------------//

  const permissionData = permission?.playerRoster;
  const role = user?.role;
  const functionalRole = user?.functionalRole;
  const functionalPermission =
    permission && permissionData && permissionData[functionalRole];

  //----------- Filter Functions -------------//

  const applyFilters = () => {
    let filteredRows = [...rowData];
    filters.forEach((filter) => {
      const { field, operator, value } = filter;
      filteredRows = filteredRows.filter((row) => {
        const rowValue = String(row[field]).toLowerCase();
        const filterValue = String(value).toLowerCase();

        switch (operator) {
          case "<":
            return Number(rowValue) < Number(filterValue);
          case "<=":
            return Number(rowValue) <= Number(filterValue);
          case "=":
            return rowValue === filterValue;
          case ">=":
            return Number(rowValue) >= Number(filterValue);
          case ">":
            return Number(rowValue) > Number(filterValue);
          case "null":
            return rowValue === "" || rowValue === null;
          default:
            return true;
        }
      });
    });

    if (selectedFields.length > 0) {
      filteredRows = filteredRows.map((row) => {
        const filterRow = {};
        selectedFields.forEach((field) => {
          filterRow[field] = row[field] || "";
        });
        return filterRow;
      });
    }

    setTableRows(filteredRows);
    setIsFilterApplied(true);
  };

  const handleAddFilter = () => {
    setFilters([...filters, { field: "", operator: "", value: "" }]);
  };
  const handleRemoveFilter = (index) => {
    const updatedFilters = [...filters];
    updatedFilters.splice(index, 1);
    setFilters(updatedFilters);
  };

  const handleFieldToggle = (fieldName) => {
    if (selectedFields.includes(fieldName)) {
      setSelectedFields(selectedFields.filter((field) => field !== fieldName));
    } else {
      setSelectedFields([...selectedFields, fieldName]);
    }
  };

  const clearFilters = () => {
    setFilters([{ field: "", operator: "", value: "" }]);
    setTableRows([]);
    setIsSorting(false);
    setIsFilterApplied(false);
    setSelectedFields([]);
  };

  const handleFilterChange = (index, field, value) => {
    const updatedFilters = [...filters];
    updatedFilters[index][field] = value;
    setFilters(updatedFilters);
  };

  //-------------- Sorting Functions --------------//

  const handleSort = (column, order) => {
    const sortedRows = [...tableRows];
    sortedRows.sort((a, b) => {
      const valueA = a[column.fieldName];
      const valueB = b[column.fieldName];

      if (column.type === "text") {
        return order === "asc"
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA);
      } else if (column.type === "number") {
        return order === "asc"
          ? Number(valueA) - Number(valueB)
          : Number(valueB) - Number(valueA);
      }
      return 0;
    });
    setTableRows(sortedRows);
    setSelectedFilter(column.fieldName);
    setIsSorting(true);
  };

  // ----------- Save filtered data -----------//

  const handleSaveFilteredData = () => {
    dispatch(
      saveFilteredData(tableRows, () => {
        dispatch(getFilteredData());
        setTableRows([]);
        setFilterData(filteredData);
        setIsFilterApplied(false);
        setFilters([{ field: "", operator: "", value: "" }]);
        setSelectedFields([]);
      })
    );
  };

  //----------- Delete filtered data -------------//

  const handleDeleteSavedView = (id) => {
    dispatch(
      deleteFilteredData(id, () => {
        dispatch(getFilteredData());
        setTableRows([]);
        setFilterData([]);
        setIsFilterApplied(false);
        setFilters([{ field: "", operator: "", value: "" }]);
        setSelectedFields([]);
      })
    );
  };

  //------------- useEffect --------------//

  useEffect(() => {
    const allFieldsFilled = filters.every(
      (field) => field.field && field.operator && field.value
    );
    const selectedFieldsNotEmpty = selectedFields.length > 0;
    setIsApplyFilterDisabled(!allFieldsFilled || !selectedFieldsNotEmpty);
  }, [filters, selectedFields]);

  useEffect(() => {
    const allFieldsFilled = filters.every(
      (field) => field.field && field.operator && field.value
    );
    setIsAddFilterDisabled(!allFieldsFilled);
  }, [filters]);

  useEffect(() => {
    if (filteredData && filteredData.length > 0) {
      setFilterData(filteredData[0].data);
    }
  }, [dispatch, filteredData]);

  useEffect(() => {
    if (permission && permissionData) {
      const rolePermission = permissionData[role];
      const functionalPermission = permissionData[functionalRole];
      if (
        functionalPermission !== undefined &&
        functionalPermission.trim() !== ""
      ) {
        setCondition(functionalPermission);
      } else if (rolePermission !== undefined && rolePermission.trim() !== "") {
        setCondition(rolePermission);
      }
    }
  }, [permission, permissionData, role, functionalRole]);

  useEffect(() => {
    dispatch(getColumns());
    dispatch(getPermissions());
    dispatch(getFilteredData());
  }, []);

  return (
    <div>
      <h2 className="text-center mt-3 mb-3">Player Roster</h2>
      {condition == "Locked" ? (
        <div
          className="text-center d-flex justify-content-center align-items-center "
          style={{ height: "70vh" }}
        >
          <h2>
            This page is not available for{" "}
            {functionalPermission !== undefined &&
            functionalPermission.trim() !== ""
              ? functionalRole
              : role}
          </h2>
        </div>
      ) : (
        <div>
          <div className="container-fluid px-5 mt-2">
            <div className="d-flex flex-wrap gap-3 mb-3">
              <button
                className="btn btn-primary me-2"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                disabled={
                  condition === "Read Only" ||
                  filterData.length > 0 ||
                  columnLoading
                }
              >
                Filter
              </button>
              <button
                className="btn btn-secondary me-2"
                onClick={clearFilters}
                disabled={
                  condition === "Read Only" ||
                  filterData.length > 0 ||
                  !isFilterApplied
                }
              >
                Reset Query
              </button>
              <button
                className="btn btn-success me-2"
                onClick={handleSaveFilteredData}
                disabled={
                  condition === "Read Only" ||
                  filterData.length > 0 ||
                  isApplyFilterDisabled ||
                  !isFilterApplied ||
                  columnLoading ||
                  tableRows.length === 0
                }
              >
                Save View
              </button>
              <button
                className="btn btn-danger"
                onClick={() => handleDeleteSavedView(filteredData[0]?.id)}
                disabled={
                  condition === "Read Only" ||
                  filterData.length === 0 ||
                  columnLoading
                }
              >
                Delete View
              </button>
            </div>
            {isFilterApplied ? (
              columnLoading ? (
                <div className="text-center">
                  <div style={{ textAlign: "center" }}>
                    <div
                      className="spinner-border"
                      role="status"
                      style={{
                        display: "inline-block",
                        marginTop: "25px",
                      }}
                    ></div>
                  </div>
                </div>
              ) : (
                <div className="table-responsive">
                  <table className="table  table-excel mt-4">
                    <thead>
                      <tr>
                        <th>No.</th>
                        {Array.isArray(column) &&
                          column.map(
                            (col, index) =>
                              selectedFields.includes(col.fieldName) && (
                                <th
                                  key={index}
                                  style={{
                                    whiteSpace: "nowrap",
                                    margin: 0,
                                    padding: 0,
                                  }}
                                >
                                  <div className="p-2">
                                    <span
                                      style={{
                                        userSelect: "none",
                                        padding: "20px",
                                      }}
                                      className="text-capitalize"
                                    >
                                      {col.name}
                                    </span>
                                    <i
                                      style={{
                                        cursor: "pointer",
                                        color:
                                          isSorting &&
                                          selectedFilter === col.fieldName
                                            ? "red"
                                            : "black",
                                        border:
                                          isSorting &&
                                          selectedFilter === col.fieldName
                                            ? "1px solid red"
                                            : "none",
                                      }}
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                      disabled={condition === "Read Only"}
                                      className={"bi bi-filter ms-3"}
                                    ></i>
                                    <ul
                                      style={{ cursor: "pointer" }}
                                      className="dropdown-menu"
                                    >
                                      <li
                                        style={{ cursor: "pointer" }}
                                        className="dropdown-item nn"
                                        onClick={() => handleSort(col, "desc")}
                                      >
                                        Sort By Highest Values
                                      </li>
                                      <li
                                        style={{ cursor: "pointer" }}
                                        className="dropdown-item nn"
                                        onClick={() => handleSort(col, "asc")}
                                      >
                                        Sort By Lowest Values
                                      </li>
                                      <li
                                        style={{ cursor: "pointer" }}
                                        className="dropdown-item nn"
                                        onClick={() => clearFilters()}
                                      >
                                        Clear filter
                                      </li>
                                    </ul>
                                  </div>
                                </th>
                              )
                          )}
                      </tr>
                    </thead>
                    <tbody>
                      {columnLoading ? (
                        <tr>
                          <td colSpan="5" style={{ textAlign: "center" }}>
                            <div
                              className="spinner-border"
                              role="status"
                              style={{
                                display: "inline-block",
                                marginTop: "25px",
                              }}
                            ></div>
                          </td>
                        </tr>
                      ) : (
                        <>
                          {isFilterApplied
                            ? Array.isArray(tableRows) &&
                              tableRows.map((row, rowIndex) => (
                                <tr key={rowIndex}>
                                  <td className="nn1">{rowIndex + 1}</td>
                                  {Array.isArray(column) &&
                                    column.map(
                                      (col, colIndex) =>
                                        selectedFields.includes(
                                          col.fieldName
                                        ) && (
                                          <td key={colIndex}>
                                            {activeCell ===
                                              `${rowIndex},${colIndex}` &&
                                            col.type !== "boolean" ? (
                                              <input
                                                type={col.type || "text"}
                                              />
                                            ) : activeCell ===
                                                `${rowIndex},${colIndex}` &&
                                              col.type === "boolean" ? (
                                              <select
                                                className="form-select text-capitalize"
                                                value={row[col.fieldName]}
                                                aria-label="Default select example"
                                              >
                                                <option value="yes">Yes</option>
                                                <option value="no">No</option>
                                              </select>
                                            ) : (
                                              <span
                                                style={{
                                                  textTransform: "capitalize",
                                                }}
                                              >
                                                {row[col.fieldName]}
                                              </span>
                                            )}
                                          </td>
                                        )
                                    )}
                                </tr>
                              ))
                            : ""}
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
              )
            ) : columnLoading ? (
              <div className="text-center">
                <div style={{ textAlign: "center" }}>
                  <div
                    className="spinner-border"
                    role="status"
                    style={{
                      display: "inline-block",
                      marginTop: "25px",
                    }}
                  ></div>
                </div>
              </div>
            ) : (
              filterData.length > 0 && (
                <div className="table-responsive">
                  <table className="table  table-excel mt-4">
                    <thead>
                      <tr>
                        <th>No.</th>
                        {column
                          .filter((col) =>
                            filterData[0].hasOwnProperty(col.fieldName)
                          )
                          .sort((a, b) => a.index - b.index)
                          .map((col) => (
                            <th key={col.id}>{col.name}</th>
                          ))}
                      </tr>
                    </thead>
                    <tbody>
                      <>
                        {filterData.length > 0 && (
                          <>
                            {filterData.map((item, rowIndex) => (
                              <tr key={rowIndex}>
                                <td>{rowIndex + 1}</td>
                                {column
                                  .filter((col) =>
                                    item.hasOwnProperty(col.fieldName)
                                  )
                                  .sort((a, b) => a.index - b.index)
                                  .map((col) => (
                                    <td key={col.id}>{item[col.fieldName]}</td>
                                  ))}
                              </tr>
                            ))}
                          </>
                        )}
                      </>
                    </tbody>
                  </table>
                </div>
              )
            )}
          </div>

          {/* Filter Modal */}

          <div
            class="modal fade"
            id="exampleModal"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">
                    Filter
                  </h5>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div class="modal-body">
                  {filters.map((filter, index) => (
                    <div key={index} class="filter-row mb-3">
                      <h6>#{index + 1}</h6>
                      <select
                        class="form-select"
                        className="mb-2"
                        value={filter.field}
                        onChange={(e) =>
                          handleFilterChange(index, "field", e.target.value)
                        }
                      >
                        <option value="">Select Field</option>
                        {Array.isArray(column) &&
                          column.map((col, idx) => (
                            <option key={idx} value={col.fieldName}>
                              {col.fieldName}
                            </option>
                          ))}
                      </select>
                      <select
                        class="form-select"
                        className="mb-2"
                        value={filter.operator}
                        onChange={(e) =>
                          handleFilterChange(index, "operator", e.target.value)
                        }
                      >
                        <option value="">Select Operator</option>
                        <option value="<">Less than</option>
                        <option value="<=">Less than or equal to</option>
                        <option value="=">Equal to</option>
                        <option value=">=">Greater than or equal to</option>
                        <option value=">">Greater than</option>
                        <option value="null">Blank (or null)</option>
                      </select>
                      <input
                        type="text"
                        class="form-control"
                        value={filter.value}
                        onChange={(e) =>
                          handleFilterChange(index, "value", e.target.value)
                        }
                        placeholder="Enter Value"
                      />
                      {index > 0 && (
                        <button
                          onClick={() => handleRemoveFilter(index)}
                          class="btn btn-sm btn-danger mt-2"
                        >
                          Remove
                        </button>
                      )}
                    </div>
                  ))}
                  <button
                    onClick={handleAddFilter}
                    class="btn btn-primary"
                    disabled={isAddFilterDisabled}
                  >
                    Add Filter
                  </button>
                </div>
                <div class="modal-footer">
                  <div>
                    <h6>Select fields</h6>
                    <div class="form-check">
                      {Array.isArray(column) &&
                        column.map((col, index) => (
                          <div key={index} class="form-check form-check-inline">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              id={"checkbox_" + index}
                              checked={selectedFields.includes(col.fieldName)}
                              onChange={() => handleFieldToggle(col.fieldName)}
                            />
                            <label
                              class="form-check-label"
                              for={"checkbox_" + index}
                            >
                              {col.fieldName}
                            </label>
                          </div>
                        ))}
                    </div>
                  </div>
                  <button
                    onClick={applyFilters}
                    class="btn btn-primary"
                    disabled={
                      isApplyFilterDisabled || condition === "Read Only"
                    }
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                  >
                    Apply Filters
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default PlayerRoster;
