import React, { useEffect, useState } from "react";
import "../../assets/css/Register.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  getAllDiscordNames,
  registerUser,
} from "../../store/actions/authActions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Register = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { discordNames, isLoading } = useSelector((state) => state.auth);

  //--------------- useStates ---------------//

  const [email, setEmail] = useState("");
  const [discordname, setDiscordName] = useState("");
  const [gameAccountName, setGameAccountName] = useState("");
  const [password, setPassword] = useState("");
  const [allDiscordNames, setAllDiscordnames] = useState([]);
  const [discordnameAvailability, setDiscordnameAvailability] = useState("");
  const [discordnameAvailabilityColor, setDiscordnameAvailabilityColor] =
    useState("");

  //---------------- Functions --------------//

  const handleDiscordnameCheck = (value) => {
    if (value.trim() !== "") {
      const isUsernameAvailable = !allDiscordNames?.includes(
        value?.toLowerCase()
      );
      setDiscordnameAvailability(
        isUsernameAvailable
          ? "Discord name is available"
          : "Discord name is already taken"
      );
      setDiscordnameAvailabilityColor(isUsernameAvailable ? "green" : "red");
    } else {
      setDiscordnameAvailability("");
      setDiscordnameAvailabilityColor("");
    }
  };

  const handleDiscordnameChange = (value) => {
    setDiscordName(value);
  };

  const handleRegistration = async () => {
    const isDiscordNameAvailable = !allDiscordNames?.includes(discordname);
    if (!isDiscordNameAvailable) {
      toast.error("Discord Name is already taken.");
      return;
    }
    const role = "pending";
    let payload = { email, discordname, role, gameAccountName };
    dispatch(
      registerUser(email, password, payload, () => {
        setEmail("");
        setDiscordName("");
        setPassword("");
        navigate("/login", { replace: true });
      })
    );
  };

  //----------------- useEffects ----------------//

  useEffect(() => {
    if (discordNames?.length > 0) {
      setAllDiscordnames(discordNames);
    } else {
      setAllDiscordnames([]);
    }
  }, [discordNames]);

  useEffect(() => {
    dispatch(getAllDiscordNames());
  }, []);

  return (
    <div className="register-container mt-5">
      <div className="register-form mt-5">
        <h2 className="register-title">Register</h2>
        <label className="fw-bold mb-2">Email</label>
        <input
          type="text"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="register-input"
        />
        <br />
        <label className="fw-bold mb-2">Discord Name</label>
        <input
          type="text"
          value={discordname}
          onChange={(e) => {
            handleDiscordnameChange(e.target.value);
            handleDiscordnameCheck(e.target.value);
          }}
          className="register-input"
        />
        <p
          className={`discordName-availability ${discordnameAvailabilityColor}`}
        >
          {discordnameAvailability}
        </p>
        <label className="fw-bold mb-2">Game Account Name</label>
        <input
          type="text"
          value={gameAccountName}
          onChange={(e) => setGameAccountName(e.target.value)}
          className="register-input"
        />
        <label className="fw-bold mb-2">Password</label>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="register-input"
        />
        {password.length > 0 && password.length < 6 ? (
          <p className="error-message">
            Password must be at least 6 characters long
          </p>
        ) : (
          ""
        )}
        <br />
        <button
          onClick={handleRegistration}
          disabled={isLoading}
          className={`register-button text-white`}
        >
          {isLoading ? "Registring..." : "Register"}
        </button>
      </div>
    </div>
  );
};

export default Register;
