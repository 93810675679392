import React, { useEffect, useState } from "react";
import "../assets/css/TroopGlossary.css"
import { getPermissions } from "../store/actions/userpermissionsAction";
import { useDispatch, useSelector } from "react-redux";
import {
  getGualsData,
  getNatarsData,
  getRNatureData,
  getRomansData,
  getTeutonsData,
  saveUnitData,
} from "../store/actions/TroopGlossaryAction";
import TroopTable from "../components/TroopGlossary/TroopTable";
import offense from "../assets/Images/troopGlossaryIcons/Offense Icon.png";
import troopDefense from "../assets/Images/troopGlossaryIcons/Troop Defense Icon.png";
import cavalryDefense from "../assets/Images/troopGlossaryIcons/Cavalry Defense Icon.png";
import speed from "../assets/Images/troopGlossaryIcons/Speed Icon.png";
import capacity from "../assets/Images/troopGlossaryIcons/Carry Capacity Icon.png";
import lumber from "../assets/Images/troopGlossaryIcons/Lumber_Resource icon.png";
import clay from "../assets/Images/troopGlossaryIcons/Clay_Resource icon.png";
import iron from "../assets/Images/troopGlossaryIcons/Iron_Resource icon.png";
import crop from "../assets/Images/troopGlossaryIcons/Crop_Resource icon.png";
import totalCost from "../assets/Images/troopGlossaryIcons/Total_Resources_Cost_Icon.png";
import upkeep from "../assets/Images/troopGlossaryIcons/Upkeep or Crop Consumption Icon.png";
import time from "../assets/Images/troopGlossaryIcons/Time_icon.jpg";

const TroopGlossary = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { Teutons, Romans, Guals, Nature, Natars, loading } = useSelector(
    (state) => state.troopGlossary
  );
  const { permission } = useSelector((state) => state.userPermissions);

  //-------------UseState--------------//

  const [activeTab, setActiveTab] = useState("teutonsTroopData");
  const [condition, setCondition] = useState("");
  const [editedIndex, setEditedIndex] = useState(null);
  const [editedUnit, setEditedUnit] = useState(null);
  const [editedFieldName, setEditedFieldName] = useState(null);

  //-------------Roles/Permissions--------------//

  const permissionData = permission?.troopGlossary;
  const role = user?.role;
  const functionalRole = user?.functionalRole;
  const functionalPermission =
    permission && permissionData && permissionData[functionalRole];

  //----------- Functions ---------//

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const handleInputChange = (e, fieldName) => {
    let value = e.target.value;

    if (value !== "" && fieldName !== "Time") {
      value = parseFloat(value);
    }

    setEditedUnit((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
  };

  const saveData = async () => {
    if (editedUnit) {
      try {
        await dispatch(saveUnitData([editedUnit], activeTab));
        dispatch(getTeutonsData());
        dispatch(getRomansData());
        dispatch(getNatarsData());
        dispatch(getRNatureData());
        dispatch(getGualsData());
      } catch (error) {
        console.error("Error saving data:", error);
      }
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      saveData();
      setEditedUnit(null);
      setEditedFieldName(null);
    }
  };

  const handleClickOutside = (e) => {
    if (!e.target.closest("input") && !e.target.closest(".troop-table-cell")) {
      setEditedIndex(null);
      setEditedUnit(null);
      setEditedFieldName(null);
    }
  };

  //-------Field Names--------//

  const fieldNames = [
    "Icon",
    "Name",
    "Offense",
    "defenseinfantry",
    "defensecavalry",
    "Speed",
    "Capacity",
    "Lumber",
    "Clay",
    "Iron",
    "Crop",
    "totalcost",
    "Upkeep",
    "Time",
  ];

  //----------- Table Header Icons --------------//

  const icons = [
    offense,
    troopDefense,
    cavalryDefense,
    speed,
    capacity,
    lumber,
    clay,
    iron,
    crop,
    totalCost,
    upkeep,
    time,
  ];

  //-----------UseEffect------------//

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (permission && permissionData) {
      const rolePermission = permissionData[role];
      const functionalPermission = permissionData[functionalRole];
      if (
        functionalPermission !== undefined &&
        functionalPermission.trim() !== ""
      ) {
        setCondition(functionalPermission);
      } else if (rolePermission !== undefined && rolePermission.trim() !== "") {
        setCondition(rolePermission);
      }
    }
  }, [permission, permissionData, role, functionalRole]);

  useEffect(() => {
    dispatch(getTeutonsData());
    dispatch(getRomansData());
    dispatch(getNatarsData());
    dispatch(getRNatureData());
    dispatch(getGualsData());
    dispatch(getPermissions());
  }, []);

  return (
    <div className="container-fluid px-5">
      <h2 className="text-center mb-3 mt-3">Troop Glossary</h2>
      {condition == "Locked" ? (
        <div
          className="text-center d-flex justify-content-center align-items-center "
          style={{ height: "70vh" }}
        >
          <h2>
            This page is not available for{" "}
            {functionalPermission !== undefined &&
            functionalPermission.trim() !== ""
              ? functionalRole
              : role}
          </h2>
        </div>
      ) : (
        <>
          <ul className="nav nav-tabs">
            <li className="nav-item">
              <button
                className={`nav-link ${
                  activeTab === "teutonsTroopData" ? "active" : ""
                }`}
                onClick={() => handleTabChange("teutonsTroopData")}
              >
                Teutons
              </button>
            </li>
            <li className="nav-item">
              <button
                className={`nav-link ${
                  activeTab === "romansTroopData" ? "active" : ""
                }`}
                onClick={() => handleTabChange("romansTroopData")}
              >
                Romans
              </button>
            </li>
            <li className="nav-item">
              <button
                className={`nav-link ${
                  activeTab === "gaulsTroopData" ? "active" : ""
                }`}
                onClick={() => handleTabChange("gaulsTroopData")}
              >
                Gauls
              </button>
            </li>
            <li className="nav-item">
              <button
                className={`nav-link ${
                  activeTab === "natureTroopData" ? "active" : ""
                }`}
                onClick={() => handleTabChange("natureTroopData")}
              >
                Nature
              </button>
            </li>
            <li className="nav-item">
              <button
                className={`nav-link ${
                  activeTab === "natarsTroopData" ? "active" : ""
                }`}
                onClick={() => handleTabChange("natarsTroopData")}
              >
                Natars
              </button>
            </li>
          </ul>
          <div className="tab-content">
            {/* Teutons Table  */}

            <div
              className={`tab-pane table-responsive fade ${
                activeTab === "teutonsTroopData" ? "show active" : ""
              }`}
            >
              <TroopTable
                data={Teutons}
                icons={icons}
                fieldNames={fieldNames}
                loading={loading}
                editedIndex={editedIndex}
                editedFieldName={editedFieldName}
                editedUnit={editedUnit}
                condition={condition}
                handleInputChange={handleInputChange}
                handleKeyDown={handleKeyDown}
                setEditedIndex={setEditedIndex}
                setEditedUnit={setEditedUnit}
                setEditedFieldName={setEditedFieldName}
              />
            </div>

            {/* Romans Table  */}

            <div
              className={`tab-pane table-responsive fade ${
                activeTab === "romansTroopData" ? "show active" : ""
              }`}
            >
              <TroopTable
                data={Romans}
                icons={icons}
                fieldNames={fieldNames}
                loading={loading}
                editedIndex={editedIndex}
                editedFieldName={editedFieldName}
                editedUnit={editedUnit}
                condition={condition}
                handleInputChange={handleInputChange}
                handleKeyDown={handleKeyDown}
                setEditedIndex={setEditedIndex}
                setEditedUnit={setEditedUnit}
                setEditedFieldName={setEditedFieldName}
              />
            </div>

            {/* Guals Table  */}

            <div
              className={`tab-pane table-responsive fade ${
                activeTab === "gaulsTroopData" ? "show active" : ""
              }`}
            >
              <TroopTable
                data={Guals}
                icons={icons}
                fieldNames={fieldNames}
                loading={loading}
                editedIndex={editedIndex}
                editedFieldName={editedFieldName}
                editedUnit={editedUnit}
                condition={condition}
                handleInputChange={handleInputChange}
                handleKeyDown={handleKeyDown}
                setEditedIndex={setEditedIndex}
                setEditedUnit={setEditedUnit}
                setEditedFieldName={setEditedFieldName}
              />
            </div>

            {/* Nature Table  */}

            <div
              className={`tab-pane table-responsive fade ${
                activeTab === "natureTroopData" ? "show active" : ""
              }`}
            >
              <TroopTable
                data={Nature}
                icons={icons}
                fieldNames={fieldNames}
                loading={loading}
                editedIndex={editedIndex}
                editedFieldName={editedFieldName}
                editedUnit={editedUnit}
                condition={condition}
                handleInputChange={handleInputChange}
                handleKeyDown={handleKeyDown}
                setEditedIndex={setEditedIndex}
                setEditedUnit={setEditedUnit}
                setEditedFieldName={setEditedFieldName}
              />
            </div>

            {/* Natars Table  */}

            <div
              className={`tab-pane table-responsive fade ${
                activeTab === "natarsTroopData" ? "show active" : ""
              }`}
            >
              <TroopTable
                data={Natars}
                icons={icons}
                fieldNames={fieldNames}
                loading={loading}
                editedIndex={editedIndex}
                editedFieldName={editedFieldName}
                editedUnit={editedUnit}
                condition={condition}
                handleInputChange={handleInputChange}
                handleKeyDown={handleKeyDown}
                setEditedIndex={setEditedIndex}
                setEditedUnit={setEditedUnit}
                setEditedFieldName={setEditedFieldName}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default TroopGlossary;
