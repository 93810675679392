const typesenseServer = {
  apiKey: "ogi7sxa7rzbmTvd5WpHFlEOz9HRG9BeI",
  nodes: [
    {
      host: "i6f34g7apcsdue82p-1.a1.typesense.net",
      port: "443",
      protocol: "https",
    },
  ],
};

export default typesenseServer;
