import React, { useEffect, useState } from "react";
import { Table } from "reactstrap";
import { getAlliancePlayers } from "../../store/actions/playerStatisticsAction";
import { getServerSettingsData } from "../../store/actions/serverSettingsAction";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment-timezone";

const StatisticsTable = ({
  playerHistory,
  allianceHistory,
  todayData,
  sevenDaysAgoData,
}) => {
  const dispatch = useDispatch();
  const [playersHistory, setPlayersHistory] = useState([]);
  const { serverSettingsData } = useSelector((state) => state.serverSettings);
  const [timezone, setTimeZone] = useState();
  const [todayPlayers, setTodayPlayers] = useState([]);
  const [sevenDaysAgoPlayers, setSevenDaysAgoPlayers] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [sortColumn, setSortColumn] = useState(null);

  const handleSort = (column, order) => {
    let sortedData = [];

    if (playerHistory && playerHistory.length > 0) {
      sortedData = [...todayData];
      sortedData.forEach((data) => {
        data.villages.forEach((village) => {
          village.populationChange = getPopulationChange(village.villageID);
        });
        data.villages.sort((a, b) =>
          compareValues(a[column], b[column], order)
        );
      });
    } else {
      sortedData = [...todayPlayers];
      sortedData.forEach((player) => {
        player.populationChange = getPopulationChanged(player);
      });
      sortedData.sort((a, b) => compareValues(a[column], b[column], order));
    }

    setSortColumn(column);
    setFilterData(sortedData);
  };

  const compareValues = (valueA, valueB, order = "asc") => {
    if (valueA === valueB) return 0;
    return (valueA < valueB ? -1 : 1) * (order === "asc" ? 1 : -1);
  };

  const clearFilters = () => {
    setSortColumn(null);
    setFilterData([]);
  };

  const getPopulationChange = (villageID) => {
    let previousPopulation = 0;
    let currentPopulation = 0;

    for (let data of sevenDaysAgoData) {
      for (let village of data.villages) {
        if (village.villageID === villageID) {
          previousPopulation = village.population;
        }
      }
    }

    for (let data of todayData) {
      for (let village of data.villages) {
        if (village.villageID === villageID) {
          currentPopulation = village.population;
        }
      }
    }

    return currentPopulation - previousPopulation;
  };

  const getPopulationChanged = (player) => {
    const todayPlayer = todayPlayers.find(
      (p) => p.playerName === player.playerName
    );
    const sevenDaysAgoPlayer = sevenDaysAgoPlayers.find(
      (p) => p.playerName === player.playerName
    );

    if (todayPlayer && sevenDaysAgoPlayer) {
      return todayPlayer.totalPopulation - sevenDaysAgoPlayer.totalPopulation;
    }

    return 0;
  };

  const getDayJoined = (player) => {
    const playerHistory = playersHistory.filter(
      (history) => history.playerName === player.playerName
    );
    const minServerDay = Math.min(
      ...playerHistory.map((history) => history.serverDay)
    );
    return minServerDay;
  };

  const getPreviousAlliance = (player) => {
    const playerHistory = playersHistory.filter(
      (history) => history.playerName === player.playerName
    );
    const previousAlliance = playerHistory
      .reverse()
      .find((history) => history.allianceTag !== player?.allianceTag);
    return previousAlliance ? previousAlliance.allianceTag : "None";
  };

  useEffect(() => {
    const fetchData = async () => {
      const players = todayData.map((data) => data.members);
      const data = await getAlliancePlayers(players);
      setPlayersHistory(data);

      const sevenDaysAgoUnix = moment
        .tz(timezone)
        .subtract(7, "days")
        .startOf("day")
        .unix();

      const todayUnix = moment.tz(timezone).startOf("day").unix();

      const todayPlayersData = data.filter(
        (player) => player.currentDate === todayUnix
      );

      const sevenDaysAgoPlayersData = data.filter(
        (player) => player.currentDate === sevenDaysAgoUnix
      );

      setTodayPlayers(todayPlayersData);
      setSevenDaysAgoPlayers(sevenDaysAgoPlayersData);
      setFilterData(todayPlayersData);
    };

    fetchData();
  }, [allianceHistory, todayData, timezone]);

  useEffect(() => {
    if (serverSettingsData && serverSettingsData.length > 0) {
      const data = serverSettingsData[0];
      setTimeZone(data?.timezone);
    }
  }, [serverSettingsData]);

  useEffect(() => {
    dispatch(getServerSettingsData());
  }, []);

  return (
    <>
      {playerHistory && playerHistory.length > 0 ? (
        <h3 className="text-center">Villages</h3>
      ) : (
        <h3 className="text-center">Players</h3>
      )}
      <div className="table-responsive mb-5">
        <Table bordered>
          <thead>
            {playerHistory && playerHistory.length > 0 ? (
              <tr>
                <th className="p-3 text-center bg-info">
                  Village Name
                  <i
                    style={{
                      cursor: "pointer",
                      color: sortColumn === "villageName" ? "red" : "",
                      border:
                        sortColumn === "villageName" ? "2px solid red" : "none",
                    }}
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    className={"bi bi-filter ms-1 bg-info"}
                  ></i>
                  <ul style={{ cursor: "pointer" }} className="dropdown-menu">
                    <li
                      role="button"
                      className="dropdown-item cursor-pointer "
                      onClick={() => handleSort("villageName", "desc")}
                    >
                      Sort By Highest Values
                    </li>
                    <li
                      role="button"
                      className="dropdown-item cursor-pointer "
                      onClick={() => handleSort("villageName", "asc")}
                    >
                      Sort By Lowest Values
                    </li>
                    <li
                      role="button"
                      className="dropdown-item cursor-pointer "
                      onClick={() => clearFilters()}
                    >
                      Clear filter
                    </li>
                  </ul>
                </th>
                <th className="text-center bg-info p-3">X|Y</th>
                <th className="p-3 text-center bg-info">
                  Population
                  <i
                    style={{
                      cursor: "pointer",
                      color: sortColumn === "population" ? "red" : "",
                      border:
                        sortColumn === "population" ? "2px solid red" : "none",
                    }}
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    className={"bi bi-filter ms-1 bg-info"}
                  ></i>
                  <ul style={{ cursor: "pointer" }} className="dropdown-menu">
                    <li
                      role="button"
                      className="dropdown-item cursor-pointer "
                      onClick={() => handleSort("population", "desc")}
                    >
                      Sort By Highest Values
                    </li>
                    <li
                      role="button"
                      className="dropdown-item cursor-pointer "
                      onClick={() => handleSort("population", "asc")}
                    >
                      Sort By Lowest Values
                    </li>
                    <li
                      role="button"
                      className="dropdown-item cursor-pointer "
                      onClick={() => clearFilters()}
                    >
                      Clear filter
                    </li>
                  </ul>
                </th>
                <th className="p-3 text-center bg-info">
                  Population Change +/- (7 days)
                  <i
                    style={{
                      cursor: "pointer",
                      color: sortColumn === "populationChange" ? "red" : "",
                      border:
                        sortColumn === "populationChange"
                          ? "2px solid red"
                          : "none",
                    }}
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    className={"bi bi-filter ms-1 bg-info"}
                  ></i>
                  <ul style={{ cursor: "pointer" }} className="dropdown-menu">
                    <li
                      role="button"
                      className="dropdown-item cursor-pointer "
                      onClick={() => handleSort("populationChange", "desc")}
                    >
                      Sort By Highest Values
                    </li>
                    <li
                      role="button"
                      className="dropdown-item cursor-pointer "
                      onClick={() => handleSort("populationChange", "asc")}
                    >
                      Sort By Lowest Values
                    </li>
                    <li
                      role="button"
                      className="dropdown-item cursor-pointer "
                      onClick={() => clearFilters()}
                    >
                      Clear filter
                    </li>
                  </ul>
                </th>
                <th className="text-center bg-info p-3">Settled/Conquered</th>
                <th className="text-center bg-info p-3">Game Day Acquired</th>
              </tr>
            ) : (
              <tr>
                <th className="p-3 text-center bg-info">
                  Player Name
                  <i
                    style={{
                      cursor: "pointer",
                      color: sortColumn === "playerName" ? "red" : "",
                      border:
                        sortColumn === "playerName" ? "2px solid red" : "none",
                    }}
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    className={"bi bi-filter ms-1 bg-info"}
                  ></i>
                  <ul style={{ cursor: "pointer" }} className="dropdown-menu">
                    <li
                      role="button"
                      className="dropdown-item cursor-pointer "
                      onClick={() => handleSort("playerName", "desc")}
                    >
                      Sort By Highest Values
                    </li>
                    <li
                      role="button"
                      className="dropdown-item cursor-pointer "
                      onClick={() => handleSort("playerName", "asc")}
                    >
                      Sort By Lowest Values
                    </li>
                    <li
                      role="button"
                      className="dropdown-item cursor-pointer "
                      onClick={() => clearFilters()}
                    >
                      Clear filter
                    </li>
                  </ul>
                </th>{" "}
                <th className="p-3 text-center bg-info">
                  Population
                  <i
                    style={{
                      cursor: "pointer",
                      color: sortColumn === "totalPopulation" ? "red" : "",
                      border:
                        sortColumn === "totalPopulation"
                          ? "2px solid red"
                          : "none",
                    }}
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    className={"bi bi-filter ms-1 bg-info"}
                  ></i>
                  <ul style={{ cursor: "pointer" }} className="dropdown-menu">
                    <li
                      role="button"
                      className="dropdown-item cursor-pointer "
                      onClick={() => handleSort("totalPopulation", "desc")}
                    >
                      Sort By Highest Values
                    </li>
                    <li
                      role="button"
                      className="dropdown-item cursor-pointer "
                      onClick={() => handleSort("totalPopulation", "asc")}
                    >
                      Sort By Lowest Values
                    </li>
                    <li
                      role="button"
                      className="dropdown-item cursor-pointer "
                      onClick={() => clearFilters()}
                    >
                      Clear filter
                    </li>
                  </ul>
                </th>{" "}
                <th className="p-3 text-center bg-info">
                  Population Change
                  <i
                    style={{
                      cursor: "pointer",
                      color: sortColumn === "populationChange" ? "red" : "",
                      border:
                        sortColumn === "populationChange"
                          ? "2px solid red"
                          : "none",
                    }}
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    className={"bi bi-filter ms-1 bg-info"}
                  ></i>
                  <ul style={{ cursor: "pointer" }} className="dropdown-menu">
                    <li
                      role="button"
                      className="dropdown-item cursor-pointer "
                      onClick={() => handleSort("populationChange", "desc")}
                    >
                      Sort By Highest Values
                    </li>
                    <li
                      role="button"
                      className="dropdown-item cursor-pointer "
                      onClick={() => handleSort("populationChange", "asc")}
                    >
                      Sort By Lowest Values
                    </li>
                    <li
                      role="button"
                      className="dropdown-item cursor-pointer "
                      onClick={() => clearFilters()}
                    >
                      Clear filter
                    </li>
                  </ul>
                </th>{" "}
                <th className="text-center bg-info p-3">Day Joined</th>
                <th className="text-center bg-info p-3">Previous Alliance</th>
              </tr>
            )}
          </thead>
          <tbody>
            {playerHistory && playerHistory.length > 0 ? (
              (filterData.length > 0 ? filterData : todayData).map(
                (data, dataIndex) =>
                  data.villages.map((village, index) => {
                    return (
                      <tr key={`${dataIndex}-${index}`}>
                        <td className="text-center">{village.villageName}</td>
                        <td className="text-center">
                          {village.x},{village.y}
                        </td>
                        <td className="text-center">
                          {village.population.toLocaleString()}
                        </td>
                        <td className="text-center">
                          {getPopulationChange(
                            village.villageID
                          ).toLocaleString()}
                        </td>
                        <td className="text-center">
                          {village?.isSettled ? "Settled" : "Conquered"}
                        </td>
                        <td className="text-center">
                          {village?.gameAcquiredDay}
                        </td>
                      </tr>
                    );
                  })
              )
            ) : allianceHistory && allianceHistory.length > 0 ? (
              (filterData.length > 0 ? filterData : todayPlayers).map(
                (player, index) => {
                  return (
                    <tr key={index}>
                      <td className="text-center">{player?.playerName}</td>
                      <td className="text-center">
                        {player.totalPopulation?.toLocaleString()}
                      </td>
                      <td className="text-center">
                        {getPopulationChanged(player)?.toLocaleString()}
                      </td>
                      <td className="text-center">{getDayJoined(player)}</td>
                      <td className="text-center">
                        {getPreviousAlliance(player)}
                      </td>
                    </tr>
                  );
                }
              )
            ) : (
              <tr>
                <td className="text-center" colSpan="6">
                  No Data Available
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default StatisticsTable;
