const initialState = {
  RPH: [],
};

const PlayerTroopReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_RPH_DATA":
      return {
        ...state,
        RPH: action.payload,
      };
    case "PLAYER_TROOP_LOADING":
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};

export default PlayerTroopReducer;
