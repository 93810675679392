import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const AllianceHistory = ({ allianceHistory, toggle, isOpen, showSummary }) => {
  if (!allianceHistory) {
    return null;
  }

  const getColorClass = (type) => {
    switch (type) {
      case "joining":
        return "text-success";
      case "leaving":
        return "text-warning";
      case "deletion":
        return "text-danger";
      default:
        return "";
    }
  };

  const sortedHistory = allianceHistory
    .slice()
    .sort((a, b) => a.serverDay - b.serverDay);

  return (
    <Modal isOpen={isOpen} toggle={toggle} scrollable>
      <ModalHeader
        toggle={toggle}
        style={{ fontWeight: "bold", fontSize: "1.5em" }}
      >
        {showSummary ? "Former Members" : "Alliance History"}
      </ModalHeader>
      <ModalBody>
        {showSummary ? (
          <ul className="list-group">
            {sortedHistory
              .filter((event) => event.deletingMembers.length > 0)
              .map((event, index) => (
                <li
                  key={index}
                  className="list-group-item"
                  style={{ fontSize: "1.1em", marginBottom: "0.5em" }}
                >
                  <span className="fw-bold">Day: {event?.serverDay}</span>{" "}
                  <span>
                    <span className={`fw-bold ${getColorClass("deletion")}`}>
                      Deletion of{" "}
                    </span>
                    {event.deletingMembers.join(", ")}
                  </span>
                </li>
              ))}
          </ul>
        ) : (
          <ul className="list-group">
            <li className="list-group-item">
              <div>
                <strong>
                  The alliance {sortedHistory[0]?.allianceTag} founded on Day{" "}
                  {sortedHistory[0]?.serverDay}
                </strong>
              </div>
              <div>
                <strong>Founding Members:</strong>
                <br />
                {sortedHistory[0]?.foundingMembers.join(", ")}
              </div>
            </li>
            {sortedHistory.map((event, index) => (
              <li
                key={index}
                className="list-group-item"
                style={{ fontSize: "1.1em", marginBottom: "0.5em" }}
              >
                {event.newMembers.length > 0 && (
                  <>
                    <span className="fw-bold">Day: {event?.serverDay}</span>{" "}
                    <span>
                      <span className={`${getColorClass("joining")} fw-bold`}>
                        Joining of{" "}
                      </span>{" "}
                      {event.newMembers.join(", ")}
                    </span>
                    <br />
                  </>
                )}
                {event.leavingMembers.length > 0 && (
                  <>
                    <span className="fw-bold">Day: {event?.serverDay}</span>{" "}
                    <span>
                      <span className={`${getColorClass("leaving")} fw-bold`}>
                        Leaving of{" "}
                      </span>{" "}
                      {event.leavingMembers.join(", ")}
                    </span>
                    <br />
                  </>
                )}
                {event.deletingMembers.length > 0 && (
                  <>
                    <span className="fw-bold">Day: {event?.serverDay}</span>{" "}
                    <span>
                      <span className={`${getColorClass("deletion")} fw-bold`}>
                        Deletion of
                      </span>{" "}
                      {event.deletingMembers.join(", ")}
                    </span>
                  </>
                )}
              </li>
            ))}
          </ul>
        )}
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AllianceHistory;
