import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteTrackerTableRow,
  getTrackerTableData,
  saveTrackerTableData,
} from "../../store/actions/defenderTrackerAction";
import { getPermissions } from "../../store/actions/userpermissionsAction";
import "../../assets/css/defenderTracker.css";
import editIcon from "../../assets/Images/Edit.jpg";
import AddEntryModal from "./AddNewRowModal";
import { toast } from "react-toastify";

const TrackerTable = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { permission } = useSelector((state) => state.userPermissions);
  const { TrackerTable } = useSelector((state) => state.defenderTracker);

  const [editedIndex, setEditedIndex] = useState(null);
  const [editedFieldName, setEditedFieldName] = useState(null);
  const [editedValue, setEditedValue] = useState("");
  const [trackerData, setTrackerData] = useState([]);
  const [sortColumn, setSortColumn] = useState(null);
  const [condition, setCondition] = useState("");
  const [selectedFilterValues, setSelectedFilterValues] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  //-------------Roles--------------//

  const permissionData = permission?.defenderTracker;
  const role = user?.role;
  const functionalRole = user?.functionalRole;

  //--------- Functions---------//

  const handleDelete = async (id) => {
    try {
      await dispatch(deleteTrackerTableRow(id));
      dispatch(getTrackerTableData());
    } catch (error) {
      toast.error("Failed to delete tracker table row");
    }
  };

  const handleSave = async (rowId) => {
    try {
      const updatedRow =
        TrackerTable && TrackerTable.find((row) => row.id === rowId);
      const updatedData = { ...updatedRow, [editedFieldName]: editedValue };
      await dispatch(saveTrackerTableData([updatedData]));
      setEditedValue("");
      setEditedIndex(null);
      setEditedFieldName(null);
      toast.success("Data updated successfully")
    } catch (error) {
      console.error("Failed to save tracker table data");
      toast.error("Failed updating data")
    }
  };

  const handleKeyDown = (e, rowId) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSave(rowId);
      dispatch(getTrackerTableData());
    }
  };

  const handleClickOutside = (e) => {
    if (
      !e.target.closest("input") &&
      !e.target.closest(".troop-tracker-table-cell")
    ) {
      setEditedIndex(null);
      setEditedValue("");
      setEditedFieldName(null);
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleApplyFilter = (columnName) => {
    const selectedValues = selectedFilterValues[columnName];

    if (!selectedValues || selectedValues.length === 0) {
      return;
    }

    const filteredRows = trackerData.filter((row) => {
      return (
        row &&
        row[columnName] !== undefined &&
        selectedValues.includes(String(row[columnName]))
      );
    });
    setTrackerData(filteredRows);
  };
  const uniqueValuesForColumn = (columnName) => {
    const uniqueValues = new Set();

    if (trackerData && trackerData.length > 0) {
      trackerData.forEach((row) => {
        if (row && row[columnName] !== undefined) {
          const value = String(row[columnName]);
          uniqueValues.add(value);
        }
      });
    }

    return Array.from(uniqueValues);
  };

  //------FieldNames--------///

  const fieldNames = [
    { key: "allianceName", label: "Alliance Name" },
    { key: "playerName", label: "Player Name" },
    { key: "villageName", label: "Village Name" },
    { key: "villageCoords", label: "(X, Y)" },
    { key: "villageMapLink", label: "Village Map Link" },
    { key: "timeDefended", label: "No. of times defended" },
  ];

  //--------- Functions to handle sorting-------- //

  const handleSort = (column, option) => {
    let sortedUsers = [...trackerData];

    if (option === "asc") {
      sortedUsers.sort((a, b) => compareValues(a[column], b[column]));
    } else if (option === "desc") {
      sortedUsers.sort((a, b) => compareValues(b[column], a[column]));
    }

    setSortColumn(column);
    setTrackerData(sortedUsers);
  };

  const compareValues = (valueA, valueB) => {
    if (valueA === valueB) {
      return 0;
    }
    return valueA < valueB ? -1 : 1;
  };

  const clearFilters = () => {
    setSortColumn(null);
    setTrackerData(TrackerTable);
    setSelectedFilterValues({});
    setSearchTerm("");
  };

  //----------UseEffects ---------//

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (TrackerTable) {
      setTrackerData(TrackerTable);
    }
  }, [TrackerTable]);

  useEffect(() => {
    if (editedIndex !== null && editedFieldName !== null) {
      const updatedRow = TrackerTable.find((row) => row.id === editedIndex);
      if (updatedRow) {
        const updatedData = { ...updatedRow, [editedFieldName]: editedValue };
        dispatch(saveTrackerTableData([updatedData]));
      }
    }
  }, [editedIndex, editedFieldName, editedValue, TrackerTable, dispatch]);

  useEffect(() => {
    if (permission && permissionData) {
      const rolePermission = permissionData[role];
      const functionalPermission = permissionData[functionalRole];
      if (
        functionalPermission !== undefined &&
        functionalPermission.trim() !== ""
      ) {
        setCondition(functionalPermission);
      } else if (rolePermission !== undefined && rolePermission.trim() !== "") {
        setCondition(rolePermission);
      }
    }
  }, [permission, permissionData, role, functionalRole]);

  useEffect(() => {
    dispatch(getTrackerTableData());
    dispatch(getPermissions());
  }, []);

  return (
    <>
      <div className="table-responsive">
        <table className="table">
          <thead>
            <th className="tracker-table-header p-3" style={{ width: "40px" }}>
              Action
            </th>
            {fieldNames.map((fieldName) => {
              return (
                <th
                  key={fieldName.key}
                  className="tracker-table-header p-3 text-center"
                >
                  {fieldName.label}
                  <i
                    style={{
                      cursor: "pointer",
                      color: sortColumn === fieldName.key ? "red" : "",
                      border:
                        sortColumn === fieldName.key ? "2px solid red" : "none",
                    }}
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    className={"bi bi-filter ms-1"}
                    disabled={condition === "Read Only"}
                  ></i>
                  <ul style={{ cursor: "pointer" }} className="dropdown-menu">
                    <li
                      className="dropdown-item nn"
                      onClick={() => handleSort(fieldName.key, "desc")}
                    >
                      Sort By Highest Values
                    </li>
                    <li
                      style={{ cursor: "pointer" }}
                      className="dropdown-item nn"
                      onClick={() => handleSort(fieldName.key, "asc")}
                    >
                      Sort By Lowest Values
                    </li>
                    <li
                      style={{ cursor: "pointer" }}
                      className="dropdown-item nn"
                      onClick={() => clearFilters()}
                    >
                      Clear filter
                    </li>
                    {fieldName.key !== "villageCoords" && (
                      <>
                        <li
                          style={{ cursor: "pointer" }}
                          className="dropdown-item "
                        >
                          Search
                          <input
                            className="mx-2"
                            placeholder="Search"
                            style={{ width: "70%" }}
                            type="text"
                            value={searchTerm}
                            onChange={handleSearchChange}
                          />
                        </li>
                        <div className="">
                          <p>Selected Values:</p>
                          {selectedFilterValues[fieldName.key]?.map(
                            (value, i) => (
                              <span key={i}>{value},</span>
                            )
                          )}
                        </div>
                        <select
                          style={{ width: "100%" }}
                          onChange={(e) => {
                            const selectedValues = Array.from(
                              e.target.selectedOptions,
                              (option) => option.value
                            );
                            setSelectedFilterValues((prevValues) => {
                              const currentValues =
                                prevValues[fieldName.key] || [];
                              const newValues = currentValues.includes(
                                selectedValues[0]
                              )
                                ? currentValues.filter(
                                    (value) => value !== selectedValues[0]
                                  )
                                : [...currentValues, ...selectedValues];
                              return {
                                ...prevValues,
                                [fieldName.key]: newValues,
                              };
                            });
                          }}
                          multiple
                        >
                          <option
                            value="selectAll"
                            className="hhh"
                            onClick={() => {
                              const allValues = uniqueValuesForColumn(
                                fieldName.key
                              ).filter((value) =>
                                value
                                  .toLowerCase()
                                  .includes(searchTerm.toLowerCase())
                              );
                              setSelectedFilterValues((prevValues) => ({
                                ...prevValues,
                                [fieldName.key]: allValues,
                              }));
                            }}
                          >
                            Select All
                          </option>
                          value && typeof value === 'string' ?
                          value.toLowerCase() : value
                          {uniqueValuesForColumn(fieldName.key)
                            ?.filter((value) =>
                              value
                                .toLowerCase()
                                .includes(searchTerm?.toLowerCase())
                            )
                            .map((value, i) => (
                              <option className="hhh" key={i} value={value}>
                                {`${value} ${
                                  selectedFilterValues[fieldName.key]?.includes(
                                    value
                                  )
                                    ? "✓"
                                    : ""
                                }`}
                              </option>
                            ))}
                        </select>
                        <div className="d-flex justify-content-end gap-2 mt-2">
                          <button
                            className="btn btn-primary"
                            disabled={condition === "Read Only"}
                            onClick={() => handleApplyFilter(fieldName.key)}
                          >
                            Apply Filter
                          </button>
                          <button
                            className="btn btn-danger"
                            disabled={condition === "Read Only"}
                            onClick={() => setSelectedFilterValues("")}
                          >
                            Cancel
                          </button>
                        </div>
                      </>
                    )}
                  </ul>
                </th>
              );
            })}
          </thead>
          <tbody>
            {trackerData?.map((row) => (
              <tr key={row?.id}>
                <td className="tracker-table-cell text-center">
                  <button
                    className="btn btn-danger px-3"
                    disabled={condition === "Read Only"}
                    onClick={() => handleDelete(row?.id)}
                  >
                    -
                  </button>
                </td>
                {fieldNames.map((fieldName) => (
                  <td
                    key={fieldName.key}
                    className="tracker-table-cell text-center"
                  >
                    {editedIndex === row.id &&
                    editedFieldName === fieldName.key ? (
                      fieldName.key === "villageCoords" ? (
                        <div className="d-flex">
                          <input
                            type="number"
                            className="w-50"
                            value={editedValue.x || ""}
                            onChange={(e) =>
                              setEditedValue({
                                ...editedValue,
                                x: e.target.value,
                              })
                            }
                            onBlur={() => handleSave(row.id)}
                            onKeyDown={(e) => handleKeyDown(e, row.id)}
                            disabled={condition === "Read Only"}
                          />
                          <input
                            type="number"
                            className="w-50"
                            value={editedValue.y || ""}
                            onChange={(e) =>
                              setEditedValue({
                                ...editedValue,
                                y: e.target.value,
                              })
                            }
                            onBlur={() => handleSave(row.id)}
                            onKeyDown={(e) => handleKeyDown(e, row.id)}
                            disabled={condition === "Read Only"}
                          />
                        </div>
                      ) : (
                        <input
                          type={
                            typeof row[fieldName.key] === "number"
                              ? "number"
                              : "text"
                          }
                          value={editedValue}
                          onChange={(e) => setEditedValue(e.target.value)}
                          onBlur={() => handleSave(row.id)}
                          onKeyDown={(e) => handleKeyDown(e, row.id)}
                          disabled={condition === "Read Only"}
                        />
                      )
                    ) : (
                      <div>
                        {fieldName.key === "villageMapLink" ? (
                          <div className="d-flex justify-content-between align-items-center">
                            <a
                              className="text-decoration-none"
                              href={
                                row[fieldName.key].startsWith("http")
                                  ? row[fieldName.key]
                                  : `https://${row[fieldName.key]}`
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {row[fieldName.key]}
                            </a>
                            <img
                              className="ms-2"
                              src={editIcon}
                              width={40}
                              role="button"
                              onClick={() => {
                                if (condition !== "Read Only") {
                                  setEditedIndex(row.id);
                                  setEditedFieldName(fieldName.key);
                                  setEditedValue(row[fieldName.key] || null);
                                }
                              }}
                            />
                          </div>
                        ) : (
                          <div
                            onClick={() => {
                              if (condition !== "Read Only") {
                                setEditedIndex(row.id);
                                setEditedFieldName(fieldName.key);
                                setEditedValue(row[fieldName.key] || null);
                              }
                            }}
                          >
                            {fieldName.key === "villageCoords"
                              ? `${row?.villageCoords?.x || ""}, ${
                                  row?.villageCoords?.y || ""
                                }`
                              : !isNaN(parseFloat(row[fieldName.key]))
                              ? parseFloat(row[fieldName.key]).toLocaleString()
                              : row[fieldName.key] || "\u00A0"}
                          </div>
                        )}
                      </div>
                    )}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Modal */}
      <AddEntryModal
        isOpen={modal}
        toggle={toggle}
        // formData={formData}
        // handleChange={handleChange}
        // handleSubmit={handleSubmit}
      />

      <div className="d-flex justify-content-start mt-2">
        <button
          className="btn btn-primary"
          onClick={toggle}
          disabled={condition === "Read Only"}
        >
          Add New Row
        </button>
      </div>
    </>
  );
};

export default TrackerTable;
