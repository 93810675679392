import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";

const StartGoalModal = ({
  isOpen,
  toggle,
  formData,
  handleChange,
  handleSubmit,
  getCurrentDate,
  isSubmitting,
}) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} centered scrollable>
      <ModalHeader toggle={toggle}>Start New Goal</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label for="goalSelection">Goal Selection:</Label>
            <Input
              type="select"
              id="goalSelection"
              value={formData.goalSelection}
              onChange={handleChange}
            >
              <option value="" disabled>
                Select an option
              </option>
              <option value="recruitment">Recruitment</option>
              <option value="philosophy">Philosophy</option>
              <option value="metallurgy">Metallurgy</option>
              <option value="commerce">Commerce</option>
            </Input>
          </FormGroup>
          <FormGroup className="mt-2">
            <Label for="resourceNeeded">Resource Needed:</Label>
            <Input
              type="text"
              id="resourceNeeded"
              value={formData.resourceNeeded}
              onChange={handleChange}
            />
          </FormGroup>
          <FormGroup className="mt-2">
            <Label for="endDate">Select Date:</Label>
            <Input
              type="date"
              id="endDate"
              value={formData.endDate}
              onChange={handleChange}
              min={getCurrentDate()}
            />
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          Close
        </Button>
        <Button
          color="primary"
          onClick={handleSubmit}
          disabled={
            !formData.goalSelection ||
            !formData.resourceNeeded ||
            !formData.endDate ||
            isSubmitting
          }
        >
          {isSubmitting ? "Creating Goal..." : "Start Goal"}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default StartGoalModal;
