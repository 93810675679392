import React from "react";

const TroopTable = ({
  data,
  icons,
  fieldNames,
  loading,
  editedIndex,
  editedFieldName,
  editedUnit,
  condition,
  handleInputChange,
  handleKeyDown,
  setEditedIndex,
  setEditedUnit,
  setEditedFieldName,
}) => {
  return (
    <table className="table troop-table">
      <thead>
        <tr>
          <th className="text-center">Icon</th>
          <th className="text-center">Name</th>
          {icons.map((icon, index) => (
            <th key={index} className="troop-table-header text-center">
              <img src={icon} width={25} alt="icon" />
            </th>
          ))}
        </tr>
      </thead>
      {loading ? (
        <tbody>
          <tr>
            <td colSpan={fieldNames.length} className="text-center">
              <div className="spinner-border" role="status"></div>
              <span className="visually-hidden">Loading...</span>
            </td>
          </tr>
        </tbody>
      ) : (
        <tbody>
          {data.map((unit, index) => (
            <tr key={unit.id}>
              {fieldNames.map((fieldName) => (
                <td key={fieldName} className="troop-table-cell text-center">
                  {editedIndex === index &&
                  editedFieldName === fieldName &&
                  fieldName === "Time" ? (
                    <input
                      type="text"
                      value={
                        editedUnit
                          ? editedUnit[fieldName.toLowerCase()] || ""
                          : ""
                      }
                      onChange={(e) =>
                        handleInputChange(e, fieldName.toLowerCase())
                      }
                      onKeyDown={handleKeyDown}
                    />
                  ) : editedIndex === index &&
                    editedFieldName === fieldName &&
                    fieldName !== "Time" ? (
                    <input
                      type="number"
                      disabled={condition === "Read Only"}
                      value={
                        editedUnit
                          ? editedUnit[fieldName.toLowerCase()] || ""
                          : ""
                      }
                      onChange={(e) =>
                        handleInputChange(e, fieldName.toLowerCase())
                      }
                      onKeyDown={handleKeyDown}
                    />
                  ) : (
                    <div
                      onClick={() => {
                        if (
                          !["Name", "Icon"].includes(fieldName) &&
                          !(fieldName !== "Time" && condition === "Read Only")
                        ) {
                          setEditedIndex(index);
                          setEditedUnit(unit);
                          setEditedFieldName(fieldName);
                        }
                      }}
                    >
                      {fieldName === "Time"
                        ? unit[fieldName.toLowerCase()]
                        : typeof unit[fieldName.toLowerCase()] === "number"
                        ? unit[fieldName.toLowerCase()].toLocaleString()
                        : unit[fieldName.toLowerCase()]}
                    </div>
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      )}
    </table>
  );
};

export default TroopTable;
