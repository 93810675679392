import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
} from "reactstrap";
import Papa from "papaparse";
import { toast } from "react-toastify";
import moment from "moment-timezone";
import { useDispatch, useSelector } from "react-redux";
import { updateFarmlist } from "../../store/actions/farmSearchAction";
import { getCurrentMapData } from "../../store/actions/resourcePushAction";

const UpdateFarmlistParser = ({ modal, toggle }) => {
  const { user } = useSelector((state) => state.auth);
  const { serverSettingsData } = useSelector((state) => state.serverSettings);
  const { latestMapInformation } = useSelector((state) => state.resourcePush);
  const dispatch = useDispatch();
  const [reportText, setReportText] = useState("");
  const [player, setPlayer] = useState();
  const [serverDay, setServerDay] = useState(0);

  const handleImport = () => {
    try {
      const parsedReport = Papa.parse(reportText);

      if (
        !parsedReport ||
        !parsedReport.data ||
        parsedReport.data.length === 0
      ) {
        toast.error("Failed to parse report data.");
        return;
      }
      const reportData = parsedReport.data;

      const sumArrivalIndex = reportData.findIndex((row) => {
        return (
          Array.isArray(row) &&
          row.some((cell) => /Start all farm lists/.test(cell))
        );
      });
      const playerArrivalIndex = sumArrivalIndex + 1;
      const villageArrivalIndex = sumArrivalIndex + 2;

      const playerName = reportData[playerArrivalIndex][0];
      const villageName = reportData[villageArrivalIndex][0];
      const lastRaidIndex = reportData.findIndex(
        (row) =>
          Array.isArray(row) && row.some((cell) => cell.includes("Last raid"))
      );

      let villages = [];
      if (lastRaidIndex !== -1) {
        for (let i = lastRaidIndex + 1; i < reportData.length; i++) {
          const row = reportData[i];
          if (Array.isArray(row)) {
            if (row.some((cell) => cell.includes("Add target"))) {
              break;
            }
            const cleanedRow = row[0].replace(/[^\x20-\x7E]/g, "").trim();
            const match = cleanedRow.match(/\((\d+)\|(\d+)\)/);
            if (match) {
              const X = parseInt(match[1], 10);
              const Y = parseInt(match[2], 10);
              const matchingVillage = latestMapInformation.find(
                (village) => village.x === X && village.y === Y
              );
              if (matchingVillage) {
                villages.push({
                  x: X,
                  y: Y,
                  playerName: matchingVillage.playerName,
                  villageName: matchingVillage.villageName,
                  allianceTag: matchingVillage.allianceTag,
                });
              } else {
                villages.push({ x: X, y: Y });
              }
              console.log(`Extracted coordinates: X=${X}, Y=${Y}`);
            }
          }
        }
        console.log("Extracted villages:", villages);
      } else {
        console.log("No 'Last raid' entry found in report data.");
      }

      if (sumArrivalIndex !== -1) {
        if (playerName === player) {
          const dispatchData = {
            player: playerName,
            village: villageName,
            villages: villages,
            serverDay: serverDay,
          };
          dispatch(updateFarmlist(dispatchData));
        } else {
          toast.error(`You can only update your Farmlist`);
        }
      } else {
        console.log("Required data not found in report data.");
      }
      toggle();
      setReportText("");
    } catch (error) {
      alert(error);
    }
  };

  useEffect(() => {
    if (serverSettingsData && serverSettingsData.length > 0) {
      const data = serverSettingsData[0];
      const timeZone = data?.timezone;
      const startDateString = data?.startDate;

      const startDate = moment.tz(startDateString, "YYYY-MM-DD", timeZone);

      const now = moment.tz(timeZone);

      let daysPassed = now
        .startOf("day")
        .diff(startDate.startOf("day"), "days");

      if (daysPassed < 0) {
        daysPassed = 0;
      } else {
        daysPassed += 1;
      }

      setServerDay(daysPassed);
    }
  }, [serverSettingsData]);

  useEffect(() => {
    if (user) {
      setPlayer(user?.gameAccountName);
    }
  }, [user]);

  useEffect(() => {
    dispatch(getCurrentMapData());
  }, [dispatch]);

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Farm List Parser</ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <textarea
                rows="15"
                className="form-control"
                value={reportText}
                onChange={(e) => setReportText(e.target.value)}
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            size="sm"
            onClick={() => {
              toggle();
              setReportText("");
            }}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            size="sm"
            onClick={handleImport}
            disabled={!reportText || reportText.length === 0}
          >
            Update
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default UpdateFarmlistParser;
