import { combineReducers } from "redux";
import authReducer from "./authReducer";
import dashboardReducer from "./dashboardReducer";
import playroasterReducer from "./playroasterReducer";
import userpermissionsReducer from "./userpermissionsReducer";
import serverSettingsReducer from "./serverSettingsReducer";
import TroopGlossaryReducer from "./TroopGlossaryReducer";
import HammerTrackerReducer from "./hammerTrackerReducer";
import DefenderTrackerReducer from "./defenderTrackerReducer";
import ResourcePushReducer from "./resourcePushReducer";
import AllianceBonusReducer from "./allianceBonusReducer";
import PlayerTroopReducer from "./playerTroopReducer";
import FarmSearchReducer from "./farmSearchReducer";
import PlayerStatisticsReducer from "./playerStatisticsReducer";

const rootReducer = combineReducers({
  auth: authReducer,
  dashboard: dashboardReducer,
  column: playroasterReducer,
  userPermissions: userpermissionsReducer,
  serverSettings: serverSettingsReducer,
  troopGlossary: TroopGlossaryReducer,
  hammerTracker: HammerTrackerReducer,
  defenderTracker: DefenderTrackerReducer,
  resourcePush: ResourcePushReducer,
  allianceBonus: AllianceBonusReducer,
  playerTroop: PlayerTroopReducer,
  farmSearch: FarmSearchReducer,
  playerStatistics: PlayerStatisticsReducer
});

export default rootReducer;
