import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";
import "../../assets/css/FarmSearch.css";
import { useDispatch, useSelector } from "react-redux";
import typesenseServer from "../../config/typesenseConfig";
import axios from "axios";
import moment from "moment-timezone";
import { getServerSettingsData } from "../../store/actions/serverSettingsAction";
import {
  getGualsData,
  getNatarsData,
  getRomansData,
  getTeutonsData,
} from "../../store/actions/TroopGlossaryAction";
import { toast } from "react-toastify";

const FarmSearchTab = ({ condition }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { myFarmlist } = useSelector((state) => state.farmSearch);
  const { Teutons, Romans, Guals, Natars } = useSelector(
    (state) => state.troopGlossary
  );
  const { serverSettingsData } = useSelector((state) => state.serverSettings);
  const [farmlist, setFarmList] = useState([]);
  const [villagesData, setVillagesData] = useState([]);
  const [timezone, setTimeZone] = useState();
  const [villageOptions, setVillageOptions] = useState([]);
  const [player, setPlayer] = useState();
  const [startingVillage, setStartingVillage] = useState();
  const [distance, setDistance] = useState(100);
  const [maxPopulationChange, setMaxPopulationChange] = useState(100);
  const [days, setDays] = useState(7);
  const [playerMinVillageCountMin, setPlayerMinVillageCountMin] = useState(1);
  const [playerMinVillageCountMax, setPlayerMinVillageCountMax] = useState(10);
  const [villageMinPopulationMin, setVillageMinPopulationMin] = useState(2);
  const [villageMinPopulationMax, setVillageMinPopulationMax] = useState(500);
  const [troopType, setTroopType] = useState();
  const [troopSpeed, setTroopSpeed] = useState();
  const [tournamentSquareLevel, setTournamentSquareLevel] = useState(1);
  const [showOutProtection, setShowOutProtection] = useState(true);
  const [showNoAlliance, setShowNoAlliance] = useState(true);
  const [excludeFarmList, setExcludeFarmList] = useState(true);
  const [showGauls, setShowGauls] = useState(true);
  const [showRomans, setShowRomans] = useState(true);
  const [showTeutons, setShowTeutons] = useState(true);
  const [showNatars, setShowNatars] = useState(true);
  const [x, setX] = useState();
  const [y, setY] = useState();
  const [searchResults, setSearchResults] = useState([]);
  const [serverUrl, setServerUrl] = useState();
  const [currentDate, setCurrentDate] = useState();
  const [tribe, setTribe] = useState();
  const [loading, setLoading] = useState(false);
  const [filterData, setFilterData] = useState([]);
  const [sortColumn, setSortColumn] = useState(null);

  const fetchStatsForDate = async (timezone) => {
    const startDateUnix = moment
      .tz(timezone)
      .subtract(7, "days")
      .startOf("day")
      .unix();
    const todayUnix = moment.tz(timezone).startOf("day").unix();

    let allDocuments = [];

    for (let date = startDateUnix; date <= todayUnix; date += 24 * 60 * 60) {
      let page = 1;
      const perPage = 250;

      while (true) {
        const response = await axios.get(
          `https://${typesenseServer.nodes[0].host}:${typesenseServer.nodes[0].port}/collections/timeseriesMapInformation/documents/search`,
          {
            params: {
              q: "*",
              filter_by: `currentDate:${date}`,
              per_page: perPage,
              page: page,
            },
            headers: {
              "X-TYPESENSE-API-KEY": typesenseServer.apiKey,
              "Content-Type": "application/json",
            },
          }
        );

        const documents = response.data.hits.map((hit) => hit.document);
        allDocuments = allDocuments.concat(documents);

        if (documents.length < perPage) {
          break;
        }

        page += 1;
      }
    }

    setVillagesData(allDocuments);
    return allDocuments;
  };

  const calculateDistance = (startingVillage, targetVillage) => {
    const largestX = x && x;
    const largestY = y && y;

    const xDiff = Math.abs(startingVillage.x - targetVillage.x);
    const yDiff = Math.abs(startingVillage.y - targetVillage.y);

    const shortestX = Math.min(xDiff, largestX * 2 + 1 - xDiff);
    const shortestY = Math.min(yDiff, largestY * 2 + 1 - yDiff);

    const distance = Math.sqrt(shortestX ** 2 + shortestY ** 2);

    return distance;
  };

  const calculatePopulationChange = (villageID, days) => {
    const targetVillageData = villagesData.filter(
      (village) => village.villageID === villageID
    );
    if (targetVillageData.length === 0) return 0;

    const startDate = moment
      .tz(timezone)
      .subtract(days, "days")
      .startOf("day")
      .unix();

    const startData = targetVillageData.find(
      (village) => village.currentDate === startDate
    );
    const endData = targetVillageData.find(
      (village) =>
        village.currentDate === moment.tz(timezone).startOf("day").unix()
    );

    if (!startData || !endData) return 0;

    return endData.population - startData.population;
  };

  const calculateDistances = () => {
    if (!villagesData.length > 0 || !startingVillage || distance === undefined)
      return;

    const startingVillageData =
      villagesData &&
      villagesData.find((village) => village.villageID == startingVillage);

    if (!startingVillageData) {
      toast.error("Starting village not found in villagesData");
      return;
    }

    const filteredVillages = villagesData.filter(
      (village) => village.playerID !== startingVillageData.playerID
    );

    const uniqueVillages = filteredVillages.reduce((unique, village) => {
      if (!unique.some((v) => v.villageID == village.villageID)) {
        unique.push(village);
      }
      return unique;
    }, []);

    const villagesWithinDistance = uniqueVillages.filter((village) => {
      const villageDistance = calculateDistance(startingVillageData, village);
      const populationChange = calculatePopulationChange(
        village.villageID,
        days
      );
      return (
        villageDistance <= distance &&
        Math.abs(populationChange) <= maxPopulationChange
      );
    });

    const playerVillageCounts = villagesData.reduce((acc, village) => {
      acc[village.playerID] = (acc[village.playerID] || 0) + 1;
      return acc;
    }, {});

    const validPlayers = Object.entries(playerVillageCounts)
      .filter(
        ([playerID, villageCount]) =>
          villageCount >= playerMinVillageCountMin &&
          villageCount <= playerMinVillageCountMax
      )
      .map(([playerID]) => playerID);

    const filteredVillagesByPlayerCount = villagesWithinDistance.filter(
      (village) => validPlayers.includes(village.playerID.toString())
    );

    const filteredVillagesByPopulationRange =
      filteredVillagesByPlayerCount.filter(
        (village) =>
          village.population >= villageMinPopulationMin &&
          village.population <= villageMinPopulationMax
      );

    let resultVillages = filteredVillagesByPopulationRange;

    resultVillages = resultVillages.filter((village) => {
      if (showGauls && village.tribe === 3) return true;
      if (showRomans && village.tribe === 2) return true;
      if (showTeutons && village.tribe === 1) return true;
      if (showNatars && village.tribe === 5) return true;
      return false;
    });

    if (showNoAlliance) {
      resultVillages = resultVillages.filter(
        (village) => village.allianceTag === ""
      );
    }

    if (excludeFarmList) {
      resultVillages = resultVillages.filter(
        (village) =>
          !farmlist.some((farm) => farm.x === village.x && farm.y === village.y)
      );
    }

    const enhancedResultVillages = resultVillages.map((village) => {
      const oneDayPopulationChange = calculatePopulationChange(
        village.villageID,
        1
      );
      const sevenDaysPopulationChange = calculatePopulationChange(
        village.villageID,
        7
      );
      const villageDistance = calculateDistance(startingVillageData, village);

      const time = villageDistance / Number(troopSpeed);
      const first20TilesTime = 20 / Number(troopSpeed);
      const timeWithTS =
        (time - first20TilesTime) / Number(tournamentSquareLevel) +
        first20TilesTime;

      const hoursTimeWithTS = Math.floor(timeWithTS);
      const minutesTimeWithTS = Math.floor((timeWithTS - hoursTimeWithTS) * 60);
      const secondsTimeWithTS = Math.floor(
        ((timeWithTS - hoursTimeWithTS) * 60 - minutesTimeWithTS) * 60
      );

      return {
        ...village,
        oneDayPopulationChange,
        sevenDaysPopulationChange,
        distance: villageDistance,
        travelTime: `${hoursTimeWithTS}:${minutesTimeWithTS}:${secondsTimeWithTS}`,
      };
    });

    return enhancedResultVillages;
  };

  const handleSearch = () => {
    setLoading(true);

    setTimeout(() => {
      const results = calculateDistances();
      setSearchResults(results);
      setFilterData(results);
      setLoading(false);
    }, 20000);
  };

  const getTroopOptions = () => {
    let options = [];

    switch (tribe) {
      case 1:
        options = Romans.map((troop) => ({
          label: troop.name,
          value: troop.name,
        }));
        break;
      case 2:
        options = Teutons.map((troop) => ({
          label: troop.name,
          value: troop.name,
        }));
        break;
      case 3:
        options = Guals.map((troop) => ({
          label: troop.name,
          value: troop.name,
        }));
        break;
      case 5:
        options = Natars.map((troop) => ({
          label: troop.name,
          value: troop.name,
        }));
        break;
      default:
        options = [{ label: "Select", value: "" }];
        break;
    }

    return [{ label: "", value: "" }, ...options];
  };

  //Sorting
  const handleSort = (column, option) => {
    let sortedUsers = [...searchResults];

    if (option === "asc") {
      sortedUsers.sort((a, b) => compareValues(a[column], b[column]));
    } else if (option === "desc") {
      sortedUsers.sort((a, b) => compareValues(b[column], a[column]));
    }

    setSortColumn(column);
    setFilterData(sortedUsers);
  };

  const compareValues = (valueA, valueB) => {
    if (valueA === valueB) {
      return 0;
    }
    return valueA < valueB ? -1 : 1;
  };

  const clearFilters = () => {
    setSortColumn(null);
    setFilterData(searchResults);
  };

  //------------ Field Names ---------------- //

  const fieldNames = [
    { key: "playerName", label: "Player" },
    {
      key: "allianceTag",
      label: "Alliance",
    },
    {
      key: "villageName",
      label: "Village",
    },
    { key: "x", label: "X | Y" },
    { key: "population", label: "Population" },
    { key: "oneDayPopulationChange", label: "+/- (1 Day)" },
    { key: "sevenDaysPopulationChange", label: "+/- (7 Days)" },
    { key: "distance", label: "Distance" },
    { key: "travelTime", label: "Travel Time" },
    { key: "y", label: "Village Link" },
  ];

  useEffect(() => {
    const updateTroopSpeed = () => {
      let troops = [];
      switch (tribe) {
        case 1:
          troops = Romans;
          break;
        case 2:
          troops = Teutons;
          break;
        case 3:
          troops = Guals;
          break;
        case 5:
          troops = Natars;
          break;
        default:
          break;
      }
      const selectedTroop = troops.find((troop) => troop.name == troopType);
      if (selectedTroop) {
        setTroopSpeed(selectedTroop?.speed);
      } else {
        setTroopSpeed("");
      }
    };

    updateTroopSpeed();
  }, [tribe, troopType, Teutons, Romans, Guals, Natars]);

  useEffect(() => {
    if (user) {
      setPlayer(user?.gameAccountName);
      const filteredData =
        myFarmlist && myFarmlist.filter((entry) => entry.player === player);

      setFarmList(filteredData);
    }
  }, [myFarmlist, user, player]);

  useEffect(() => {
    if (serverSettingsData && serverSettingsData.length > 0) {
      const data = serverSettingsData[0];
      setTimeZone(data?.timezone);
      setX(data?.largestX);
      setY(data?.largestY);
      setServerUrl(data?.serverUrl);
      fetchStatsForDate(data?.timezone);
      const todayUnix = moment.tz(timezone).startOf("day").unix();
      setCurrentDate(todayUnix);
    }
  }, [serverSettingsData]);

  useEffect(() => {
    if (player && currentDate) {
      const fetchVillageOptions = async () => {
        try {
          const response = await axios.get(
            `https://${typesenseServer.nodes[0].host}:${typesenseServer.nodes[0].port}/collections/timeseriesMapInformation/documents/search`,
            {
              params: {
                q: player,
                query_by: "playerName",
                filter_by: `currentDate:${currentDate}`,
                per_page: 250,
              },
              headers: {
                "X-TYPESENSE-API-KEY": typesenseServer.apiKey,
                "Content-Type": "application/json",
              },
            }
          );

          const villages = response.data.hits.map((hit) => hit.document);
          const options = villages.map((village) => ({
            value: village.villageID,
            label: `${village.villageName} (${village.x}|${village.y})`,
          }));
          setVillageOptions(options);
          const tribe = villages.length > 0 ? villages[0]?.tribe : null;
          setTribe(tribe);
        } catch (error) {
          toast.error("Typesense search error:", error);
        }
      };

      fetchVillageOptions();
    }
  }, [player, currentDate]);

  useEffect(() => {
    dispatch(getServerSettingsData());
    dispatch(getTeutonsData());
    dispatch(getRomansData());
    dispatch(getNatarsData());
    dispatch(getGualsData());
  }, []);

  return (
    <div className="farm-search-tab">
      <div className="form-inline">
        <div className="form-group">
          <label>Starting Village</label>
          <select
            className="form-control"
            value={startingVillage}
            onChange={(e) => setStartingVillage(e.target.value)}
          >
            <option value="">Select</option>
            {villageOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label>Distance from Starting Village</label>
          <input
            type="number"
            className="form-control"
            value={distance}
            onChange={(e) => setDistance(Number(e.target.value))}
          />
        </div>
      </div>
      <div className="form-inline">
        <div className="form-group">
          <label>Max Population Change</label>
          <input
            type="number"
            className="form-control"
            value={maxPopulationChange}
            onChange={(e) => setMaxPopulationChange(Number(e.target.value))}
          />
        </div>
        <div className="form-group">
          <label>In the last Days</label>
          <input
            type="number"
            className="form-control"
            value={days}
            onChange={(e) => setDays(Number(e.target.value))}
          />
        </div>
      </div>
      <div className="form-inline">
        <div className="form-group">
          <label>Player Minimum Village Count</label>
          <input
            type="number"
            className="form-control"
            value={playerMinVillageCountMin}
            onChange={(e) =>
              setPlayerMinVillageCountMin(Number(e.target.value))
            }
          />
        </div>
        <div className="form-group">
          <label>Up to</label>
          <input
            type="number"
            className="form-control"
            value={playerMinVillageCountMax}
            onChange={(e) =>
              setPlayerMinVillageCountMax(Number(e.target.value))
            }
          />
        </div>
      </div>
      <div className="form-inline">
        <div className="form-group">
          <label>Village Minimum Population</label>
          <input
            type="number"
            className="form-control"
            value={villageMinPopulationMin}
            onChange={(e) => setVillageMinPopulationMin(Number(e.target.value))}
          />
        </div>
        <div className="form-group">
          <label>Up to</label>
          <input
            type="number"
            className="form-control"
            value={villageMinPopulationMax}
            onChange={(e) => setVillageMinPopulationMax(Number(e.target.value))}
          />
        </div>
      </div>
      <div className="form-inline">
        <div className="form-group">
          <label>Troop Type</label>
          <select
            className="form-control"
            value={troopType}
            onChange={(e) => setTroopType(e.target.value)}
          >
            {getTroopOptions().map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label>Troop Speed</label>
          <input
            type="number"
            className="form-control"
            value={troopSpeed}
            readOnly
          />
        </div>
      </div>
      <div className="form-inline">
        <div className="form-group">
          <label>Tournament Square Level</label>
          <select
            className="form-control"
            value={tournamentSquareLevel}
            onChange={(e) => setTournamentSquareLevel(Number(e.target.value))}
          >
            <option value="1">TS 0</option>
            <option value="1.2">TS 1</option>
            <option value="1.4">TS 2</option>
            <option value="1.6">TS 3</option>
            <option value="1.8">TS 4</option>
            <option value="2.0">TS 5</option>
            <option value="2.2">TS 6</option>
            <option value="2.4">TS 7</option>
            <option value="2.6">TS 8</option>
            <option value="2.8">TS 9</option>
            <option value="3.0">TS 10</option>
            <option value="3.2">TS 11</option>
            <option value="3.4">TS 12</option>
            <option value="3.6">TS 13</option>
            <option value="3.8">TS 14</option>
            <option value="4.0">TS 15</option>
            <option value="4.2">TS 16</option>
            <option value="4.4">TS 17</option>
            <option value="4.6">TS 18</option>
            <option value="4.8">TS 19</option>
            <option value="5.0">TS 20</option>
          </select>
        </div>
        <div className="form-group"></div>
      </div>
      <div className="switch-group">
        <div className="form-check form-switch">
          <label className="form-check-label">
            <input
              type="checkbox"
              className="form-check-input"
              checked={showOutProtection}
              onChange={(e) => setShowOutProtection(e.target.checked)}
            />
            Show only players that are out of protection period
          </label>
        </div>
        <div className="form-check form-switch">
          <label className="form-check-label">
            <input
              type="checkbox"
              className="form-check-input"
              checked={showNoAlliance}
              onChange={(e) => setShowNoAlliance(e.target.checked)}
            />
            Only show players with no alliance
          </label>
        </div>
        <div className="form-check form-switch">
          <label className="form-check-label">
            <input
              type="checkbox"
              className="form-check-input"
              checked={excludeFarmList}
              onChange={(e) => setExcludeFarmList(e.target.checked)}
            />
            Exclude villages in my farmlist
          </label>
        </div>
        <div className="form-check form-switch">
          <label className="form-check-label">
            <input
              type="checkbox"
              className="form-check-input"
              checked={showGauls}
              onChange={(e) => setShowGauls(e.target.checked)}
            />
            Show Gauls
          </label>
        </div>
        <div className="form-check form-switch">
          <label className="form-check-label">
            <input
              type="checkbox"
              className="form-check-input"
              checked={showRomans}
              onChange={(e) => setShowRomans(e.target.checked)}
            />
            Show Romans
          </label>
        </div>
        <div className="form-check form-switch">
          <label className="form-check-label">
            <input
              type="checkbox"
              className="form-check-input"
              checked={showTeutons}
              onChange={(e) => setShowTeutons(e.target.checked)}
            />
            Show Teutons
          </label>
        </div>
        <div className="form-check form-switch">
          <label className="form-check-label">
            <input
              type="checkbox"
              className="form-check-input"
              checked={showNatars}
              onChange={(e) => setShowNatars(e.target.checked)}
            />
            Show Natars
          </label>
        </div>
      </div>
      <Button
        color="info"
        className={`btn-search py-2 ${loading ? "loading" : ""}`}
        onClick={handleSearch}
        disabled={
          loading || condition === "Read Only" || !startingVillage || !troopType
        }
        aria-busy={loading}
        aria-label="Search"
      >
        {loading ? (
          <>
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            Searching...
          </>
        ) : (
          "Search"
        )}
      </Button>
      <div className="table-responsive mb-5">
        <table className="table">
          <thead>
            {fieldNames.map((fieldName) => {
              return (
                <th key={fieldName.key} className="bg-info">
                <div className="d-flex justify-content-between align-items-center bg-info">
                  <div className="flex-grow-1 text-center">{fieldName.label}</div>
                  <div className="d-flex align-items-center">
                    <i
                      style={{
                        cursor: "pointer",
                        color: sortColumn === fieldName.key ? "red" : "",
                        border: sortColumn === fieldName.key ? "1px solid red" : "none",
                      }}
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      className={"bi bi-filter ms-2 bg-info"}
                    ></i>
                    <ul style={{ cursor: "pointer" }} className="dropdown-menu">
                      <li
                        role="button"
                        className="dropdown-item cursor-pointer"
                        onClick={() => handleSort(fieldName.key, "desc")}
                      >
                        Sort By Highest Values
                      </li>
                      <li
                        role="button"
                        className="dropdown-item cursor-pointer"
                        onClick={() => handleSort(fieldName.key, "asc")}
                      >
                        Sort By Lowest Values
                      </li>
                      <li
                        role="button"
                        className="dropdown-item cursor-pointer"
                        onClick={() => clearFilters()}
                      >
                        Clear filter
                      </li>
                    </ul>
                  </div>
                </div>
              </th>
              
              );
            })}
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td className="text-center" colSpan="12">
                  <div className="d-flex justify-content-center align-items-center">
                    <div className="spinner-border" role="status"></div>
                    <span className="visually-hidden ms-2">Loading...</span>
                  </div>
                </td>
              </tr>
            ) : filterData && filterData.length > 0 ? (
              filterData.map((village, index) => (
                <tr key={index}>
                  <td className="text-center">{village?.playerName}</td>
                  <td className="text-center">{village?.allianceTag}</td>
                  <td className="text-center">{village?.villageName}</td>
                  <td className="text-center">{`${village?.x} | ${village?.y}`}</td>
                  <td className="text-center">{village?.population}</td>
                  <td className="text-center">
                    {village?.oneDayPopulationChange}
                  </td>
                  <td className="text-center">
                    {village?.sevenDaysPopulationChange}
                  </td>
                  <td className="text-center">
                    {village?.distance.toFixed(2)}
                  </td>
                  <td className="text-center"> {village?.travelTime}</td>
                  <td className="text-center">
                    <a
                      href={`${serverUrl}/karte.php?x=${village?.x}&y=${village?.y}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-decoration-none"
                    >
                      Village Link
                    </a>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="10" className="text-center">
                  No results found.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default FarmSearchTab;
