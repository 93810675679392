import React, { useEffect, useRef, useState } from "react";
import Chart from "chart.js/auto";
import { useDispatch } from "react-redux";
import {
  getAlliancePopulation,
  getPlayerPopulation,
} from "../../store/actions/playerStatisticsAction";

const TopTwentyStatsGraph = ({ data, type }) => {
  const chartRef = useRef(null);
  const dispatch = useDispatch();
  const chartInstance = useRef(null);
  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let populationData;
        if (type === "player") {
          populationData = await dispatch(getPlayerPopulation(data));
        } else if (type === "alliance") {
          populationData = await dispatch(getAlliancePopulation(data));
        }
        
        populationData.sort((a, b) => a.serverDay - b.serverDay);
        
        setChartData(populationData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [dispatch, data, type]);

  useEffect(() => {
    if (chartRef.current && chartData) {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }

      const ctx = chartRef.current.getContext("2d");
      chartInstance.current = new Chart(ctx, {
        type: "line",
        data: {
          labels: chartData.map((d) => `Day ${d.serverDay}`),
          datasets: [
            {
              label: "Population",
              data: chartData.map((d) => d.totalPopulation),
              borderColor: "#198754",
              tension: 0.2,
              fill: true,
            },
          ],
        },
        options: {
          responsive: false,
          maintainAspectRatio: false,
          animation: false,
          scales: {
            x: {
              display: false,
              grid: {
                display: false,
              },
              ticks: {
                display: false,
              },
            },
            y: {
              display: false,
              grid: {
                display: false,
              },
              ticks: {
                display: false,
              },
            },
          },
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              enabled: false,
            },
          },
          layout: {
            padding: {
              top: 10,
              bottom: 10,
              left: 10,
              right: 10,
            },
          },
          elements: {
            point: {
              radius: 0,
            },
            line: {
              borderWidth: 1,
              borderColor: "#198754",
            },
          },
          plugins: {
            filler: {
              propagate: false,
            },
          },
          backgroundColor: "#f8f9fa",
          border: {
            width: 1,
            color: "#ced4da",
          },
        },
      });
    }

    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, [chartData]);

  return <canvas ref={chartRef} height={100} />;
};

export default TopTwentyStatsGraph;
